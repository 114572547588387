import { StatusCodeBasicSearchPayload } from '../status-codes.model';

export class ResetStatusCodeList {
  static readonly type = '[Status Codes] Reset Status Codes List';
}

export class FetchStatusCodesList {
  static readonly type = '[Status Codes] Status Codes List';
  constructor(public payload: StatusCodeBasicSearchPayload) {}
}
