import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField, IDropdownResponse } from '../../models/dynamic.model';
import { ValidatorService } from '../../services/form-control-validators.service';
import { mailingAddressFormConfig } from './mailing-address.config';
import { SharedModule } from '../../shared.module';
import { CommonService } from '../../services/common.service';
import {
  AccordionTabs,
  conditionCheckConstants,
  validationErrorMessages,
  ActionIcons,
  countryCodes,
  formFieldTypes,
  ModeIcons,
  StatusCodes,
  MasterLookup,
  SvgIconFrom,
} from '../../utility/constants';
import { CustomErrorTooltipComponent } from '../custom-error-tooltip/custom-error-tooltip.component';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

@Component({
  selector: 'app-mailing-address',
  standalone: true,
  imports: [SharedModule, CustomErrorTooltipComponent],
  templateUrl: './mailing-address.component.html',
  styleUrl: './mailing-address.component.scss',
})
export class MailingAddressComponent implements OnInit {
  @Input() isFrom!: string;
  @Input() isSameAsAddress!: boolean;
  @Output() accountAddressFormDataValue = new EventEmitter<any>();
  @Input() pageMode!: string;
  public readonly modeIcons = ModeIcons;

  public accountAddressForm!: FormGroup;
  public formFields!: FormField[];
  public formFieldType = formFieldTypes;
  public actionIcons = ActionIcons;
  public filteredCountryTypeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public filteredStateDataTypeOptions!:
    | Observable<IDropdownResponse[]>
    | undefined;
  public countryList!: IDropdownResponse[];
  public stateList!: IDropdownResponse[];
  public errorMessages!: any;
  public setMailingAddressWidth: any = {};
  public setMailingAddressErrorTooltip: any = {};
  public setMailingAddressTooltipEvent: any = {};
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  public countryCode = countryCodes;
  public setAddressInfoErrorTooltip: any = {};
  public setAddressInfoTooltipEvent: any = {};
  public setAddressInfoWidth: any = {};
  public svgIconFromList = SvgIconFrom;
  constructor(
    private readonly fromUtilsService: ValidatorService,
    public readonly commonService: CommonService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly formUtilsService: ValidatorService
  ) {
    this.setReqInit();
  }

  ngOnInit(): void {
    this.checkMode();
  }

  private setReqInit() {
    this.createForm();
    this.formFields = mailingAddressFormConfig.fields;
    this.getCountryList();
  }

  public checkControlHasError(controlName: string) {
    return this.commonService.checkControlError(
      this.accountAddressForm,
      controlName
    );
  }

  public fetchWidth(event: MouseEvent, controlName: string) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setMailingAddressWidth,
      this.setMailingAddressTooltipEvent,
      this.setMailingAddressErrorTooltip
    );
  }

  public fetchWidthPhone(
    event: MouseEvent,
    controlName: string,
    isFrom?: string
  ) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setAddressInfoWidth,
      this.setAddressInfoTooltipEvent,
      this.setAddressInfoErrorTooltip,
      0,
      isFrom
    );
  }

  public fetchErrorMessages(controlName: string): string {
    if (
      this.accountAddressForm.get(controlName)?.errors?.[
        conditionCheckConstants.autocompleteError
      ]
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.fromUtilsService.getErrorMessage(
        this.accountAddressForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public mailingAddressHeader(isFrom: string): string {
    return isFrom === conditionCheckConstants.billingAddress
      ? ''
      : AccordionTabs.Address;
  }

  public dispatchAccountsAddressFormChanges() {
    this.accountAddressFormDataValue.emit({
      isFrom: this.isFrom,
      formData: this.accountAddressForm.getRawValue(),
    });
    this.setOrRemoveCountryBasedValidation(
      this.accountAddressForm.get('countryCode')?.value
    );
  }

  private createForm() {
    const { form, errorMessages } = this.fromUtilsService.generateForm(
      mailingAddressFormConfig
    );
    this.accountAddressForm = form;
    this.errorMessages = errorMessages;
  }

  public getAddressPlaceholder(controlName: string) {
    return this.commonService.getPlaceholder(
      this.accountAddressForm,
      controlName,
      this.pageMode,
      this.modeIcons
    );
  }

  public getStateList(data: any) {
    if (data) {
      this.commonService
        .getStateData(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: any) => {
            if (data.code == StatusCodes.Success) {
              this.setOrRemoveCountryBasedValidation(
                this.accountAddressForm.get('countryCode')?.value
              );
              this.stateList = data?.data || [];
              this.filteredStateDataTypeOptions =
                this.addressCompAutocompleteValueChanges(
                  'state',
                  this.stateList,
                  this.accountAddressForm
                );
            } else if (data.statusCode == StatusCodes.SuccessfulRequest) {
              this.removeControlsValidationCountryBased();
            } else {
              this.errorHandlerService.handleApiError(data);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.errorHandlerService.handleError(error);
          },
        });
    }
  }

  private setOrRemoveCountryBasedValidation(countryCodeVal: string) {
    if (
      countryCodeVal === countryCodes.usa ||
      countryCodeVal === countryCodes.canada ||
      countryCodeVal === countryCodes.brazil
    ) {
      this.setControlsValidationCountryBased();
    } else {
      this.removeControlsValidationCountryBased();
    }
  }

  private setControlsValidationCountryBased() {
    this.commonService.setControlRequiredValidation(
      this.accountAddressForm,
      'state'
    );
    this.commonService.setControlRequiredValidation(
      this.accountAddressForm,
      'zipCode'
    );
    this.commonService.clearControlValidators(this.accountAddressForm, 'phone');
    this.commonService.setPhoneControlValidation(
      this.accountAddressForm,
      'phone',
      true
    );
  }

  private addressCompAutocompleteValueChanges(
    controlName: string,
    autocompleteDropdownData: IDropdownResponse[],
    form: FormGroup
  ): Observable<IDropdownResponse[]> | undefined {
    const formControl = form.get(controlName);
    return formControl?.valueChanges.pipe(
      startWith(''),
      map(value => {
        const modifiedValue = value?.toLowerCase() || '';
        if (!modifiedValue) {
          return autocompleteDropdownData;
        }
        if (controlName === 'countryCode') {
          return autocompleteDropdownData?.filter(opt =>
            opt.id.toLowerCase().includes(modifiedValue)
          );
        } else {
          return autocompleteDropdownData?.filter(opt =>
            opt.name.toLowerCase().includes(modifiedValue)
          );
        }
      })
    );
  }

  private removeControlsValidationCountryBased() {
    const countryCode = this.accountAddressForm.get('countryCode')?.value;
    if (
      countryCode !== countryCodes.usa ||
      countryCode !== countryCodes.canada ||
      countryCode !== countryCodes.brazil
    ) {
      this.commonService.clearControlValidators(
        this.accountAddressForm,
        'state'
      );
      this.commonService.clearControlValidators(
        this.accountAddressForm,
        'phone'
      );
      this.commonService.setPhoneControlValidation(
        this.accountAddressForm,
        'phone',
        false
      );
    }
  }

  public setAutocompleteDropdownError(formControlId?: string) {
    this.commonService.getAutocompleteDropdownIdParam(
      this.countryList,
      this.accountAddressForm,
      'countryCode',
      'countryListIdVal',
      formControlId
    );
  }

  public clearFields(controlName: string, controlValue: string) {
    if (controlValue !== '') {
      return;
    }
    if (controlName === 'countryCode') {
      this.accountAddressForm.get('state')?.setValue('');
      this.dispatchAccountsAddressFormChanges();
    }
  }

  private getCountryList() {
    this.commonService
      .getMasterLookUp(MasterLookup.countries)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          if (data.statusCode == StatusCodes.Success) {
            this.countryList = data.data;
            this.filteredCountryTypeOptions =
              this.addressCompAutocompleteValueChanges(
                'countryCode',
                this.countryList,
                this.accountAddressForm
              );
          } else {
            this.errorHandlerService.handleApiError(data);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  public formAutocompleteValueChanges(
    controlName: string,
    apiControlName: string,
    dropdownResponse: any,
    form: any,
    formControlId?: string
  ) {
    if (dropdownResponse && controlName == 'countryCode') {
      return this.commonService.getAutocompleteDropdownIdParam(
        dropdownResponse,
        form,
        controlName,
        apiControlName,
        formControlId
      );
    } else if (dropdownResponse && controlName != 'countryCode') {
      return this.commonService.getAutocompleteDropdownId(
        dropdownResponse,
        form,
        controlName,
        apiControlName,
        false,
        formControlId
      );
    } else {
      this.getErrorMessage(controlName);
    }
  }

  public getErrorMessage(controlName: string): string {
    if (
      this.accountAddressForm.get(controlName)?.errors?.['autocompleteError']
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.formUtilsService.getErrorMessage(
        this.accountAddressForm,
        this.errorMessages,
        controlName
      );
    }
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ModeIcons.view) {
          this.accountAddressForm.disable();
        } else {
          this.accountAddressForm.enable();
        }
      });
  }
}
