import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormField, IDropdownResponse } from '../../models/dynamic.model';
import { ValidatorService } from '../../services/form-control-validators.service';
import { mailingAddressFormConfig } from './mailing-address.config';
import { SharedModule } from '../../shared.module';
import { CommonService } from '../../services/common.service';
import {
  AccordionTabs,
  conditionCheckConstants,
  validationErrorMessages,
  ActionIcons,
  countryCodes,
  formFieldTypes,
  ModeIcons,
  StatusCodes,
  SvgIconFrom,
} from '../../utility/constants';
import { CustomErrorTooltipComponent } from '../custom-error-tooltip/custom-error-tooltip.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { ModalService } from '../../services/modal.service';
import { CommonPopupService } from '../../services/common-popup.service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-mailing-address',
  standalone: true,
  imports: [SharedModule, CustomErrorTooltipComponent],
  templateUrl: './mailing-address.component.html',
  styleUrl: './mailing-address.component.scss',
})
export class MailingAddressComponent implements OnInit {
  @Input() isFrom!: string;
  @Input() isSameAsAddress!: boolean;
  @Output() accountAddressFormDataValue = new EventEmitter<any>();
  @Input() pageMode!: string;
  public readonly modeIcons = ModeIcons;

  public accountAddressForm!: FormGroup;
  public formFields!: FormField[];
  public formFieldType = formFieldTypes;
  public actionIcons = ActionIcons;
  public countryList!: IDropdownResponse[];
  public stateList!: IDropdownResponse[];
  public errorMessages!: any;
  public setMailingAddressWidth: any = {};
  public setMailingAddressErrorTooltip: any = {};
  public setMailingAddressTooltipEvent: any = {};
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  public validationCountryCode = countryCodes;
  public setAddressInfoErrorTooltip: any = {};
  public setAddressInfoTooltipEvent: any = {};
  public setAddressInfoWidth: any = {};
  public svgIconFromList = SvgIconFrom;
  private selectedCountryCode = '';
  private countries$!: Observable<any[]>;

  constructor(
    private readonly fromUtilsService: ValidatorService,
    public readonly commonService: CommonService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly formUtilsService: ValidatorService,
    private readonly commonPopupService: CommonPopupService,
    private readonly modalService: ModalService,
    private readonly store: Store
  ) {
    this.setReqInit();
  }

  ngOnInit(): void {
    this.checkMode();
    this.countries$.subscribe(data => {
      this.countryList = data;
    });
  }

  private setReqInit() {
    this.createForm();
    this.formFields = mailingAddressFormConfig.fields;
    this.countries$ = this.store.select(
      (state: any) => state.accountDropdowns.countries
    );
  }

  public checkControlHasError(controlName: string) {
    return this.commonService.checkControlError(
      this.accountAddressForm,
      controlName
    );
  }

  public fetchWidth(event: MouseEvent, controlName: string) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setMailingAddressWidth,
      this.setMailingAddressTooltipEvent,
      this.setMailingAddressErrorTooltip
    );
  }

  public fetchWidthPhone(
    event: MouseEvent,
    controlName: string,
    isFrom?: string
  ) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setAddressInfoWidth,
      this.setAddressInfoTooltipEvent,
      this.setAddressInfoErrorTooltip,
      0,
      isFrom
    );
  }

  public fetchErrorMessages(controlName: string): string {
    if (
      this.accountAddressForm.get(controlName)?.errors?.[
        conditionCheckConstants.autocompleteError
      ]
    ) {
      return validationErrorMessages.autocompleteError;
    } else if (
      this.accountAddressForm.get(controlName)?.errors?.['invalidState']
    ) {
      return validationErrorMessages.invalidState;
    } else {
      return this.fromUtilsService.getErrorMessage(
        this.accountAddressForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public mailingAddressHeader(isFrom: string): string {
    return isFrom === conditionCheckConstants.billingAddress
      ? ''
      : AccordionTabs.Address;
  }

  public getLookupFieldClearIcon(controlName: string) {
    return (
      (controlName === 'countryCode' &&
        this.commonService.isValidField(
          this.accountAddressForm.get('countryCode')?.value
        )) ||
      (controlName === 'state' &&
        this.commonService.isValidField(
          this.accountAddressForm.get('state')?.value
        ))
    );
  }

  public clearLookupFieldValues(controlName: string) {
    if (controlName === 'countryCode') {
      this.accountAddressForm.get('countryCode')?.setValue('');
      this.setOrRemoveCountryBasedValidation(
        this.accountAddressForm.get('countryCode')?.value
      );
    }
    this.accountAddressForm.get('state')?.setValue('');
  }

  public onLookupSearchIconClicked(controlName: string) {
    const lookupData =
      controlName === 'countryCode' ? this.countryList : this.stateList;
    if (lookupData) {
      const dialogRef = this.commonPopupService.openLookupPopup(
        this.modalService,
        controlName,
        lookupData,
        controlName === 'countryCode'
          ? this.accountAddressForm.get('countryCode')?.value
          : this.accountAddressForm.get('state')?.value
      );
      dialogRef.afterClosed().subscribe((popupData: any) => {
        if (popupData && controlName === 'countryCode') {
          this.commonService.markControlTouched(
            this.accountAddressForm,
            controlName
          );
          this.commonService.setControlRequiredValidation(
            this.accountAddressForm,
            controlName
          );
        } else if (popupData && controlName === 'state') {
          this.commonService.markControlTouched(
            this.accountAddressForm,
            controlName
          );
          this.setOrRemoveCountryBasedValidation(
            this.accountAddressForm.get('countryCode')?.value
          );
        }
        if (popupData?.data && controlName === 'countryCode') {
          this.accountAddressForm
            .get('countryCode')
            ?.setValue(popupData?.data.country);
          this.accountAddressForm.get('state')?.setValue('');
          this.dispatchAccountsAddressFormChanges();
          this.setOrRemoveCountryBasedValidation(
            this.accountAddressForm.get('countryCode')?.value
          );
          this.accountAddressForm.markAsDirty();
        } else if (popupData?.data && controlName === 'state') {
          this.accountAddressForm.get('state')?.setValue(popupData?.data.state);
          this.accountAddressForm.markAsDirty();
          this.dispatchAccountsAddressFormChanges();
        }
      });
    }
    setTimeout(() => {
      this.commonService.markControlUnTouched(
        this.accountAddressForm,
        controlName
      );
      this.commonService.clearControlValidators(
        this.accountAddressForm,
        controlName
      );
    }, 200);
  }

  public dispatchAccountsAddressFormChanges() {
    this.accountAddressFormDataValue.emit({
      isFrom: this.isFrom,
      formData: this.accountAddressForm.getRawValue(),
    });
    this.setOrRemoveCountryBasedValidation(
      this.accountAddressForm.get('countryCode')?.value
    );
  }

  private createForm() {
    const { form, errorMessages } = this.fromUtilsService.generateForm(
      mailingAddressFormConfig
    );
    this.accountAddressForm = form;
    this.errorMessages = errorMessages;
  }

  public getAddressPlaceholder(controlName: string) {
    return this.commonService.getPlaceholder(
      this.accountAddressForm,
      controlName,
      this.pageMode,
      this.modeIcons
    );
  }

  public getStateList(data: string, openPopup: boolean) {
    const previousSelectedCountryCode = this.selectedCountryCode;
    const currentSelectedCountryCode = this.commonService.isValidField(
      this.accountAddressForm.get('countryCode')?.value
    )
      ? this.accountAddressForm.get('countryCode')?.value
      : data;
    this.selectedCountryCode = currentSelectedCountryCode;
    if (data && previousSelectedCountryCode !== currentSelectedCountryCode) {
      this.commonService
        .getStateData(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: any) => {
            if (data.code == StatusCodes.Success) {
              this.stateList = data?.data || [];
              if (openPopup) {
                this.onLookupSearchIconClicked('state');
              } else {
                const selectedState = this.stateList?.find(
                  state =>
                    state.stateCode ===
                    this.accountAddressForm.get('state')?.value
                );
                const selectedStateValue = selectedState
                  ? selectedState.stateCode + ' - ' + selectedState.name
                  : this.accountAddressForm.get('state')?.value;
                this.accountAddressForm
                  .get('state')
                  ?.setValue(selectedStateValue);
              }
            } else if (data.statusCode == StatusCodes.SuccessfulRequest) {
              this.removeControlsValidationCountryBased();
            } else {
              this.errorHandlerService.handleApiError(data);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.errorHandlerService.handleError(error);
          },
        });
    } else if (
      data &&
      previousSelectedCountryCode === currentSelectedCountryCode
    ) {
      this.onLookupSearchIconClicked('state');
    } else if (!data) {
      this.accountAddressForm.get('state')?.markAsTouched();
      this.accountAddressForm.get('state')?.setErrors({ invalidState: true });
    }
  }

  private setOrRemoveCountryBasedValidation(countryCodeVal: string) {
    const countryCodeValue = countryCodeVal.split(' - ')[0];
    if (
      countryCodeValue === countryCodes.usa ||
      countryCodeValue === countryCodes.canada ||
      countryCodeValue === countryCodes.brazil
    ) {
      this.setControlsValidationCountryBased();
    } else {
      this.removeControlsValidationCountryBased();
      if (this.setMailingAddressErrorTooltip['state']) {
        this.commonService.closeErrorTooltip(
          'state',
          this.setMailingAddressErrorTooltip
        );
      }
    }
  }

  private setControlsValidationCountryBased() {
    this.commonService.setControlRequiredValidation(
      this.accountAddressForm,
      'state'
    );
    this.commonService.setControlRequiredValidation(
      this.accountAddressForm,
      'zipCode'
    );
    this.commonService.clearControlValidators(this.accountAddressForm, 'phone');
    this.commonService.setPhoneControlValidation(
      this.accountAddressForm,
      'phone',
      true
    );
  }

  private removeControlsValidationCountryBased() {
    const countryCodeValue = this.accountAddressForm
      .get('countryCode')
      ?.value?.split(' - ')[0];
    if (
      countryCodeValue !== countryCodes.usa ||
      countryCodeValue !== countryCodes.canada ||
      countryCodeValue !== countryCodes.brazil
    ) {
      this.commonService.clearControlValidators(
        this.accountAddressForm,
        'state'
      );
      this.commonService.clearControlValidators(
        this.accountAddressForm,
        'zipCode'
      );
      this.accountAddressForm
        .get('zipCode')
        ?.setValidators([Validators.minLength(4), Validators.maxLength(10)]);
      this.accountAddressForm.get('zipCode')?.updateValueAndValidity();
      this.commonService.clearControlValidators(
        this.accountAddressForm,
        'phone'
      );
      this.commonService.setPhoneControlValidation(
        this.accountAddressForm,
        'phone',
        false
      );
    }
  }

  public formAutocompleteValueChanges(
    controlName: string,
    apiControlName: string,
    dropdownResponse: any,
    form: any,
    formControlId?: string
  ) {
    if (dropdownResponse && controlName != 'countryCode') {
      return this.commonService.getAutocompleteDropdownId(
        dropdownResponse,
        form,
        controlName,
        apiControlName,
        false,
        formControlId
      );
    } else {
      this.getErrorMessage(controlName);
    }
  }

  public getErrorMessage(controlName: string): string {
    if (
      this.accountAddressForm.get(controlName)?.errors?.['autocompleteError']
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.formUtilsService.getErrorMessage(
        this.accountAddressForm,
        this.errorMessages,
        controlName
      );
    }
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.pageMode = data;
        if (data === ModeIcons.view) {
          this.accountAddressForm.disable();
        } else {
          this.accountAddressForm.enable();
        }
      });
  }
}
