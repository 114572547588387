import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './features/components/dashboard/dashboard.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { CanActivateGuard } from './core/guards/can-activate.guard';
import { SummaryPagePrintComponent } from './shared/components/summary-page-print/summary-page-print.component';
import { InformationPagePrintComponent } from './shared/components/information-page-print/information-page-print.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [CanActivateGuard],
    data: { breadcrumb: { alias: 'dashboard' } },
  },
  {
    path: 'hawb',
    canActivate: [CanActivateGuard],
    loadChildren: () =>
      import('./features/components/hawb/hawb.module').then(m => m.HawbModule),
    data: { breadcrumb: { alias: 'hawb' } },
  },
  {
    path: 'bag',
    canActivate: [CanActivateGuard],
    loadChildren: () =>
      import('./features/components/bag/bag.module').then(m => m.BagModule),
    data: { breadcrumb: { alias: 'bag' } },
  },
  {
    path: 'manifest',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./features/components/manifest/manifest.module').then(
        m => m.ManifestModule
      ),
    data: { breadcrumb: { alias: 'manifest' } },
  },
  {
    path: 'mawb',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./features/components/mawb/mawb.module').then(m => m.MawbModule),
    data: { breadcrumb: { alias: 'mawb' } },
  },
  {
    path: 'sm-dashboard',
    canActivate: [CanActivateGuard],
    loadChildren: () =>
      import('./features/components/smDashboard/sm-dashboard.module').then(
        m => m.SmDashboardModule
      ),
    data: { breadcrumb: { alias: 'sm-dashboard' } },
  },
  {
    path: '',
    canActivate: [CanActivateGuard],
    component: DashboardComponent,
  },
  {
    path: 'print-preview',
    canActivate: [CanActivateGuard],
    component: SummaryPagePrintComponent,
  },
  {
    path: 'info-print-preview',
    canActivate: [CanActivateGuard],
    component: InformationPagePrintComponent,
  },
  // {
  //   // Needed for handling redirect after login
  //   path: 'auth',
  //   component: MsalRedirectComponent,
  // },
  {
    path: 'user-profile',
    canActivate: [CanActivateGuard],
    loadChildren: () =>
      import(
        '../app/features/components/user-profile/user-profile.module'
      ).then(module => module.UserProfileModule),
    data: { breadcrumb: { alias: 'user-profile' } },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      canceledNavigationResolution: 'computed',
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
