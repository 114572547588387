import { Component, Inject, OnInit } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  LinkedAccountConstants,
  ModalPopupMessages,
  popupButtons,
  StatusCodes,
  validationErrorMessages,
  UserManagementConstants,
} from '../../utility/constants';
import { ValidatorPattern } from '../../utility/validators.pattern';
import {
  GatewayMasterShipmentsGridContent,
  ShipmentBagGridContent,
  ShipmentGatewayPieceGridContent,
} from '../../models/dynamic.model';
import { TableColumnsProp } from '../../models/tables.model';
import { SearchModule } from '../search/search.module';
import { SnakbarService } from '../../services/snakbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-message-popup',
  standalone: true,
  imports: [CommonModule, MaterialModule, SvgIconComponent, SearchModule],
  templateUrl: './message-popup.component.html',
  styleUrl: './message-popup.component.scss',
})
export class MessagePopupComponent implements OnInit {
  public messagePopupData: any;
  public confirmValue!: string;
  public popupMessages = ModalPopupMessages;
  public isDeleteAction = false;
  public deleteText = ModalPopupMessages.deleteConfirmationText;
  public requiredBulkDeleteText = '';
  public requiredBulkBagText = '';
  public requiredBulkGatewayText = '';
  public popupButtons = popupButtons;
  public fileType = '';
  public summaryTableColumns: any;
  public isPrintDisable = false;
  public shipmentGatewayPieceGridColumns!: TableColumnsProp[];
  public shipmentGatewayPieceTableData!: ShipmentGatewayPieceGridContent[];
  public page!: string;
  public masterShipmentTableColumnConfig!: TableColumnsProp[];
  public masterShipmentTableData!: GatewayMasterShipmentsGridContent[];
  public tableisFrom!: string;
  public shipmentBagGridColumns!: TableColumnsProp[];
  public shipmentBagPieceTableData!: ShipmentBagGridContent[];
  public selectedOption: string | null = null;
  selectedRowData: any;
  private isConfirmValueValid = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ref: MatDialogRef<MessagePopupComponent>,
    private readonly snakbarService: SnakbarService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly commonService: CommonService
  ) {
    this.confirmValue = '';
  }

  ngOnInit(): void {
    this.messagePopupData = this.data;
    this.deleteText = ModalPopupMessages.deleteConfirmationText;
    this.updateDeleteConfirmationText();
    this.shipmentGatewayPieceGridColumns =
      this.messagePopupData.code?.tableColumnConfig;
    this.shipmentGatewayPieceTableData =
      this.messagePopupData.code?.tableColumnDataSource;
    this.page = this.messagePopupData.code?.isFrom;
    this.masterShipmentTableColumnConfig =
      this.messagePopupData.code?.tableColumns;
    this.masterShipmentTableData = this.messagePopupData.code?.tableData;
    this.shipmentBagGridColumns =
      this.messagePopupData.code?.tableBagColumnConfig;
    this.shipmentBagPieceTableData =
      this.messagePopupData.code?.tableBagColumnDataSource;
    this.tableisFrom = this.messagePopupData.code?.tableIsFrom;
    this.confirmValue = this.messagePopupData.code?.isForCreateLinkedAccount
      ? this.messagePopupData.code?.linkedAccountNumber
      : '';
  }

  public getConfirmText() {
    if (
      this.messagePopupData.code?.isForBulkBagConfirmation ||
      this.messagePopupData.code?.isForBulkGateway
    ) {
      return this.messagePopupData.code?.confirmText;
    } else if (this.messagePopupData.code?.isForUserCreate) {
      return '';
    } else {
      return this.deleteText;
    }
  }

  public getCancelButtonName() {
    let buttonName = popupButtons.Cancel;
    if (
      this.messagePopupData.code?.isMultiple ||
      this.messagePopupData.code?.isForDownload ||
      this.messagePopupData.code?.isForSearch ||
      this.messagePopupData.code?.isForPublish ||
      this.messagePopupData.code?.isForPrint ||
      this.messagePopupData.code?.renderTable ||
      this.messagePopupData.code?.isForBulkGateway ||
      this.messagePopupData.code?.isForBulkBagConfirmation ||
      this.messagePopupData.code?.renderTable ||
      this.messagePopupData.code?.isForGatewayLocationMismatch ||
      this.messagePopupData.code?.isForCbpFail ||
      this.messagePopupData.code?.renderBagShipmentTable ||
      this.messagePopupData.code?.isForHandlingExisitingAccount ||
      this.messagePopupData.code?.renderBagShipmentTable ||
      this.messagePopupData.code?.isForCreateLinkedAccount
    ) {
      buttonName = popupButtons.Cancel;
    } else if (this.messagePopupData.code?.isForDiscardChanges) {
      buttonName = popupButtons.ContinueEdit;
    } else if (this.messagePopupData.code?.isRoleDuplicate) {
      buttonName = popupButtons.discardCreation;
    } else if (!this.messagePopupData.code?.isMultiple) {
      buttonName = popupButtons.No;
      if (this.messagePopupData.code?.isOptionalTextbox) {
        buttonName = popupButtons.Cancel;
      }
    }
    return buttonName;
  }

  public confirm() {
    if (
      !this.messagePopupData.code?.isMultiple &&
      this.messagePopupData.code?.isOptionalTextbox
    ) {
      this.ref.close({ popupClose: true, comments: this.confirmValue });
    } else if (this.messagePopupData.code?.isForCreateLinkedAccount) {
      this.validateAccountNumber(this.confirmValue);
    } else if (this.messagePopupData.code?.isForUserCreate) {
      this.ref.close({
        email: this.confirmValue,
        popupClose: true,
        clickedIcon: true,
      });
    } else if (this.messagePopupData.code?.renderBagShipmentTable) {
      this.ref.close({ popupClose: true, data: this.selectedRowData });
    } else if (
      !this.messagePopupData.code?.isMultiple &&
      this.messagePopupData.code?.isForDownload
    ) {
      this.ref.close({ popupClose: true, fileType: this.fileType });
    } else {
      this.ref.close(true);
    }
    this.defaultData();
  }

  public closePopup(event?: string) {
    if (this.messagePopupData.code?.isForUserCreate && !event) {
      this.ref.close({ email: '', popupClose: true, clickedIcon: false });
    } else if ((this.messagePopupData.code?.isAddHawb && event) || (this.messagePopupData.code?.isRoleDuplicate&& event)) {
      this.ref.close('close');
    } else {
      this.ref.close(false);
    }
    this.defaultData();
  }

  public editShipment() {
    this.ref.close(popupButtons.editShipment);
  }

  public getPopupButtonLabel(): string {
    const code = this.messagePopupData.code;
    if (code?.isMultiple || code?.isForSearch) {
      return this.popupButtons.Delete;
    } else if (code?.isForDiscardChanges) {
      return this.popupButtons.Discard;
    } else if (!code?.isMultiple && code?.isOptionalTextbox) {
      return code.isBlock;
    } else if (code?.renderTable || code?.renderBagShipmentTable) {
      return this.popupButtons.Ok;
    } else if (code?.isForBulkGateway || code?.isForBulkBagConfirmation) {
      return this.popupButtons.Confirm;
    } else if (code?.isForGatewayLocationMismatch) {
      return this.popupButtons.gateway;
    } else if (code?.isForCbpFail) {
      return this.popupButtons.proceedWithGateway;
    } else if (code?.isForCreateLinkedAccount) {
      return this.popupButtons.create;
    } else if (code?.isRoleDuplicate) {
      return this.popupButtons.editInformation;
    }
    return this.popupButtons.Yes;
  }

  public isButtonDisabled(): boolean {
    const code = this.messagePopupData.code;
    if (code?.renderBagShipmentTable && !this.selectedRowData) {
      return true;
    }
    if (code?.isForCreateLinkedAccount && !this.isConfirmValueValid) {
      return true;
    }
    if (code?.isForUserCreate && !this.isConfirmValueValid) {
      return true;
    }
    return (
      this.getClassValueForEnableDisable() ||
      (!code?.isMultiple && code?.isOptionalTextbox && !this.confirmValue)
    );
  }

  public proccedAndCloseMasterShipmentSelection(selectedShipmentData: any) {
    this.selectedRowData = selectedShipmentData;
    if (this.messagePopupData.code?.renderMasterShipmentTable) {
      this.ref.close({
        popupClose: true,
        masterShipmentData: selectedShipmentData,
      });
    } else if (this.messagePopupData.code?.renderTable) {
      this.ref.close({
        popupClose: true,
        selectedShipmentData: selectedShipmentData,
      });
    }
  }

  public normalizeText(text: string): string {
    return text.toLowerCase().replace(/\s+/g, '');
  }

  public validateConfirmValue(): void {
    if (this.messagePopupData.code?.isForCreateLinkedAccount) {
      const confirmValue = String(this.confirmValue);
      if (!confirmValue || confirmValue.length !== 7) {
        this.snakbarService.openCustomisedSnackBar(
          LinkedAccountConstants.wrongAccountNumber,
          ModalPopupMessages.Warning
        );
        this.isConfirmValueValid = false;
        return;
      }
      if (confirmValue.endsWith('00')) {
        this.snakbarService.openCustomisedSnackBar(
          LinkedAccountConstants.linkedAccountNotEndWith0,
          ModalPopupMessages.Warning
        );
        this.isConfirmValueValid = false;
        return;
      }
      this.isConfirmValueValid = true;
    } else if (this.messagePopupData.code?.isForUserCreate) {
      const confirmValue = String(this.confirmValue).trim();
      const emailRegex = ValidatorPattern.emailUserValidator; // Use the RegExp object
      if (!confirmValue) {
        this.isConfirmValueValid = true; // Empty confirmValue is valid
        return;
      }
      if (!emailRegex.test(confirmValue)) {
        this.snakbarService.openCustomisedSnackBar(
          UserManagementConstants.invalidEmail,
          ModalPopupMessages.Warning
        );
        this.isConfirmValueValid = false;
        return;
      }
      this.isConfirmValueValid = true; // Valid email
    }
  }

  private validateAccountNumber(accountNumber: string) {
    this.commonService.validateAccountNumber(accountNumber).subscribe({
      next: (data: any) => {
        if (data.statusCode === StatusCodes.Success) {
          this.ref.close({ popupClose: true, comments: this.confirmValue });
        } else if (data.statusCode === StatusCodes.SuccessfulRequest) {
          this.snakbarService.openCustomisedSnackBar(
            validationErrorMessages.accountAlreadyExist,
            ModalPopupMessages.Warning
          );
        }
      },
      error: (error: HttpErrorResponse) => {
        this.errorHandlerService.handleError(error);
      },
    });
  }

  public renderPlaceholder() {
    return this.messagePopupData.code?.isForUserCreate
      ? 'Enter Email ID'
      : 'Type here';
  }

  public handleFooterButtonActions(btnName: string) {
    if (btnName === 'Cancel') {
      this.ref.close();
    } else if (btnName === popupButtons.tryAnother) {
      this.ref.close({ popupClose: true, data: popupButtons.tryAnother });
    } else if (btnName === popupButtons.createLinkedAccount) {
      this.ref.close({
        popupClose: true,
        data: popupButtons.createLinkedAccount,
      });
    } else if (btnName === popupButtons.useSuggestedAccount) {
      this.ref.close({
        popupClose: true,
        data: popupButtons.useSuggestedAccount,
      });
    } else if (btnName === popupButtons.proceed) {
      this.ref.close({ popupClose: true, data: this.selectedOption });
    }
  }

  private updateDeleteConfirmationText() {
    if (this.messagePopupData.code?.isBulk) {
      this.bulkDeleteTextMessage();
    } else if (this.messagePopupData.code?.isForBulkBagConfirmation) {
      this.bulkConfirmation();
    } else if (this.messagePopupData.code?.isForBulkGateway) {
      const match = this.messagePopupData.code?.confirmText.match(/\b\d+\b/);
      if (match && match.length > 0) {
        const number = parseInt(match[0]);
        this.requiredBulkGatewayText = `Gateway ${number}`;
      } else {
        this.requiredBulkGatewayText = '';
      }
    } else {
      this.defaultData();
    }
  }

  private bulkConfirmation() {
    const match = this.messagePopupData.code?.confirmText.match(/\b\d+\b/);
    if (match && match.length > 0) {
      const number = parseInt(match[0]);
      this.requiredBulkBagText = `Bag ${number}`;
    } else {
      this.requiredBulkBagText = '';
    }
  }

  private bulkDeleteTextMessage() {
    const message = this.messagePopupData.code?.message;
    const regexPattern = /\b\d+\b/g;
    const match = message.match(regexPattern);
    if (match && match.length > 0) {
      if (match.length >= 2) {
        // Extract total permissions and non-deletable permissions
        const totalPermissions = parseInt(match[0]);
        const nonDeletablePermissions = parseInt(match[1]);
        const deletableCount = totalPermissions - nonDeletablePermissions;
        this.deleteText = `If Yes, Type "Delete ${deletableCount}" and click Delete`;
        this.requiredBulkDeleteText = `Delete ${deletableCount}`;
      } else {
        const number = parseInt(match[0]);
        this.deleteText = `If Yes, Type "Delete ${number}" and click Delete`;
        this.requiredBulkDeleteText = `Delete ${number}`;
      }
      this.isDeleteAction = true;
    } else {
      this.isDeleteAction = false;
      this.requiredBulkDeleteText = '';
    }
  }

  private defaultData() {
    this.isDeleteAction = false;
    this.deleteText = ModalPopupMessages.deleteConfirmationText;
    this.requiredBulkDeleteText = '';
    this.requiredBulkBagText = '';
    this.requiredBulkGatewayText = '';
  }

  public commentBoxChange(event: any) {
    const input = event.target as HTMLTextAreaElement;
    const trimmedValue = input.value.replace(
      ValidatorPattern.StartPositionSpaceNotAllowed,
      ''
    );
    if (input.value !== trimmedValue) {
      this.confirmValue = trimmedValue;
      input.value = trimmedValue;
    }
  }

  public getClassValueForEnableDisable() {
    return (
      (this.messagePopupData.code?.isMultiple &&
        !this.isDeleteTextMatch(this.confirmValue) &&
        this.confirmValue !== popupButtons.Delete) ||
      (this.messagePopupData.code?.isForBulkBagConfirmation &&
        this.normalizeText(this.confirmValue) !==
          this.normalizeText(this.requiredBulkBagText)) ||
      (this.messagePopupData.code?.isForBulkGateway &&
        this.normalizeText(this.confirmValue) !==
          this.normalizeText(this.requiredBulkGatewayText))
    );
  }

  public isDeleteTextMatch(text: string): boolean {
    return this.normalizeText(text) === popupButtons.smallDelete;
  }
}
