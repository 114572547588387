import { CommonModule } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MaterialModule } from '../../material.module';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ActionIcons,
  ModalPopupMessages,
  pageUrl,
  popupButtons,
} from '../../utility/constants';
import { TableColumns } from '../../models/dynamic.model';
import {
  paperSizes,
  paperSizesList,
  paperSizesWidth,
} from '../../configs/common-configs';
import { FormControl } from '@angular/forms';
import { SummaryPagePrintComponent } from '../summary-page-print/summary-page-print.component';
import { ModalService } from '../../services/modal.service';
import { CommonPopupService } from '../../services/common-popup.service';
import { Subject, takeUntil } from 'rxjs';
import { PrintService } from '../../services/print.service';

@Component({
  selector: 'app-print-preview-popup',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    SvgIconComponent,
    SummaryPagePrintComponent,
  ],
  templateUrl: './print-preview-popup.component.html',
  styleUrl: './print-preview-popup.component.scss',
})
export class PrintPreviewPopupComponent implements OnInit {
  @ViewChild('tableContainer', { read: ViewContainerRef, static: true })
  tableContainer!: ViewContainerRef;

  public printPopupData: any;
  public popupMessages = ModalPopupMessages;
  public popupButtons = popupButtons;
  public summaryTableColumns: any;
  public modifiedSummaryTableColumns: any;
  public isPrintDisable = false;
  public paperSizeList = paperSizes;
  public paperSizeFormControl = new FormControl('');
  public actionIcons = ActionIcons;
  public objectKeys = Object.keys;

  private childWindowRef: Window | null = null;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public printService: PrintService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ref: MatDialogRef<PrintPreviewPopupComponent>,
    private readonly modalService: ModalService,
    private readonly commonPopupService: CommonPopupService
  ) {}

  ngOnInit(): void {
    this.paperSizeFormControl.setValue(paperSizesList.Letter);
    this.printPopupData = this.data;
    this.setCheckboxColumns();
    this.getDataForPrintTable();
  }

  public selectAllColumnsInSection(objKey: string) {
    return this.summaryTableColumns[objKey].config.every(
      (col: any) => col.isSelected
    );
  }

  public onSectionCheckboxClicked(event: any, objKey: string) {
    this.summaryTableColumns[objKey].config.forEach((col: any) => {
      col.isSelected = event;
    });
  }

  // For selecting columns
  public handleCheckboxSelection(index: number, isSelected: boolean) {
    this.summaryTableColumns[index].isSelected = !isSelected;
    this.isPrintDisable = this.summaryTableColumns.every(
      (column: any) => !column.isSelected
    );
  }

  public handleInfoPageCheckboxSelection(
    selectedSection: string,
    selectedItemIndex: any,
    isSelected: boolean
  ) {
    this.summaryTableColumns[selectedSection].config[
      selectedItemIndex
    ].isSelected = !isSelected;
    this.isPrintDisable = Object.values(this.summaryTableColumns).every(
      (group: any) => group.config.every((header: any) => !header.isSelected)
    );
  }

  public closePrintPopup() {
    this.ref.close(false);
    this.printService.setDataForPreviewTable({});
  }

  public calculateTableWidthForPrint(isPrintClicked: boolean) {
    let selectedColumnsWidth;
    if (this.printPopupData.code?.isFromSummary) {
      selectedColumnsWidth = this.summaryTableColumns
        .filter((column: any) => column.isSelected)
        .reduce((acc: any, value: any) => {
          return acc + value.defaultPrintWidth;
        }, 0);
    } else {
      this.validateAndSetRequiredColumns();
      const totals = [];
      for (const group in this.modifiedSummaryTableColumns) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.modifiedSummaryTableColumns,
            group
          )
        ) {
          const totalWidth = this.modifiedSummaryTableColumns[
            group
          ].config.reduce((sum: any, header: any) => {
            return sum + header.defaultPrintWidth;
          }, 0);
          totals.push(totalWidth);
        }
      }
      selectedColumnsWidth = totals;
    }
    if (this.getConditionCheckForWidth(selectedColumnsWidth)) {
      isPrintClicked
        ? this.openPrintWarningPopup()
        : this.setPrintTableData(false, true);
    } else {
      this.setPrintTableData(isPrintClicked, false);
    }
  }

  private validateAndSetRequiredColumns() {
    this.modifiedSummaryTableColumns = JSON.parse(
      JSON.stringify(this.summaryTableColumns)
    );
    for (const key in this.modifiedSummaryTableColumns) {
      const selectedConfig = this.modifiedSummaryTableColumns?.[
        key
      ].config.filter((item: any) => item.isSelected);
      this.modifiedSummaryTableColumns[key].headerKeys = selectedConfig.map(
        (item: any) => item.key
      );
      this.modifiedSummaryTableColumns[key].headers = selectedConfig.map(
        (item: any) => item.label
      );
      this.modifiedSummaryTableColumns[key].config = selectedConfig;
      if (!this.modifiedSummaryTableColumns[key].config.length) {
        delete this.modifiedSummaryTableColumns[key];
      }
    }
  }

  private getConditionCheckForWidth(selectedColumnsWidth: any) {
    if (this.printPopupData.code?.isFromSummary) {
      return (
        (this.paperSizeFormControl.value === paperSizesList.Letter &&
          selectedColumnsWidth > paperSizesWidth.Letter) ||
        (this.paperSizeFormControl.value === paperSizesList.Legal &&
          selectedColumnsWidth > paperSizesWidth.Legal) ||
        (this.paperSizeFormControl.value === paperSizesList.A4 &&
          selectedColumnsWidth > paperSizesWidth.A4)
      );
    } else {
      return selectedColumnsWidth.some((sectionWidth: number) => {
        return (
          (this.paperSizeFormControl.value === paperSizesList.Letter &&
            sectionWidth > paperSizesWidth.Letter) ||
          (this.paperSizeFormControl.value === paperSizesList.Legal &&
            sectionWidth > paperSizesWidth.Legal) ||
          (this.paperSizeFormControl.value === paperSizesList.A4 &&
            sectionWidth > paperSizesWidth.A4)
        );
      });
    }
  }

  private setCheckboxColumns() {
    if (this.printPopupData.code?.isFromSummary) {
      this.printPopupData.code?.columns?.forEach(
        (col: TableColumns) => (col.isSelected = true)
      );
    }
    this.summaryTableColumns = this.printPopupData.code.columns;
  }

  private setPrintTableData(
    isPrintRequired: boolean,
    isPreviewWarning: boolean
  ) {
    if (this.printPopupData.code?.isFromSummary) {
      this.printService.setDataForPreviewTable({});
      this.printService.setDataForPreviewTable(
        this.getSelectedDataForPreview(isPrintRequired, isPreviewWarning)
      );
    } else {
      this.openNewWindowForPreviewPrint({
        ...this.getSelectedDataForPreview(isPrintRequired, isPreviewWarning),
        isFrom: this.printPopupData.code?.isFrom,
      });
    }
  }

  private getSelectedDataForPreview(
    isPrintRequired: boolean,
    isPreviewWarning: boolean
  ) {
    return {
      selectedColumns: this.printPopupData.code?.isFromSummary
        ? this.summaryTableColumns
        : this.modifiedSummaryTableColumns,
      selectedPageSize: this.paperSizeFormControl.value,
      isPrintDialogRequired: isPrintRequired,
      isPreviewWarning: isPreviewWarning,
    };
  }

  private getDataForPrintTable() {
    this.printService.printTableData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (Object.keys(data).length) {
          this.openNewWindowForPreviewPrint(data);
        }
      });
  }

  private openNewWindowForPreviewPrint(data: any) {
    const path = this.printPopupData.code?.isFromSummary
      ? pageUrl.printPreviewTable
      : pageUrl.printPreviewInfoTable;
    this.childWindowRef = window.open(
      `${window.location.origin}${path}`,
      'Child Window',
      'top=0,left=0,height=100%,width=auto'
    );
    // Wait for the child to load before sending the message
    if (this.childWindowRef) {
      setTimeout(() => {
        this.childWindowRef?.postMessage(data, `${window.location.origin}`);
      }, 1500);
    }
    this.printService.setPrintTableData({});
  }

  private openPrintWarningPopup() {
    const dialogRef = this.commonPopupService.openPrintWarningPopup(
      this.modalService
    );
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data) {
        this.setPrintTableData(true, false);
      }
    });
  }
}
