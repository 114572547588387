import { Injectable } from '@angular/core';
import { MessagePopupComponent } from '../components/message-popup/message-popup.component';
import {
  ActionIcons,
  actionText,
  lookupHeaders,
  ModalPopupMessages,
  pageType,
  popupWidth,
  StatusCodes,
  validationErrorMessages,
} from '../utility/constants';
import { IDropdownResponse, TableColumns } from '../models/dynamic.model';
import { PrintPreviewPopupComponent } from '../components/print-preview-popup/print-preview-popup.component';
import { LookupPopupComponent } from '../components/lookup-popup/lookup-popup.component';
import { LookupColumnsConfig } from '../configs/common-configs';
import { Subject, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { SnakbarService } from './snakbar.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class CommonPopupService {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly snakbarService: SnakbarService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly commonService: CommonService
  ) {}

  public openDownloadPopup(modalService: any) {
    const downloadDialogRef = modalService.openPopup(
      {
        isForDownload: true,
        isBulk: false,
        isFooterRequired: true,
      },
      ActionIcons.download,
      MessagePopupComponent,
      popupWidth.downloadPopup
    );
    return downloadDialogRef;
  }

  public openPrintPopup(
    modalService: any,
    isFrom: string,
    columns: TableColumns[],
    isRowSelected: boolean,
    isFromSummary: boolean
  ) {
    const downloadDialogRef = modalService.openPopup(
      {
        isFrom: isFrom,
        columns: columns,
        isRowSelected: isRowSelected,
        isFromSummary: isFromSummary,
      },
      ActionIcons.Print,
      PrintPreviewPopupComponent,
      popupWidth.printPopup
    );
    return downloadDialogRef;
  }

  public openDeletePopup(modalService: any, popupConfig: any) {
    const downloadDialogRef = modalService.openPopup(
      {
        message: popupConfig.message,
        isMultiple: true,
        isFooterRequired: true,
        isBulk: popupConfig.isBulk,
      },
      popupConfig.messageType,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    return downloadDialogRef;
  }

  public openBagExistsPopup(modalService: any) {
    modalService.openPopup(
      {
        message: validationErrorMessages.manifestAlreadyAdded,
        isFooterRequired: false,
      },
      ModalPopupMessages.Warning,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
  }

  public openPrintWarningPopup(modalService: any) {
    const printDialogRef = modalService.openPopup(
      {
        message:
          ModalPopupMessages.printWarningContent +
          '. ' +
          ModalPopupMessages.printWarningContentConfirmation,
        isForPrintWarning: true,
        isFooterRequired: true,
      },
      ModalPopupMessages.Warning,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    return printDialogRef;
  }

  public openLookupPopup(
    modalService: any,
    controlName: string,
    lookupData: IDropdownResponse[],
    selectedOption: string
  ) {
    const lookupColumns = LookupColumnsConfig.filter(
      columnsData => columnsData.label === this.getLookUpHeaderName(controlName)
    );
    const lookupDialogRef = modalService.openPopup(
      {
        showModalCloseOption: true,
        displayedColumnsList: [
          ...lookupColumns.map(column => column.key),
          'action',
        ],
        tableColumns: lookupColumns,
        tableData:
          controlName === 'countryCode' || controlName === 'state'
            ? this.getLookupData(controlName, lookupData)
            : lookupData,
        selectedOptionData: selectedOption,
      },
      this.getLookUpHeaderName(controlName),
      LookupPopupComponent,
      popupWidth.searchPopup
    );
    return lookupDialogRef;
  }

  private getLookUpHeaderName(controlName: string) {
    if (controlName === 'countryCode') {
      return lookupHeaders.country;
    } else if (controlName === 'state') {
      return lookupHeaders.state;
    } else if (controlName === 'origin') {
      return lookupHeaders.origin;
    } else {
      return lookupHeaders.destination;
    }
  }

  private getLookupData(controlName: string, lookupData: IDropdownResponse[]) {
    if (controlName === 'countryCode') {
      return lookupData.map(data => {
        return {
          ...data,
          country: data.id + ' - ' + data.name,
        };
      });
    } else if (controlName === 'state') {
      return lookupData.map(data => {
        return {
          ...data,
          state: data.stateCode + ' - ' + data.name,
        };
      });
    } else {
      return lookupData;
    }
  }

  public createErrorPopupData(
    modalService: any,
    message: string,
    title: string
  ) {
    modalService.openPopup(
      {
        message: message,
        isFooterRequired: false,
      },
      title,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
  }

  public sendClearanceForCBP(
    modalService: any,
    clearanceChannelType: IDropdownResponse[],
    isFromModule: any
  ) {
    const clearanceDialogRef = modalService.openPopup(
      {
        clearanceType: clearanceChannelType,
        isFrom: pageType.sendCBPClearance,
        isMultiple: false,
        isFooterRequired: true,
        isSendCBPClearance: true,
        isFromModule: isFromModule,
      },
      actionText.sendCbpClearance,
      MessagePopupComponent,
      popupWidth.searchPopup
    );
    return clearanceDialogRef;
  }

  public validateCBPClearance(
    selectedIds: number[],
    isFrom: string,
    callback: () => void,
    modalService: any,
    clearanceChannelType: any,
    isFromInfoPage?: boolean
  ) {
    this.commonService
      .validateCBP({
        ids: selectedIds,
        isFrom: isFrom,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (resp: any) => {
          if (resp.statusCode == StatusCodes.Success) {
            callback();
          } else if (resp.statusCode == StatusCodes.SuccessfulRequest) {
            if (resp.isButtonRequired) {
              this.validatePartialCBPClearance(
                resp.message,
                modalService,
                clearanceChannelType,
                isFrom,
                resp.applicableIds
              );
            } else {
              this.triggerCBPClearance(
                modalService,
                isFromInfoPage
                  ? this.getValidateCBPClearanceWarningMessage(isFrom)
                  : resp.message
              );
            }
          } else {
            this.errorHandlerService.handleApiError(resp);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  public validatePartialCBPClearance(
    message: string,
    modalService: any,
    clearanceShipmentChannelType: any,
    isFrom: string,
    ids: any
  ) {
    const partialDialogRef = modalService.openPopup(
      {
        isFrom: pageType.sendCBPClearance,
        isMultiple: false,
        isFooterRequired: true,
        isSendPartialValidate: true,
        message: message,
      },
      ModalPopupMessages.Warning,
      MessagePopupComponent,
      popupWidth.deletePopup
    );
    partialDialogRef.afterClosed().subscribe((popupData: any) => {
      if (popupData) {
        this.sendClearanceForValidateCBP(
          modalService,
          clearanceShipmentChannelType,
          isFrom,
          ids
        );
      }
    });
  }

  public getCBPClearnace(data: any, isFrom: string, isFromInfoPage?: boolean) {
    this.commonService
      .getCBPClearance(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (resp: any) => {
          if (resp.statusCode == StatusCodes.Success) {
            this.snakbarService.openCustomisedSnackBar(
              isFromInfoPage
                ? this.getCBPClearanceSuccessMessage(isFrom)
                : resp.message,
              ModalPopupMessages.success
            );
          } else if (resp.statusCode == StatusCodes.SuccessfulRequest) {
            this.snakbarService.openCustomisedSnackBar(
              isFromInfoPage
                ? this.getCBPClearanceErrorMessage(isFrom)
                : resp.message,
              ModalPopupMessages.error
            );
          } else {
            this.errorHandlerService.handleApiError(resp);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  private triggerCBPClearance(modalService: any, message: string) {
    modalService.openPopup(
      {
        isFooterRequired: true,
        isForWarning: true,
        message: message,
        isAccountDependency: true,
      },
      '',
      MessagePopupComponent,
      popupWidth.warningPopup
    );
  }

  private sendClearanceForValidateCBP(
    modalServiceEvent: any,
    clearanceShipmentChannelType: any,
    isFrom: string,
    ids: any
  ) {
    const dialogValidRef = this.sendClearanceForCBP(
      modalServiceEvent,
      clearanceShipmentChannelType,
      isFrom
    );
    dialogValidRef.afterClosed().subscribe((popupData: any) => {
      if (popupData) {
        const clearanceValidData = {
          ids: ids,
          isFrom: isFrom,
          clearanceChannelIds: popupData.data,
        };
        this.getCBPClearnace(clearanceValidData, isFrom);
      }
    });
  }

  private getCBPClearanceSuccessMessage(isFrom: string) {
    if (isFrom === 'shipment') {
      return ModalPopupMessages.ShipmentSendCBPSuccess;
    } else if (isFrom === 'bag') {
      return ModalPopupMessages.ShipmentFromBagManifestMawbSendCBPSuccess(
        'Bag'
      );
    } else if (isFrom === 'manifest') {
      return ModalPopupMessages.ShipmentFromBagManifestMawbSendCBPSuccess(
        'Manifest'
      );
    } else {
      return ModalPopupMessages.ShipmentFromBagManifestMawbSendCBPSuccess(
        'MAWB'
      );
    }
  }

  private getCBPClearanceErrorMessage(isFrom: string) {
    if (isFrom === 'shipment') {
      return ModalPopupMessages.ShipmentSendCBPError;
    } else if (isFrom === 'bag') {
      return ModalPopupMessages.ShipmentBagManifestMawbCBPError('Bag');
    } else if (isFrom === 'manifest') {
      return ModalPopupMessages.ShipmentBagManifestMawbCBPError('Manifest');
    } else {
      return ModalPopupMessages.ShipmentBagManifestMawbCBPError('MAWB');
    }
  }

  private getValidateCBPClearanceWarningMessage(isFrom: string) {
    if (isFrom === 'shipment') {
      return ModalPopupMessages.NoShipmentClearance;
    } else if (isFrom === 'bag') {
      return ModalPopupMessages.NoBagManifestMawbClearance('Bag');
    } else if (isFrom === 'manifest') {
      return ModalPopupMessages.NoBagManifestMawbClearance('Manifest');
    } else {
      return ModalPopupMessages.NoBagManifestMawbClearance('MAWB');
    }
  }
}
