import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import {
  BagDropdownList,
  DropdownList,
  IBagInfoData,
  IBagInformationData,
  IBagMiscellaneousStateModel,
  IBagShipmentListData,
  IShipmentStateModel,
  bagColumnData,
  defaultBagInfo,
} from 'src/app/features/model/bag.model';
import {
  defaultBagMiscellaneousData,
} from '../bag.config';
import {
  FetchBagsList,
  FetchSelectedBagById,
  LinkShipmentToBag,
  ResetBagMiscellaneous,
  ResetBagsInformationList,
  ResetBagsList,
  ResetPieceList,
  SaveBagMiscellaneous,
  UnlinkShipmentFromBag,
  UpdateBagInformation,
  DeleteBagShipment,
  FetchBagsInformationListSuccess,
  AddBagShipment,
  ResetBagShipmentList,
  FetchBagDropdowns,
} from './bag.action';

// Miscellaneous Section
@State<IBagMiscellaneousStateModel>({
  name: 'bagMiscellaneous',
  defaults: {
    miscellaneous: defaultBagMiscellaneousData(),
  },
})
@Injectable()
export class BagMiscellaneousState {
  @Action(SaveBagMiscellaneous)
  saveBagMiscellaneousData(
    ctx: StateContext<IBagMiscellaneousStateModel>,
    action: SaveBagMiscellaneous
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      miscellaneous: action.payload,
    });
  }

  @Action(ResetBagMiscellaneous)
  resetBagMiscellaneousData(ctx: StateContext<IBagMiscellaneousStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      miscellaneous: defaultBagMiscellaneousData(),
    });
  }
}

// Piece table data
@State<IShipmentStateModel>({
  name: 'bagShipmentList',
  defaults: {
    shipmentList: [],
  },
})
@Injectable()
export class BagPieceState {
  @Action(LinkShipmentToBag)
  linkPieceData(ctx: StateContext<IShipmentStateModel>, action: LinkShipmentToBag) {
    const state = ctx.getState();
    const updatedShipmentList = [...state.shipmentList, ...action.payload]; // Merge old state with new data
    ctx.setState({
      ...state,
      shipmentList: updatedShipmentList,
    });
  }

  @Action(UnlinkShipmentFromBag)
  unlinkPieceData(ctx: StateContext<IShipmentStateModel>, { payload }: any) {
    const state = ctx.getState();
    const filteredShipment = state.shipmentList.filter(
      shipment => shipment.id !== payload
    );
    ctx.setState({
      ...state,
      shipmentList: filteredShipment,
    });
  }

  @Action(ResetPieceList)
  resetBagPieceData(ctx: StateContext<IShipmentStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      shipmentList: [],
    });
  }
}

export interface BagsListStateModel {
  bags: bagColumnData[];
  totalData: number;
  origins: DropdownList[];
  PackagingType: DropdownList[];
  destinations: DropdownList[];
}

@Injectable()
@State<BagsListStateModel>({
  name: 'bags',
  defaults: {
    bags: [],
    totalData: 0,
    origins: [],
    PackagingType: [],
    destinations: [],
  },
})
export class BagsListState {

  @Action(FetchBagsList)
  FetchBagsList(ctx: StateContext<BagsListStateModel>, action: FetchBagsList) {
    const state = ctx.getState();
    const payload = action.payload;
    if (payload.data && payload.data.bags && payload.data.totalRecords) {
      ctx.setState({
        ...state,
        bags: payload.data.bags,
        totalData: payload.data.totalRecords,
      });
    } else {
      ctx.setState({
        ...state,
        bags: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetBagsList)
  resetBagPieceData(ctx: StateContext<BagsListStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bags: [],
      totalData: 0,
    });
  }
}

// // Bag Crud
export interface BagInformationStateModel {
  bagInformation: IBagInformationData;
  bagsInformationList: IBagInformationData[];
}
@State<BagInformationStateModel>({
  name: 'bagInformation',
  defaults: {
    bagInformation: {} as IBagInformationData,
    bagsInformationList: [],
  },
})
@Injectable()
export class BagInformationState {

  @Action(FetchBagsInformationListSuccess)
  fetchBagsInformationListSuccess(
    { getState, setState }: StateContext<BagInformationStateModel>,
    { bags }: FetchBagsInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      bagsInformationList: bags,
    });
  }

  @Action(FetchSelectedBagById)
  FetchSelectedBagById(
    ctx: StateContext<BagInformationStateModel>,
    action: FetchSelectedBagById
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bagInformation: state.bagsInformationList.filter(
        element => element.bagId == action.bagId
      )[0],
    });
  }

  @Action(ResetBagsInformationList)
  resetBagInfo(
    ctx: StateContext<BagInformationStateModel>,
    action: ResetBagsInformationList
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bagInformation: {} as IBagInformationData,
      bagsInformationList: [],
    });
  }
}
export interface BagInfoStateModel {
  bagInfo: IBagInfoData;
}

@State<BagInfoStateModel>({
  name: 'bagInfo',
  defaults: {
    bagInfo: defaultBagInfo,
  },
})
@Injectable()
export class BagInfoState {
  @Action(UpdateBagInformation)
  updateBagInformation(
    ctx: StateContext<BagInfoStateModel>,
    action: UpdateBagInformation
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bagInfo: action.payload,
    });
  }
}

export interface ShipmentBagStateModel {
  shipmentBag: IBagShipmentListData[];
}

@State<ShipmentBagStateModel>({
  name: 'shipmentBag',
  defaults: {
    shipmentBag: [],
  },
})
@Injectable()
export class PieceBagState {
  @Action(AddBagShipment)
  addBagPieces(
    ctx: StateContext<ShipmentBagStateModel>,
    { payload }: AddBagShipment
  ) {
    const state = ctx.getState();
    if (payload && payload.length >= 0) {
      if (payload.length === 0) {
        // If payload is empty, set piecesBag to an empty array
        ctx.setState({
          ...state,
          shipmentBag: [],
        });
      } else {
        // If payload is not empty, concatenate it with the existing piecesBag
        ctx.setState({
          ...state,
          shipmentBag: [...state.shipmentBag, ...payload],
        });
      }
    }
  }

  @Action(DeleteBagShipment)
  deleteBagPiece(
    ctx: StateContext<ShipmentBagStateModel>,
    { payload }: DeleteBagShipment
  ) {
    const state = ctx.getState();
    const filteredShipment = state.shipmentBag.filter(shipment =>
      shipment.shipment.id !== payload
    );
    ctx.setState({
      ...state,
      shipmentBag: filteredShipment,
    });
  }

  @Action(ResetBagShipmentList)
  resetBagPieceData(ctx: StateContext<ShipmentBagStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      shipmentBag: [],
    });
  }
}

@Injectable()
@State<BagDropdownList>({
  name: 'bagDropdowns',
  defaults: {
    origins: [],
    destinations: [],
    serviceType: [],
    attachmentType: [],
    agentTypeData: [],
    customerIdData: [],
    airlineCodeData: [],
  },
})
export class BagDropdownsListState {
  @Action(FetchBagDropdowns)
  saveShipmentLookUpData(
    ctx: StateContext<BagDropdownList>,
    action: FetchBagDropdowns
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      origins: action.payload.origins,
      destinations: action.payload.destinations,
      serviceType: action.payload.serviceType,
      attachmentType: action.payload.attachmentType,
      agentTypeData: action.payload.agentTypeData,
      customerIdData: action.payload.customerIdData,
      airlineCodeData: action.payload.airlineCodeData,
    });
  }
}