import { TableColumnsProp } from 'src/app/shared/models/tables.model';
import { DepartmentsGridContent } from './user-management.model';
import { FormConfig } from 'src/app/shared/models/dynamic.model';
import { ComponentMapping } from '../../model/manifest.model';
import {
  AccordionTabs,
  searchDataParam,
} from 'src/app/shared/utility/constants';
import { LoginInformationComponent } from './user-management-information/login-information/login-information.component';
import { ValidatorPattern } from 'src/app/shared/utility/validators.pattern';
import { IDepartment } from '../../model/sm-users';

const isSelectedAndDefaultView = {
  isSelected: true,
  defaultView: true,
};

export const userManagementColumnConfig: TableColumnsProp[] = [
  {
    ...isSelectedAndDefaultView,
    label: 'First Name',
    key: 'firstName',
    defaultPrintWidth: 120,
  },
  {
    ...isSelectedAndDefaultView,
    key: 'lastName',
    label: 'Last Name',
    defaultPrintWidth: 100,
  },
  {
    key: 'fullName',
    label: 'Full Name',
    ...isSelectedAndDefaultView,
    defaultPrintWidth: 100,
  },
  {
    key: 'email',
    ...isSelectedAndDefaultView,
    label: 'Email Address',
    defaultPrintWidth: 180,
  },
  {
    ...isSelectedAndDefaultView,
    key: 'defaultLocation',
    label: 'Default Location',
    defaultPrintWidth: 150,
  },
  {
    ...isSelectedAndDefaultView,
    label: 'Last Logged In',
    key: 'lastLoggedIn',
    defaultPrintWidth: 150,
  },
  {
    key: 'lastAccessedIpAddress',
    ...isSelectedAndDefaultView,
    label: 'Last Accessed IP Address',
    defaultPrintWidth: 150,
  },
  {
    key: 'noOfDepartments',
    isSelected: false,
    defaultView: true,
    label: 'Number of Departments',
    defaultPrintWidth: 150,
  },
  {
    key: 'noOfRoles',
    isSelected: false,
    defaultView: true,
    label: 'Number of Roles',
    defaultPrintWidth: 150,
  },
];

export const userLoginNetworkDetails = [
  {
    label: 'Last Login :',
  },
  {
    label: 'Last Access IP Address :',
  },
];

export const userInfoFormFields: FormConfig = {
  fields: [
    {
      required: true,
      label: 'Country',
      id: 'countryCode',
      type: 'input',
      requiredError: 'Country is required',
    },
    {
      label: 'Time Zone',
      id: 'timeZoneName',
      type: 'input',
      required: true,
      requiredError: 'Time Zone is required',
    },
    {
      label: 'Time Id',
      id: 'timeZoneId',
      type: 'extra',
    },
    {
      id: 'phone',
      label: 'Phone',
      type: 'input',
      patternError: 'Invalid Phone Number',
      required: false,
      requiredError: 'Invalid Phone Number',
      minError: 'Invalid Phone Number',
      noInitialWhiteSpace: true,
    },
    {
      id: 'email',
      label: 'Email',
      pattern: ValidatorPattern.Email_Validator,
      maxValue: 50,
      required: true,
      type: 'input',
      requiredError: 'Valid Email is required',
      emailPatternError: 'Invalid Email',
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      required: true,
      id: 'firstName',
      label: 'First Name',
      requiredError: 'First Name is required',
      type: 'input',
      maxValue: 126,
      allowAlphabetWithSpace: true,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      required: true,
      requiredError: 'Last Name is required',
      type: 'input',
      allowAlphabetWithSpace: true,
      maxValue: 126,
    },
    {
      isDisableField: true,
      type: 'input',
      id: 'fullName',
      label: 'Full Name',
    },
    {
      required: true,
      id: 'address_1',
      label: 'Address Line 1',
      requiredError: 'Address Line 1 is required',
      type: 'input',
      maxValue: 126,
    },
    {
      id: 'address_2',
      label: 'Address Line 2',
      maxValue: 126,
      isCheckForMaxValue: true,
      type: 'input',
    },
    {
      label: 'City',
      required: true,
      type: 'input',
      requiredError: 'City is required',
      id: 'city',
      minValue: 2,
      maxValue: 50,
      noInitialWhiteSpace: true,
      allowAlphaNumeric: true,
      minError: 'Invalid City entered',
      isCheckForMaxValue: true,
    },
    {
      type: 'input',
      id: 'state',
      requiredError: 'State/Province/Region is required',
      label: 'State/Province/Region',
    },
    {
      id: 'zipCode',
      type: 'input',
      label: 'Postal Code',
      required: true,
      requiredError: 'Postal Code is required',
      maxValue: 10,
      minValue: 4,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
      minError: 'Postal code must be between 4 and 10 characters',
    },
    {
      type: 'input',
      id: 'salesPersonNumber',
      label: 'Sales Person Number',
      maxValue: 9,
      allowNumeric: true,
      isDisableField: true,
      requiredError: 'Sales Person Number is required',
    },
    {
      id: 'status',
      label: 'Status',
      type: 'radio',
      options: [
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Inactive',
          value: 'inactive',
        },
      ],
    },
    {
      options: [
        {
          label: 'Lock',
          value: 'lock',
        },
        {
          label: 'Unlock',
          value: 'unlock',
        },
      ],
      id: 'access',
      type: 'radio',
      label: 'Access',
    },
    {
      id: 'defaultLocation',
      label: 'Default Location',
      type: 'input',
      isDisableField: true,
      required: true,
      requiredError: 'Default Location is required',
    },
    {
      id: 'userId',
      label: 'User ID',
      isDisableField: true,
      type: 'input',
    },
  ],
};

export const departmentTableData: DepartmentsGridContent[] = [
  {
    location: 'NYC',
    department: 'Sales',
    role: 'Manager',
  },
];

export const departmentColumnsConfig: TableColumnsProp[] = [
  {
    key: 'group',
    label: 'Group',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'department',
    label: 'Department',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    isSelected: true,
    defaultPrintWidth: 100,
    label: 'Role',
    key: 'role',
  },
  {
    key: 'location',
    label: 'Facility',
    isSelected: true,
    defaultPrintWidth: 100,
  },
];

export const departmentFormConfig: FormConfig = {
  fields: [
    {
      id: 'group',
      type: 'input',
      label: 'Group',
      requiredError: 'Group is required',
    },
    {
      id: 'groupId',
      type: 'extra',
    },
    {
      id: 'department',
      type: 'input',
      label: 'Department',
      requiredError: 'Department is required',
    },
    {
      id: 'departmentId',
      type: 'extra',
    },
    {
      id: 'role',
      type: 'input',
      label: 'Role',
      requiredError: 'Role is required',
    },
    {
      id: 'roleId',
      type: 'extra',
    },
    {
      type: 'input',
      id: 'location',
      label: 'Location',
      requiredError: 'Facility is required',
    },
    {
      id: 'locationId',
      type: 'extra',
    },
    {
      id: 'groupDepartmentRolePermissionsId',
      type: 'extra',
    },
    {
      id: 'id',
      type: 'extra',
    },
    {
      id: 'tempId',
      type: 'extra',
    },
    {
      id: 'isRowDisable',
      type: 'extra',
    },
    {
      id: 'action',
      type: 'extra',
    },
  ],
};

export const departmentInputRowData: DepartmentsGridContent[] = [
  {
    location: '',
    department: '',
    role: '',
    isInputRow: true,
  },
];

export const userManagementInfoAccordionTabConfig: ComponentMapping[] = [
  {
    title: AccordionTabs.Events,
    componentType: '',
  },
  {
    title: AccordionTabs.LoginInfo,
    componentType: LoginInformationComponent,
  },
];

export const loginInfoColumnConfig: TableColumnsProp[] = [
  {
    ...isSelectedAndDefaultView,
    label: 'Last Login Date & Time',
    key: 'lastLoggedIn',
  },
  {
    key: 'lastAccessedIpAddress',
    ...isSelectedAndDefaultView,
    label: 'Last Accessed IP Address',
  },
];

export const defaultDepartmentGridData: IDepartment[] = [
  {
    id: 0,
    tempId: '',
    action: 'create',
    location: '',
    locationId: '',
    role: '',
    roleId: '',
    department: '',
    departmentId: '',
    group: '',
    groupId: '',
    groupDepartmentRolePermissionsId: '',
    isRowDisable: false,
  },
];

export const defautUserPagination = {
  sortingOrder: searchDataParam.desc,
  sortingField: searchDataParam.userId,
  fromIndex: 0,
  toIndex: 10,
};

export const userExcelHeaders: string[] = [
  'First Name',
  'Last Name',
  'Full Name',
  'Email Address',
  'Default Location',
  'Last Accessed IP Address',
  'Number of Departments',
  'Number of Roles',
];

export const userInfoSectionHeaders: string[] = [
  'User Information',
  'Departments',
  'Events',
  'Login Information',
];

export const userInfoSectionHeaderKeys: string[] = [
  'userInfo',
  'departments',
  'events',
  'loginInfo',
];

export const userInfoHeaders = [
  {
    isSelected: true,
    key: 'countryCode',
    defaultPrintWidth: 120,
    label: 'Country Code',
  },
  {
    isSelected: true,
    key: 'timeZoneName',
    defaultPrintWidth: 180,
    label: 'Time Zone',
  },
  {
    label: 'Phone',
    key: 'phone',
    defaultPrintWidth: 130,
    isSelected: true,
  },
  {
    defaultPrintWidth: 200,
    label: 'Email',
    isSelected: true,
    key: 'email',
  },
  {
    label: 'First Name',
    key: 'firstName',
    defaultPrintWidth: 160,
    isSelected: true,
  },
  {
    defaultPrintWidth: 160,
    key: 'lastName',
    label: 'Last Name',
    isSelected: true,
  },
  {
    label: 'Full Name',
    isSelected: true,
    key: 'fullName',
    defaultPrintWidth: 160,
  },
  {
    key: 'address_1',
    isSelected: true,
    defaultPrintWidth: 160,
    label: 'Address Line 1',
  },
  {
    label: 'Address Line 2',
    isSelected: true,
    defaultPrintWidth: 160,
    key: 'address_2',
  },
  {
    isSelected: true,
    label: 'City',
    defaultPrintWidth: 160,
    key: 'city',
  },
  {
    label: 'State/Province/Region',
    defaultPrintWidth: 160,
    key: 'state',
    isSelected: true,
  },
  {
    label: 'Postal Code',
    defaultPrintWidth: 160,
    isSelected: true,
    key: 'zipCode',
  },
  {
    label: 'Sales Person Number',
    defaultPrintWidth: 160,
    isSelected: true,
    key: 'salesPersonNumber',
  },
  {
    label: 'Status',
    defaultPrintWidth: 160,
    isSelected: true,
    key: 'status',
  },
  {
    isSelected: true,
    label: 'Access',
    key: 'access',
    defaultPrintWidth: 160,
  },
  {
    label: 'Default Location',
    defaultPrintWidth: 130,
    isSelected: true,
    key: 'defaultLocation',
  },
  {
    label: 'User ID',
    defaultPrintWidth: 120,
    isSelected: true,
    key: 'userId',
  },
];

export const userLoginHistoryColumnsConfig: TableColumnsProp[] = [
  {
    label: 'Last Login Date & Time',
    isSelected: true,
    key: 'lastLoggedIn',
    defaultPrintWidth: 150,
  },
  {
    key: 'lastAccessedIpAddress',
    defaultPrintWidth: 100,
    label: 'Last Accessed IP Address',
    isSelected: true,
  },
];
