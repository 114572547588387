import {
  AccountSearchPayload,
  IAccountsDetailData,
  IAddressInfoDetailsData,
  IBusinessInfoDetailsData,
  ICustomerManagementDetailsData,
  IGovernmentInfoDetailsData,
} from 'src/app/features/model/sm-accounts';

// Accounts Detail Section
export class SaveAccountsDetail {
  static readonly type = '[Accounts Detail] Save';
  constructor(public payload: IAccountsDetailData) {}
}

export class ResetAccountsDetail {
  static readonly type = '[Accounts Detail] Reset';
}

// Address Information Section
export class SaveAddressDetails {
  static readonly type = '[Accounts Address Details] Save';
  constructor(public payload: IAddressInfoDetailsData) {}
}

// Business Information Section
export class SaveBusinessDetails {
  static readonly type = '[Accounts Business Details] Save';
  constructor(public payload: IBusinessInfoDetailsData) {}
}

export class SaveCustomerManagementDetails {
  static readonly type = '[Customer Management Details] Save';
  constructor(public payload: ICustomerManagementDetailsData) {}
}

// Government Information
export class SaveGovernmentDetails {
  static readonly type = '[Accounts Government Details] Save';
  constructor(public payload: IGovernmentInfoDetailsData) {}
}

export class FetchAccountList {
  static readonly type = '[Account] List';
  constructor(public payload: AccountSearchPayload) {}
}

export class ResetAccountList {
  static readonly type = '[Accounts] Reset Accounts List';
}

export class FetchAccountsInformationListSuccess {
  static readonly type = '[Account] Fetch Accounts Information List Success';
  constructor(public accounts: any[]) {}
}

export class FetchSelectedAccountById {
  static readonly type = '[Accounts] Fetch Selected Account By Id';
  constructor(public accountId: number) {}
}

export class ResetAccountsInformationList {
  static readonly type = '[Accounts] Reset Accounts Information List';
}
export class FetchAccountDropdowns {
  static readonly type = '[Accounts] Fetch Dropdowns';
  constructor(public payload: any) {}
}