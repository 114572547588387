import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';
import {
  ActionIcons,
  BulkDeleteItemNames,
  dateTimeFormat,
  fullmanAndCbpGridFields,
  fullmanAndCbpStatus,
  fullmanFileAndCbpGridActionMessages,
  gridMessages,
  InputRowKeys,
  MessageTemplates,
  ModalPopupMessages,
  pageType,
  popupButtons,
  popupWidth,
  selectedIdForSummaryTable,
  StatusCodes,
} from 'src/app/shared/utility/constants';
import { CommonService } from 'src/app/shared/services/common.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TableService } from '../tables.service';
import { TableColumnsProp } from 'src/app/shared/models/tables.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckboxStateService } from 'src/app/shared/services/checkbox-state.service';
import { IBasicSearchProps } from 'src/app/shared/models/dynamic.model';
import { MatPaginator } from '@angular/material/paginator';
import { CommonTableService } from 'src/app/shared/services/common-table.service';
import { MenuOverlayService } from 'src/app/shared/services/menu-overlay.service';
import { formatDate } from '@angular/common';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { MessagePopupComponent } from '../../message-popup/message-popup.component';
import { FullmanFilesService } from 'src/app/features/service/fullman-files.service';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrl: './summary-table.component.scss',
})
export class SummaryTableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() summaryGridColumns!: TableColumnsProp[];
  @Input() tableData: any;
  @Input() isFrom!: string;
  @Input() dataSearched!: boolean;
  @Input() totalCount!: number;
  @Input() paginationProps!: IBasicSearchProps;
  @Input() isSelectAllMessageRequired = true;
  @Input() isPaginationRequired = true;
  @Output() pageChangeEvent = new EventEmitter<unknown>();
  @Output() tableSelectionEvent = new EventEmitter<string>();
  @Output() navigateData = new EventEmitter<any>();
  @Output() navigateFullmaFileData = new EventEmitter<any>();
  @Output() proceedMasterShipmentEvent = new EventEmitter<any>();
  @ViewChild(MatSort) sort!: MatSort;
  selectedRowId: any;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator
  ) {
    if (paginator) {
      this.actualPaginator = paginator;
      this.isInLastPage = this.actualPaginator.hasNextPage();
    }
  }

  public actualPaginator!: MatPaginator;
  public dataSource = new MatTableDataSource<any>([]);
  public selection = new SelectionModel<any>(true, []);
  public tableSortDirection!: string;
  public summaryCustomiseTableColumns!: TableColumnsProp[];
  public displayedColumns!: string[];
  public noSearchMessage = '';
  public noMatchMessage!: string;
  public isAllCheckboxSelected!: boolean;
  public activeColumn!: string;
  public defaultSelectedColumn!: string;
  public selectedId!: string;
  public globalHeaderCheckboxState = false;
  public indeterminate = false;
  public isInLastPage = false;
  public previousPageSize = 10;
  public dateFormat = dateTimeFormat.onlyDate;
  public actionButtons!: string[];
  public buttonNames = popupButtons;
  public isOverlayOpen = false;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly globalSelectedIds: Set<number> = new Set<number>();
  private checkSelectedCount = 0;
  private isAllSelected = false;
  private selectAdditionalRows = false;
  private isListAllResultsClicked!: boolean;
  private isFullmanFileRejected = false;
  private selectedRow: any;

  constructor(
    public commonService: CommonService,
    public tableService: TableService,
    private readonly cdref: ChangeDetectorRef,
    private readonly checkboxService: CheckboxStateService,
    public commonTableService: CommonTableService,
    private readonly menuOverlayService: MenuOverlayService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly modalService: ModalService,
    private readonly fullmanFileService: FullmanFilesService
  ) {
    this.setRequiredInitialisation();
  }

  ngOnInit(): void {
    this.summaryCustomiseTableColumns = this.summaryGridColumns;
    this.isListAllResultsClickedData();
    this.prepareTableData();
    this.renderActionsButtonList();
    this.tableService.setCustomizeMenuColumns(
      this.summaryCustomiseTableColumns
    );
    this.tableService.setReorderedColumns(
      this.tableService.getFromLocalStorage(this.isFrom)
    );
    this.fetchFullManRejectStatus();
  }

  ngOnChanges() {
    this.dataSource.data = this.tableData;
    this.setDisplayColumnsForGrid();
    this.setGridColumnsOptions();
  }

  ngAfterViewInit() {
    this.getDataWithSortPagination();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public highlightSelectedRow(row: any) {
    return (
      this.selectedRow === row &&
      (this.isFrom === 'fetch-fullman-data' ||
        this.isFrom === 'cbp-request-status')
    );
  }

  // For table header checkbox selection
  public onCheckAllChange(event: MatCheckboxChange): void {
    const isChecked = event.checked;
    this.isAllSelected = false;
    if (isChecked) {
      this.isCheckedCheckAllChange();
    } else {
      this.isUncheckedCheckAllChange();
    }
    const pageLookup = {
      [pageType.account]: selectedIdForSummaryTable.Account,
      [pageType.agent]: selectedIdForSummaryTable.Agent,
      [pageType.smStatusCodes]: selectedIdForSummaryTable.SmStatusCodes,
      [pageType.smRolesAndPermissions]:
        selectedIdForSummaryTable.SmRolesAndPermissions,
      [pageType.userManagement]: selectedIdForSummaryTable.Id,
      [pageType.smAirports]: selectedIdForSummaryTable.SmAirports,
      [pageType.smAirlines]: selectedIdForSummaryTable.SmAirlines,
      [pageType.smNumberRanges]: selectedIdForSummaryTable.SmNumberRanges,
      [pageType.agentTypes]: selectedIdForSummaryTable.smAgentTypes,
      [pageType.hsCodes]: selectedIdForSummaryTable.smhsCode,
      [pageType.facilities]: selectedIdForSummaryTable.smFacilities,
    };
    if (isChecked) {
      this.getSelectionDataById(pageLookup[this.isFrom]);
    } else {
      this.getDeSelectionDataById(pageLookup[this.isFrom]);
    }
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  // for table body checkbox selection
  public checked(row: any) {
    const key = this.getCheckKey(row);
    if (!this.globalSelectedIds.has(key)) {
      this.globalSelectedIds.add(key);
      this.selection.select(row);
      this.checkSelectedCount++;
    }
    if (this.isAllSelected && this.globalSelectedIds.size === this.totalCount) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public unchecked(row: any) {
    const key = this.getCheckKey(row);
    if (this.globalSelectedIds.has(key)) {
      this.globalSelectedIds.delete(key);
      this.selection.deselect(row);
      this.checkSelectedCount--;
    }
    this.isAllSelected = false;
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public isChecked(row: any): boolean {
    const key = this.getCheckKey(row);
    return this.globalSelectedIds.has(key);
  }

  public dragAndDropGridColumns(event: CdkDragDrop<string[]>) {
    this.summaryCustomiseTableColumns =
      this.tableService.dragAndDropGridColumns(
        event,
        this.displayedColumns,
        this.summaryCustomiseTableColumns,
        this.tableService.getStorageName(this.isFrom),
        false
      );
  }

  public getBorderClass() {
    return (
      this.isFrom === pageType.shipmentGatewayMasterShipment ||
      this.isFrom === pageType.shipmentGatewayPiece ||
      this.isFrom === pageType.shipmentBag
    );
  }

  public onChangingPages(event: any) {
    const isPageSizeChanged = event.pageSize !== this.previousPageSize;
    this.previousPageSize = event.pageSize;
    if (isPageSizeChanged && this.globalHeaderCheckboxState) {
      this.selectAdditionalRows = true;
    } else {
      this.selectAdditionalRows = false;
    }
    this.paginationProps.paging.fromIndex =
      event.pageIndex * event?.pageSize + 1;
    this.paginationProps.paging.toIndex =
      this.paginationProps.paging.fromIndex + (event?.pageSize - 1);
    this.pageChangeEvent.emit({
      payload: this.paginationProps,
      isAllSelected: false,
    });
  }

  public getAllItemsSelectedMessage(): string {
    let allItemsMessage = '';
    if (this.isFrom === pageType.mawb) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Mawbs,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.agent) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Agents,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.hawb) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Shipments,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.manifest) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Manifests,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.bag) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Bags,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.userManagement) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.UserManagement,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.RolesAndPermissions,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.account) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Accounts,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.smNumberRanges) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.NumberRanges,
        this.dataSource.data.length
      );
    }
    return allItemsMessage;
  }

  public getSelectAllItemsMessage(): string {
    let selectAllMessage = '';
    if (this.isFrom === pageType.mawb) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Mawbs,
        this.totalCount
      );
    } else if (this.isFrom === pageType.agent) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Agents,
        4 //providing static count for reference
      );
    } else if (this.isFrom === pageType.hawb) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Shipments,
        this.totalCount
      );
    } else if (this.isFrom === pageType.manifest) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Manifests,
        this.totalCount
      );
    } else if (this.isFrom === pageType.bag) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Bags,
        this.totalCount
      );
    } else if (this.isFrom === pageType.userManagement) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.UserManagement,
        this.totalCount
      );
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.RolesAndPermissions,
        this.totalCount
      );
    } else if (this.isFrom === pageType.account) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Accounts,
        this.totalCount
      );
    } else if (this.isFrom === pageType.smNumberRanges) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.NumberRanges,
        this.totalCount
      );
    } else if (this.isFrom === pageType.smStatusCodes) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.StatusCodes,
        this.totalCount
      );
    }
    return selectAllMessage;
  }

  public selectAllCheckboxes() {
    this.isAllSelected = true;
    this.checkSelectedCount = this.totalCount;
    this.paginationProps.paging.fromIndex = 0;
    this.paginationProps.paging.toIndex = this.totalCount;
    this.pageChangeEvent.emit({
      payload: this.paginationProps,
      isAllSelected: true,
    });
  }

  public invokeMethodsAfterApiHit() {
    if (this.selectAdditionalRows) {
      this.selection.clear();
      this.checkAllCheckboxes(this.dataSource.data);
      this.selectAdditionalRows = false;
    }
    this.isAllSelected = false;
    this.updateCheckboxStateForCurrentPage();
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public invokeMethodsAfterAllSelected(dataFromParent: any) {
    this.checkAllCheckboxes(dataFromParent);
    this.updateHeaderCheckboxState();
  }

  public invokeMethodsAfterDeleteOrNewSearch() {
    this.selection.clear();
    this.checkSelectedCount = 0;
    this.indeterminate = false;
    this.globalSelectedIds.clear();
  }

  public updateHeaderCheckboxState() {
    const { globalHeaderCheckboxState, indeterminate } =
      this.checkboxService.updateHeaderCheckboxState(
        this.dataSource,
        this.isChecked.bind(this),
        this.isAllSelected
      );
    this.globalHeaderCheckboxState = globalHeaderCheckboxState;
    this.indeterminate = indeterminate;
  }

  public navigateToSpecificRecord(rowData: any, key?: string, keyData?: any) {
    if (this.isFrom === pageType.fetchFullManData) {
      if (key === 'fullmanParsedDataId') {
        const data = {
          fullmanParsedDataId: rowData.fullmanParsedDataId,
          tableName: this.isFrom,
        };
        this.selectedRow = rowData;
        this.navigateData.emit(data);
      } else if (key === 'fileName') {
        const req =
          '?requestType=FULLMAN&fileStatus=' +
          rowData.status +
          '&fileNames=' +
          rowData.fileName;
        this.downloadFilesAPI(req);
      } else if (key === 'errorInfo') {
        this.openFullmanFileErrorLogPopup(keyData);
      }
    } else if (this.isFrom === pageType.cbpRequestStatus) {
      if (key === 'cbpRequestInfoId') {
        const data = {
          cbpRequestInfoId: rowData.cbpRequestInfoId,
          tableName: this.isFrom,
        };
        this.selectedRow = rowData;
        this.navigateData.emit(data);
      } else if (key === 'requestedEdi') {
        const req =
          '?requestType=CBP_REQUEST&fileStatus=' +
          rowData.status +
          '&fileNames=' +
          rowData.requestedEdi;
        this.downloadFilesAPI(req);
      } else if (key === 'errorInfo') {
        this.openFullmanFileErrorLogPopup(keyData);
      }
    } else if (this.isFrom === pageType.cbpResponseStatus) {
      if (key === 'errorInfo') {
        this.openFullmanFileErrorLogPopup(keyData);
      }
    } else {
      this.selection.clear();
      this.navigateData.emit(rowData);
    }
  }

  public consigneeTooltip(tooltipInfo: any): string {
    if (!tooltipInfo) return '';
    const parts: string[] = [];
    const name = tooltipInfo.name?.trim();
    const companyName = tooltipInfo.companyName?.trim();
    if (name || companyName) {
      parts.push([name, companyName].filter(Boolean).join(', '));
    }
    const address1 = tooltipInfo.address_1?.trim();
    const address2 = tooltipInfo.address_2?.trim();
    if (address1 || address2) {
      parts.push([address1, address2].filter(Boolean).join(', '));
    }
    const location = [
      tooltipInfo.city?.trim(),
      tooltipInfo.state?.trim(),
      tooltipInfo.zipCode?.trim(),
      tooltipInfo.country?.trim(),
    ]
      .filter(Boolean)
      .join(', ');
    if (location) {
      parts.push(location);
    }
    const contact = [tooltipInfo.phone?.trim(), tooltipInfo.email?.trim()]
      .filter(Boolean)
      .join(', ');
    if (contact) {
      parts.push(contact);
    }
    return parts.join('\n').trim();
  }

  public getActionButtons(row: any): string[] {
    if (row.isPlaceholderRow) {
      return [this.buttonNames.create];
    }
    if (this.isFrom === pageType.shipmentGatewayPiece) {
      return [this.buttonNames.proceed, this.buttonNames.createLinkedShipments];
    }
    if (this.isFrom === pageType.shipmentGatewayMasterShipment) {
      return [this.buttonNames.proceed];
    }
    return [];
  }

  public getConditionalTooltipValue(item: any, element: any) {
    if (
      item.label === 'Shipment' ||
      item.label === 'Bag' ||
      item.label === 'MAWB' ||
      item.label === 'Group' ||
      item.label === 'Full Name' ||
      item.label === 'File Name' ||
      this.getNonHyperlinkTooltip(item)
    ) {
      return element[item.key];
    } else if (item.label === 'Actions') {
      const tooltipContent = this.renderTooltipForTable(
        item.label,
        this.isFrom
      );
      return tooltipContent;
    } else {
      return '';
    }
  }

  public getNonHyperlinkTooltip(item: any) {
    return (
      item.label === 'Clearance Channel' ||
      ((item.key === 'user' || item.key === 'facility') &&
        this.isFrom === 'sm-roles-and-permissions')
    );
  }

  public onActionButtonClicked(button: string, rowData: any) {
    if (
      this.isFrom === pageType.shipmentGatewayMasterShipment &&
      button === this.buttonNames.proceed
    ) {
      this.proceedMasterShipmentEvent.emit(rowData);
    } else if (this.isFrom === pageType.shipmentGatewayPiece) {
      this.proceedMasterShipmentEvent.emit({
        selectedButton: button,
        selectedData: rowData,
      });
    }
  }

  public onRadioChange(row: any) {
    this.selectedRowId = row.id;
    this.proceedMasterShipmentEvent.emit(row);
  }

  public canCreate(row: any): boolean {
    // Example logic: Only allow 'Create' if specific conditions are met
    return row.isPlaceholderRow && row.someCondition === true;
  }

  public customiseTableDataDisplay(element: any, elementData: any) {
    if (element === 'date' || element === 'masterShipmentDateOfCreation') {
      return formatDate(elementData, this.dateFormat, 'en-US');
    } else if (element === 'applicableFor') {
      return elementData.map((item: any) => {
        return item.applicableFor;
      });
    } else if (
      element === fullmanAndCbpGridFields.errorInfo ||
      element === fullmanAndCbpGridFields.fullmanParsedDataId ||
      element === fullmanAndCbpGridFields.cbpRequestInfoId ||
      element === fullmanAndCbpGridFields.status
    ) {
      const result = this.handleDataDisplayForFullmanColumns(
        element,
        elementData
      );
      return result;
    } else {
      return elementData;
    }
  }

  public renderTooltipForTable(label: string, isFrom: string): string {
    const mapTooltip = {
      [pageType.fetchFullManData]:
        fullmanFileAndCbpGridActionMessages.viewCbpRequestStatus,
      [pageType.cbpRequestStatus]:
        fullmanFileAndCbpGridActionMessages.viewCbpResponseStatus,
    };
    if (label === 'Actions') {
      return mapTooltip[isFrom] || '';
    } else {
      return '';
    }
  }

  private handleDataDisplayForFullmanColumns(key: string, elementData: string) {
    const mapColumnToFunctions = {
      [fullmanAndCbpGridFields.errorInfo]: () => {
        if (elementData) {
          return Object.keys(elementData).length === 0
            ? ''
            : fullmanFileAndCbpGridActionMessages.viewErrorLog;
        } else {
          return '';
        }
      },
      [fullmanAndCbpGridFields.cbpRequestInfoId]: () => {
        return elementData
          ? fullmanFileAndCbpGridActionMessages.viewCbpResponseStatus
          : '';
      },
      [fullmanAndCbpGridFields.status]: () => {
        if (elementData === fullmanAndCbpStatus.rejected) {
          this.fullmanFileService.setFullmanFileRejectStatus(true);
        } else {
          this.fullmanFileService.setFullmanFileRejectStatus(false);
        }
        return elementData;
      },
      [fullmanAndCbpGridFields.fullmanParsedDataId]: () => {
        if (this.isFullmanFileRejected || !elementData) {
          return '';
        } else {
          return fullmanFileAndCbpGridActionMessages.viewCbpRequestStatus;
        }
      },
    };
    return mapColumnToFunctions[key]();
  }
  public styleFullmanFileStatus(key: any, keyData: string): string | undefined {
    const mapStatusToStyles = {
      [fullmanAndCbpStatus.completed]: 'fullman-file-success-status',
      [fullmanAndCbpStatus.dataPending]: 'fullman-files-pending-status',
      [fullmanAndCbpStatus.processed]: 'fullman-file-success-status',
      [fullmanAndCbpStatus.yetToStart]: 'fullman-file-yet-to-start-status',
      [fullmanAndCbpStatus.inProgress]: 'fullman-files-pending-status',
      [fullmanAndCbpStatus.rejected]: 'fullman-files-rejected-status',
    };
    if (key === 'status' && keyData) {
      return mapStatusToStyles[keyData];
    } else {
      return '';
    }
  }

  private fetchFullManRejectStatus() {
    this.fullmanFileService.isFullManFileRejected.subscribe(data => {
      this.isFullmanFileRejected = data;
    });
  }

  private openFullmanFileErrorLogPopup(data: any) {
    this.modalService.openPopup(
      {
        errorLogData: data,
        isFooterRequired: false,
        isForErrorLog: true,
        isForWarning: false,
      },
      ModalPopupMessages.errorLog,
      MessagePopupComponent,
      popupWidth.searchPopup
    );
  }

  private renderActionsButtonList(): void {
    if (this.isFrom === pageType.shipmentGatewayPiece) {
      this.actionButtons = [this.buttonNames.create];
    } else {
      this.actionButtons = [];
    }
  }

  private prepareTableData(): void {
    if (this.isFrom === pageType.shipmentGatewayPiece) {
      const placeholderRow = {
        shipmentGatewayShipment:
          this.dataSource.data[0]?.shipmentGatewayShipment || '',
        shipmentGatewayConsigneeName: '',
        shipmentGatewayOrigin: '',
        shipmentGatewayFinalDestination: '',
        shipmentGatewayDateOfCreation: '',
        shipmentGatewayWeights: '',
        shipmentGatewayDimensions: '',
        isGatewayed: '',
        isPlaceholderRow: true, // Identify as placeholder row
      };
      this.dataSource.data = [placeholderRow, ...this.dataSource.data];
    }
  }

  private getDataWithSortPagination() {
    this.sort.active = this.activeColumn;
    this.sort.direction = 'desc';
    this.dataSource.sort = this.sort;
    this.cdref.detectChanges();
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.tableSortDirection = data.direction;
    });
  }

  private setRequiredInitialisation() {
    this.tableSortDirection = 'desc';
    this.isAllCheckboxSelected = false;
  }

  private setGridColumnsOptions() {
    if (this.isFrom === pageType.mawb) {
      this.activeColumn = pageType.mawb;
      this.defaultSelectedColumn = InputRowKeys.Mawb;
      this.selectedId = selectedIdForSummaryTable.Mawb;
    } else if (this.isFrom === pageType.agent) {
      this.activeColumn = pageType.agent;
      this.defaultSelectedColumn = InputRowKeys.Agent;
      this.selectedId = selectedIdForSummaryTable.Agent;
    } else if (this.isFrom === pageType.hawb) {
      this.activeColumn = pageType.hawb;
      this.defaultSelectedColumn = InputRowKeys.hawbExt;
      this.selectedId = selectedIdForSummaryTable.Hawb;
    } else if (this.isFrom === pageType.manifest) {
      this.activeColumn = pageType.manifest;
      this.defaultSelectedColumn = InputRowKeys.ManifestNo;
      this.selectedId = selectedIdForSummaryTable.Manifest;
    } else if (this.isFrom === pageType.bag) {
      this.activeColumn = pageType.bag;
      this.defaultSelectedColumn = InputRowKeys.bagExt;
      this.selectedId = selectedIdForSummaryTable.Bag;
    } else if (this.isFrom === pageType.account) {
      this.activeColumn = pageType.account;
      this.defaultSelectedColumn = InputRowKeys.Account;
      this.selectedId = selectedIdForSummaryTable.Account;
    } else if (this.isFrom === pageType.smStatusCodes) {
      this.activeColumn = pageType.smStatusCodes;
      this.defaultSelectedColumn = InputRowKeys.smStatusCodes;
      this.selectedId = selectedIdForSummaryTable.SmStatusCodes;
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      this.activeColumn = pageType.smRolesAndPermissions;
      this.defaultSelectedColumn = InputRowKeys.smRolesAndPermissions;
      this.selectedId = selectedIdForSummaryTable.SmRolesAndPermissions;
    } else if (this.isFrom === pageType.userManagement) {
      this.activeColumn = pageType.userManagement;
      this.defaultSelectedColumn = InputRowKeys.smUserManagement;
      this.selectedId = selectedIdForSummaryTable.SmUserManagement;
    } else if (this.isFrom === pageType.smAirports) {
      this.activeColumn = pageType.smAirports;
      this.defaultSelectedColumn = InputRowKeys.smAirports;
      this.selectedId = selectedIdForSummaryTable.SmAirports;
    } else if (this.isFrom === pageType.smNumberRanges) {
      this.activeColumn = pageType.smNumberRanges;
      this.defaultSelectedColumn = InputRowKeys.smNumberRanges;
      this.selectedId = selectedIdForSummaryTable.SmNumberRanges;
    } else if (this.isFrom === pageType.smAirlines) {
      this.activeColumn = pageType.smAirlines;
      this.defaultSelectedColumn = InputRowKeys.smAirlines;
      this.selectedId = selectedIdForSummaryTable.SmAirlines;
    } else if (this.isFrom === pageType.agentTypes) {
      this.activeColumn = pageType.agentTypes;
      this.defaultSelectedColumn = InputRowKeys.agentType;
      this.selectedId = selectedIdForSummaryTable.smAgentTypes;
    } else if (this.isFrom === pageType.hsCodes) {
      this.activeColumn = pageType.hsCodes;
      this.defaultSelectedColumn = InputRowKeys.hsCodes;
      this.selectedId = selectedIdForSummaryTable.smhsCode;
    } else if (this.isFrom === pageType.facilities) {
      this.activeColumn = pageType.facilities;
      this.defaultSelectedColumn = InputRowKeys.facilities;
      this.selectedId = selectedIdForSummaryTable.smFacilities;
    }
    this.tableService.setDefaultTableColumn(this.defaultSelectedColumn);
  }

  private setDisplayColumnsForGrid() {
    this.displayedColumns = this.tableService.getFromLocalStorage(this.isFrom);
    this.tableService.setToLocalStorage(
      this.tableService.getStorageName(this.isFrom),
      JSON.stringify(this.displayedColumns)
    );
    this.tableService.setTableDisplayedColumns(this.displayedColumns);
  }

  private isListAllResultsClickedData() {
    this.commonService.isListAllResultsData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isListAllResultsClicked = data;
        this.getDefaultSearchMessage();
      });
  }

  private getDefaultSearchMessage() {
    const pageModuleMap = {
      [pageType.mawb]: BulkDeleteItemNames.Mawbs,
      [pageType.hawb]: BulkDeleteItemNames.Shipments,
      [pageType.manifest]: BulkDeleteItemNames.Manifests,
      [pageType.bag]: BulkDeleteItemNames.Bags,
      [pageType.userManagement]: BulkDeleteItemNames.User,
      [pageType.smRolesAndPermissions]: BulkDeleteItemNames.RolesAndPermissions,
      [pageType.smNumberRanges]: BulkDeleteItemNames.NumberRanges,
      [pageType.smStatusCodes]: BulkDeleteItemNames.StatusCodes,
    };

    const moduleName = pageModuleMap[this.isFrom] || '';

    if (this.isFrom === pageType.account) {
      this.noSearchMessage = gridMessages.accountNoSearch;
      this.noMatchMessage = this.isListAllResultsClicked
        ? gridMessages.noRecordsAvailable(BulkDeleteItemNames.Accounts)
        : gridMessages.noRecordsMatch(BulkDeleteItemNames.Accounts);
    } else if (this.isFrom === pageType.userLoginInfo) {
      this.noSearchMessage = gridMessages.noUser;
      this.noMatchMessage = gridMessages.noRecordsMatch(
        BulkDeleteItemNames.Users
      );
    } else if (this.isFrom === pageType.smNumberRanges) {
      this.noSearchMessage = gridMessages.noNumberRangeSearch;
      this.noMatchMessage = gridMessages.noRecordsMatch(
        BulkDeleteItemNames.NumberRanges
      );
    } else if (
      this.isFrom === pageType.cbpRequestStatus ||
      this.isFrom === pageType.cbpResponseStatus
    ) {
      this.noMatchMessage = gridMessages.noCbpRequestSearch;
    } else if (this.isFrom === pageType.bagGateway) {
      this.noMatchMessage = gridMessages.noBagGateway;
      this.noSearchMessage = gridMessages.noBagGateway;
    } else if (this.isFrom === pageType.fetchFullManData) {
      this.noMatchMessage = gridMessages.noFullmanFiles;
      this.noSearchMessage = gridMessages.noFullmanFileSearch;
    } else {
      this.noSearchMessage = `${gridMessages.noSearch} ${moduleName}`;
      this.noMatchMessage = this.isListAllResultsClicked
        ? gridMessages.noRecordsAvailable(moduleName)
        : gridMessages.noRecordsMatch(moduleName);
    }
  }

  private getSelectionDataById(key: string) {
    this.dataSource.data.forEach(row => {
      if (!this.globalSelectedIds.has(row[key])) {
        this.globalSelectedIds.add(row[key]);
        this.selection.select(row);
        this.checkSelectedCount++; // Increment for new selections
      }
    });
  }

  private getDeSelectionDataById(key: string) {
    this.dataSource.data.forEach(row => {
      if (this.globalSelectedIds.has(row[key])) {
        this.globalSelectedIds.delete(row[key]);
        this.selection.deselect(row);
        this.checkSelectedCount--;
      }
      this.selection.deselect(row);
    });
  }

  private getCheckKey(row: any) {
    let checkKey;
    if (this.isFrom === pageType.mawb) {
      checkKey = row.mawbId;
    } else if (this.isFrom === pageType.agent) {
      checkKey = row.agentId;
    } else if (this.isFrom === pageType.hawb) {
      checkKey = row.hawbId;
    } else if (this.isFrom === pageType.manifest) {
      checkKey = row.manifestId;
    } else if (this.isFrom === pageType.bag) {
      checkKey = row.bagId;
    } else if (this.isFrom === pageType.account) {
      checkKey = row.accountNumber;
    } else if (this.isFrom === pageType.smStatusCodes) {
      checkKey = row.statusCode;
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      checkKey = row.id;
    } else if (this.isFrom === pageType.userManagement) {
      checkKey = row.id;
    } else if (this.isFrom === pageType.smAirports) {
      checkKey = row.airportCode;
    } else if (this.isFrom === pageType.smNumberRanges) {
      checkKey = row.id;
    } else if (this.isFrom === pageType.smAirlines) {
      checkKey = row.airlineCode;
    } else if (this.isFrom === pageType.agentTypes) {
      checkKey = row.agentTypeName;
    } else if (this.isFrom === pageType.hsCodes) {
      checkKey = row.hsCode;
    } else if (this.isFrom === pageType.facilities) {
      checkKey = row.facilityCode;
    }
    return checkKey;
  }

  private checkAllCheckboxes(dataArray: any) {
    dataArray.forEach((row: any) => {
      const key = this.getCheckKey(row);
      if (!this.globalSelectedIds.has(key)) {
        this.globalSelectedIds.add(key);
        this.selection.select(row);
      }
    });
  }

  private updateCheckboxStateForCurrentPage() {
    this.checkboxService.updateCheckboxStateForCurrentPage(
      this.dataSource,
      this.selection,
      this.globalSelectedIds,
      this.selectedId
    );
  }

  private setIconHeader() {
    if (this.globalSelectedIds.size > 0) {
      this.tableSelectionEvent.emit(ActionIcons.selected);
    } else {
      this.tableSelectionEvent.emit(ActionIcons.fetchResult);
    }
  }

  private isCheckedCheckAllChange() {
    if (this.isFrom === pageType.mawb) {
      this.getSelectionDataById(selectedIdForSummaryTable.Mawb);
    } else if (this.isFrom === pageType.hawb) {
      this.getSelectionDataById(selectedIdForSummaryTable.Hawb);
    } else if (this.isFrom === pageType.manifest) {
      this.getSelectionDataById(selectedIdForSummaryTable.Manifest);
    } else if (this.isFrom === pageType.bag) {
      this.getSelectionDataById(selectedIdForSummaryTable.Bag);
    } else if (this.isFrom === pageType.userManagement) {
      this.getSelectionDataById(selectedIdForSummaryTable.SmUserManagement);
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      this.getSelectionDataById(
        selectedIdForSummaryTable.SmRolesAndPermissions
      );
    } else if (this.isFrom === pageType.smNumberRanges) {
      this.getSelectionDataById(selectedIdForSummaryTable.SmNumberRanges);
    } else if (this.isFrom === pageType.smStatusCodes) {
      this.getSelectionDataById(selectedIdForSummaryTable.SmStatusCodes);
    }
  }

  private isUncheckedCheckAllChange() {
    if (this.isFrom === pageType.mawb) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Mawb);
    } else if (this.isFrom === pageType.hawb) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Hawb);
    } else if (this.isFrom === pageType.manifest) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Manifest);
    } else if (this.isFrom === pageType.bag) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Bag);
    } else if (this.isFrom === pageType.userManagement) {
      this.getSelectionDataById(selectedIdForSummaryTable.SmUserManagement);
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      this.getSelectionDataById(
        selectedIdForSummaryTable.SmRolesAndPermissions
      );
    } else if (this.isFrom === pageType.smNumberRanges) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.SmNumberRanges);
    } else if (this.isFrom === pageType.smStatusCodes) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.SmStatusCodes);
    }
  }

  public toggleOverlay(menuOverlay?: any) {
    if (this.isOverlayOpen) {
      this.menuOverlayService.closeOverlay();
    } else {
      this.menuOverlayService.openSearchOverlay(menuOverlay, this.isFrom);
    }
    this.isOverlayOpen = !this.isOverlayOpen;
  }

  private downloadFilesAPI(req: any) {
    this.commonService
      .downloadAttachment(req, true)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data.statusCode == StatusCodes.Success) {
          this.commonService.downloadUploadedFile(data?.data[0].signedUrl);
        } else {
          this.errorHandlerService.handleApiError(data);
        }
      });
  }
}
