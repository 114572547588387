<div class="account-address-container">
  <app-mailing-address
    [isFrom]="addressTabs[0]"
    [isSameAsAddress]="isSameAsAdress"
    (accountAddressFormDataValue)="dispatchAddressInfoEvent()"
    [pageMode]="pageMode">
  </app-mailing-address>
  <div class="billing-address-header-container">
    <span class="ibc-heading-h7-bold-primary">{{
      headerText.BillingAddress
    }}</span>
    <mat-checkbox
      class="checkbox-label"
      [(ngModel)]="isSameAsAdress"
      (change)="onCheckboxChange($event.checked)"
      [ngModelOptions]="{ standalone: true }"
      class="ibc-body-text-medium"
      [disabled]="pageMode === viewMode">
      {{ actionMessages.samesAsBillingAddress }}
    </mat-checkbox>
  </div>
  <app-mailing-address
    [isFrom]="addressTabs[1]"
    [isSameAsAddress]="isSameAsAdress"
    (accountAddressFormDataValue)="dispatchAddressInfoEvent()"
    [pageMode]="pageMode">
  </app-mailing-address>
</div>
