<div class="lookup-popup-container">
  <div class="lookup-popup-header">
    <span class="ibc-body-text-large-bold"
      >{{ crudPopupData.title }} Lookup</span
    >
    <div *ngIf="crudPopupData.code?.showModalCloseOption">
      <app-svg-icon
        [iconName]="'close'"
        class="cursor"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (staticIconClicked)="closePopup()"></app-svg-icon>
    </div>
  </div>

  <div class="crud-popup-content">
    <div>
      <mat-form-field appearance="outline" class="mat-md w-100">
        <input
          matInput
          type="text"
          [(ngModel)]="searchTextbox"
          [placeholder]="getPlaceholderText()"
          (input)="filterLookUpList()" />
        <app-svg-icon
          *ngIf="searchTextbox"
          matSuffix
          [iconName]="icons.close"
          class="dropdown-close-icon cursor"
          [isStaticSvg]="true"
          (click)="searchTextbox = ''; filterLookUpList()"
          (keydown)="$event.stopPropagation()">
        </app-svg-icon>
      </mat-form-field>
    </div>

    <div
      class="lookup-table ibc-border-table {{
        lookupTableDataSource.data.length === 0 ? 'no-data' : 'set-height'
      }}">
      <cdk-virtual-scroll-viewport
        itemSize="50"
        class="virtual-scroll-container"
        (scrolledIndexChange)="onScroll()">
        <mat-table
          [dataSource]="lookupTableDataSource"
          matSort
          matSortDisableClear>
          <mat-header-row
            *matHeaderRowDef="lookupTableDisplayedColumns"
            class="ibc-body-text-large-bold"></mat-header-row>

          <ng-container>
            <mat-row
              *matRowDef="
                let row;
                columns: lookupTableDisplayedColumns
              "></mat-row>
          </ng-container>

          <mat-divider></mat-divider>

          <ng-container
            *ngFor="let item of lookupTableColumns; let i = index"
            [matColumnDef]="item.key">
            <mat-header-cell *matHeaderCellDef>
              <span class="column-header cursor">
                <span class="ibc-body-text-large">{{ item.label }}</span>
                <span
                  mat-sort-header
                  *ngIf="lookupTableDataSource.data.length !== 0">
                  <app-svg-icon
                    [iconName]="'sort'"
                    [isStaticSvg]="true"
                    [isFrom]="'Page'"
                    [condition]="tableSortDirection"></app-svg-icon>
                </span>
              </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="ibc-body-text-medium">
                {{ element[item.key] }}
              </span>
            </mat-cell>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef>
              <span class="ibc-body-text-large">Actions</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              <button
                class="{{
                  getMatchedRow(element) ? '' : 'cursor'
                }} ibc-secondary-default-button"
                [disabled]="getMatchedRow(element)"
                (click)="onSelectClicked(element)">
                {{ getMatchedRow(element) ? 'Selected' : 'Select' }}
              </button>
            </mat-cell>
          </ng-container>
        </mat-table>
      </cdk-virtual-scroll-viewport>

      <ng-container *ngIf="lookupTableDataSource.data.length === 0">
        <div class="initial-search-text">
          {{ noResultsMessage }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
