import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MailingAddressComponent } from '../../../../../shared/components/mailing-address/mailing-address.component';
import {
  actionText,
  ModeIcons,
  pageType,
  SingleDeleteHeaderText,
} from 'src/app/shared/utility/constants';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  accountsAddressTab,
  defaultAccountsAddressData,
} from '../../accounts.config';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { CommonService } from 'src/app/shared/services/common.service';
import { SaveAddressDetails } from '../../store/accounts.action';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { IAddressInfoDetailsData } from 'src/app/features/model/sm-accounts';
import { AccountsService } from 'src/app/features/service/accounts.service';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [MailingAddressComponent, SharedModule],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
})
export class AddressComponent implements OnInit, AfterViewInit {
  @ViewChildren(MailingAddressComponent)
  accountsAddressComponents!: QueryList<MailingAddressComponent>;

  public headerText = SingleDeleteHeaderText;
  public actionMessages = actionText;
  public addressTabs = accountsAddressTab;
  public accountsAddressForm!: FormGroup;
  public accountsBillingAddressForm!: FormGroup;
  public accountsAddressComponent: any;
  public accountsBillingAddressComponent: any;
  public isSameAsAdress = false;
  public addressInfoTabData$!: Observable<IAddressInfoDetailsData>;
  public addressInfoTabFormData: any;
  public pageMode!: string;
  public viewMode = ModeIcons.view;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private billingAddressSyncSubscription: Subscription | null = null;

  constructor(
    private readonly store: Store,
    private readonly commonService: CommonService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly accountsService: AccountsService
  ) {
    this.addressInfoTabData$ = this.store.select(
      state => state.accountsAddressInfo
    );
  }

  ngOnInit() {
    this.dataFetch();
    this.checkMode();
    this.setupMailingAddressSync();
  }

  ngAfterViewInit(): void {
    this.accountsAddressComponents.forEach(component => {
      if (component.isFrom === this.addressTabs[0]) {
        this.accountsAddressComponent = component;
        this.accountsAddressForm = component.accountAddressForm;
      } else if (component.isFrom === this.addressTabs[1]) {
        this.accountsBillingAddressComponent = component;
        this.accountsBillingAddressForm = component.accountAddressForm;
      }
    });
    this.patchFormData(); // after html rendered, patch data
    this.checkAddressFormValidData(); // to check form dirty for submit validation
  }

  public onCheckboxChange(checked: boolean) {
    this.isSameAsAdress = checked;
    if (checked) {
      this.syncBillingAddressWithMailingAddress();
      this.setupMailingAddressSync();
    } else {
      this.accountsBillingAddressForm.reset(defaultAccountsAddressData, {
        emitEvent: false,
      });
      this.billingAddressSyncSubscription?.unsubscribe();
    }
    this.dispatchAddressInfoEvent();
    this.commonService.checkPristineAccordionData(false);
  }

  public dispatchAddressInfoEvent() {
    const addressInfoData = {
      address: this.accountsAddressForm.getRawValue(),
      billingAddress: this.accountsBillingAddressForm.getRawValue(),
      isSameAsAdress: this.isSameAsAdress,
    };
    this.store.dispatch(new SaveAddressDetails(addressInfoData));
    this.commonService.checkPristineAccordionData(false);
  }

  private checkAddressFormValidData() {
    this.accountsService.isCheckForAddressInfoData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === true) {
          this.commonService.validateFormFields(this.accountsAddressForm);
          this.commonService.validateFormFields(
            this.accountsBillingAddressForm
          );
          this.changeDetectorRef.detectChanges();
          this.checkAddressInfoValid();
        } else {
          this.accountsAddressForm.markAsPristine();
          this.accountsBillingAddressForm.markAsPristine();
        }
      });
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ModeIcons.view) {
          this.pageMode = ModeIcons.view;
        } else {
          this.pageMode = data != '' ? data : ModeIcons.add;
        }
      });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchAddressInfoTabData(); // get data from store and bind
        }
      });
  }

  private fetchAddressInfoTabData() {
    this.addressInfoTabData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(addressInfoData => {
        this.addressInfoTabFormData = addressInfoData;
        this.generateAddressData(addressInfoData);
        this.patchFormData();
      });
  }

  private generateAddressData(input: any): any {
    const { address, billingAddress } = input;
    const isAddressEmpty =
      !address ||
      Object.keys(address).every(key => !address[key] || key === 'id');
    const isBillingAddressEmpty =
      !billingAddress ||
      Object.keys(billingAddress).every(
        key => !billingAddress[key] || key === 'id'
      );

    if (isAddressEmpty && isBillingAddressEmpty) {
      this.isSameAsAdress = false;
      return;
    }
    const isSameAsAddress = Object.keys(address).every(key =>
      key === 'id' ? true : address[key] === billingAddress[key]
    );
    this.isSameAsAdress = isSameAsAddress;
  }

  private patchFormData() {
    if (
      this.accountsAddressForm &&
      JSON.stringify(this.addressInfoTabFormData.address) !==
        JSON.stringify(this.accountsAddressForm.value)
    ) {
      this.accountsAddressForm.patchValue(this.addressInfoTabFormData.address);
      this.accountsAddressForm.updateValueAndValidity();
      this.executeAfterPatchValues();
    }
    if (
      this.accountsBillingAddressForm &&
      JSON.stringify(this.addressInfoTabFormData.billingAddress) !==
        JSON.stringify(this.accountsBillingAddressForm.value)
    ) {
      this.accountsBillingAddressForm.patchValue(
        this.addressInfoTabFormData.billingAddress
      );
      this.accountsBillingAddressForm.updateValueAndValidity();
      this.executeAfterPatchValues();
    }
  }

  private executeAfterPatchValues() {
    this.commonService.checkPristineAccordionData(
      this.accountsAddressForm.pristine &&
        this.accountsBillingAddressForm.pristine
    );
    this.changeDetectorRef.detectChanges();
    this.checkAddressInfoValid();
  }

  private checkAddressInfoValid() {
    if (
      this.accountsAddressForm.valid &&
      this.accountsBillingAddressForm.valid
    ) {
      this.accountsService.checkAccordionTabsInfoValid(true);
    } else {
      this.accountsService.checkAccordionTabsInfoValid(false);
    }
  }

  private setupMailingAddressSync() {
    this.billingAddressSyncSubscription?.unsubscribe();
    this.billingAddressSyncSubscription = this.accountsAddressForm?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((mailingAddressValues: any) => {
        if (this.isSameAsAdress) {
          this.accountsBillingAddressForm.patchValue(mailingAddressValues, {
            emitEvent: false,
          });
        }
      });
  }

  private syncBillingAddressWithMailingAddress() {
    this.accountsBillingAddressForm.patchValue(this.accountsAddressForm.value, {
      emitEvent: false,
    });
  }
}
