import { Component } from '@angular/core';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { breadcrumbNames } from '../../utility/constants';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [BreadcrumbModule],
  providers: [BreadcrumbService],
  templateUrl: './app-breadcrumb.component.html',
  styleUrl: './app-breadcrumb.component.scss',
})
export class AppBreadcrumbComponent {
  constructor(private readonly breadcrumbService: BreadcrumbService) {}

  ngOnInit(): void {
    this.breadcrumbService.set('@dashboard', 'Dashboard');
    this.breadcrumbService.set('@hawb', 'Home');
    this.breadcrumbService.set('@bag', 'Home');
    this.breadcrumbService.set('@manifest', 'Home');
    this.breadcrumbService.set('@mawb', 'Home');
    this.breadcrumbService.set('@bag-information', 'Bags');
    this.breadcrumbService.set('@hawb-information', 'Shipment');
    this.breadcrumbService.set('@manifest-information', 'Manifest');
    this.breadcrumbService.set('@commodity-information', 'Commodity');
    this.breadcrumbService.set('@mawb-information', 'MAWB');
    this.breadcrumbService.set('@sm-dashboard', 'Home');
    this.breadcrumbService.set('@flight-information', 'Flight');
    this.breadcrumbService.set('@agent-summary', 'Agent');
    this.breadcrumbService.set('@agent-information', 'Agent Information');
    this.breadcrumbService.set(
      '@hawb-advanced-search',
      breadcrumbNames.Shipment
    );
    this.breadcrumbService.set('@bag-advanced-search', breadcrumbNames.Bag);
    this.breadcrumbService.set(
      '@manifest-advanced-search',
      breadcrumbNames.Manifest
    );
    this.breadcrumbService.set('@account-summary', breadcrumbNames.Account);
    this.breadcrumbService.set('@mawb-advanced-search', breadcrumbNames.MAWB);
    this.breadcrumbService.set(
      '@account-information',
      breadcrumbNames.AccountInfo
    );
    this.breadcrumbService.set('@status-codes', breadcrumbNames.StatusCodes);
    this.breadcrumbService.set(
      '@roles-and-permissions',
      breadcrumbNames.RolesAndPermissions
    );
    this.breadcrumbService.set(
      '@fullman-upload',
      breadcrumbNames.fullmanUpload
    );
    this.breadcrumbService.set(
      '@user-management',
      breadcrumbNames.UserManagement
    );
    this.breadcrumbService.set(
      '@user-information',
      breadcrumbNames.UserInformation
    );
    this.breadcrumbService.set('@user-profile', breadcrumbNames.UserProfile);
    this.breadcrumbService.set(
      '@roles-and-permissions-information',
      breadcrumbNames.RolesAndPermissionsInfo
    );
    this.breadcrumbService.set('@airports', breadcrumbNames.Airports);
    this.breadcrumbService.set(
      '@airport-information',
      breadcrumbNames.AirportInfo
    );
    this.breadcrumbService.set('@number-ranges', breadcrumbNames.NumberRanges);
    this.breadcrumbService.set('@airlines', breadcrumbNames.Airlines);
    this.breadcrumbService.set(
      '@airline-information',
      breadcrumbNames.AirlineInfo
    );
    this.breadcrumbService.set('@agent-types', breadcrumbNames.AgentTypes);
    this.breadcrumbService.set('@hs-codes', breadcrumbNames.HsCodes);
    this.breadcrumbService.set(
      '@hs-code-information',
      breadcrumbNames.HsCodeInfo
    );
    this.breadcrumbService.set('@facilities', breadcrumbNames.Facilities);
    this.breadcrumbService.set(
      '@facility-information',
      breadcrumbNames.FacilityInfo
    );
  }
}
