import { ComponentMapping } from '../../../models/piece-information.model';
import { AccordionTabs } from '../../../utility/constants';

export const AdvancedSearchPieceInformationAccordions: ComponentMapping[] = [
  {
    title: AccordionTabs.Notes,
    componentType: '',
  },
  {
    title: AccordionTabs.Events,
    componentType: '',
  },
  {
    title: AccordionTabs.Attachments,
    componentType: '',
  },
  {
    title: AccordionTabs.Details,
    componentType: '',
  },
];

export interface PieceModal {
  agent: string;
  agentShipmentTracker: string;
  all: string;
  barCode: string;
  height: string;
  length: string;
  packaging: string;
  piece: string;
  shipment: string;
  weight: string;
  width: string;
}

export const defaultPieceModal: PieceModal = {
  agent: '',
  agentShipmentTracker: '',
  all: '',
  barCode: '',
  height: '',
  length: '',
  packaging: '',
  piece: '',
  shipment: '',
  weight: '',
  width: ''
};

export interface PieceModalStateModel {
  pieceModal: PieceModal;
}