import { Action, State, StateContext } from '@ngxs/store';
import {
  FetchStatusCodesList,
  ResetStatusCodeList,
} from './status-codes.action';
import { StatusCodesListStateModel } from '../status-codes.model';
import { updateState } from 'src/app/shared/utility/store.utility';
import { Injectable } from '@angular/core';

@Injectable()
@State<StatusCodesListStateModel>({
  name: 'statusCodesList',
  defaults: {
    statusCodes: [],
    totalData: 0,
  },
})
export class StatusCodesListState {
  @Action(ResetStatusCodeList)
  ResetStatusCodesList(ctx: StateContext<StatusCodesListStateModel>) {
    updateState(ctx, {
      statusCodes: [],
      totalData: 0,
    });
  }

  @Action(FetchStatusCodesList)
  FetchStatusCodesList(
    ctx: StateContext<StatusCodesListStateModel>,
    action: FetchStatusCodesList
  ) {
    const payload = action.payload;
    if (payload.data?.statusCodes && payload.data?.totalRecords) {
      updateState(ctx, {
        statusCodes: payload.data.statusCodes,
        totalData: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        statusCodes: [],
        totalData: 0,
      });
    }
  }
}
