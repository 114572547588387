import { ComponentMapping } from '../../../models/piece-information.model';
import { AccordionTabs } from '../../../utility/constants';
import { FormConfig } from 'src/app/shared/models/dynamic.model';

export const CommodityAdvancedSearchTabs: ComponentMapping[] = [
  { title: AccordionTabs.Roles, componentType: '' },
  { title: AccordionTabs.Miscellaneous, componentType: '' },
  { title: AccordionTabs.Notes, componentType: '' },
  { title: AccordionTabs.Attachments, componentType: '' },
  { title: AccordionTabs.Details, componentType: '' },
];

export const commodityAdvancedSearchFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'hscode',
      type: 'input',
      label: 'HS Code',
      maxValue: 126,
    },
    {
      id: 'value',
      type: 'input',
      label: 'Value',
      maxValue: 126,
    },
    {
      id: 'description',
      type: 'input',
      label: 'Description',
      maxValue: 126,
    },
    {
      id: 'manufacturerCountryCode',
      type: 'input',
      label: 'Manufacture Country Code',
      maxValue: 126,
    },
  ],
};

export interface CommodityModal {
  hscode: string;
  value: string;
  description: string;
  manufactureCountryCode: string;
}

export const defaultCommodityModal: CommodityModal = {
  hscode: '',
  value: '',
  description: '',
  manufactureCountryCode: '',
};

export interface CommodityModalStateModel {
  commodityModal: CommodityModal;
}

// Initial state
export const defaultCommodityState: CommodityModalStateModel = {
  commodityModal: defaultCommodityModal,
};
