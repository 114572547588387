import { FormConfig } from '../../models/dynamic.model';
import { ValidatorPattern } from '../../utility/validators.pattern';

export const mailingAddressFormConfig: FormConfig = {
  fields: [
    {
      id: 'countryCode',
      type: 'select',
      label: 'Country',
      required: true,
      requiredError: 'Country is required',
    },
    {
      id: 'phone',
      type: 'input',
      label: 'Phone',
      required: false,
      noInitialWhiteSpace: true,
      requiredError: 'Invalid Phone Number',
      minError: 'Invalid Phone Number',
      patternError: 'Invalid Phone Number',
    },
    {
      id: 'email',
      type: 'input',
      label: 'Email',
      pattern: ValidatorPattern.Email_Validator,
      required: false,
      noInitialWhiteSpace: true,
      maxValue: 50,
      requiredError: 'Valid Email is required',
      emailPatternError: 'Invalid Email',
    },
    {
      id: 'name',
      type: 'input',
      label: 'Name',
      required: true,
      requiredError: 'Name is required',
      noInitialWhiteSpace: true,
      minValue: 1,
      maxValue: 126,
      allowAlphabetWithSpace: true,
    },
    {
      id: 'companyName',
      type: 'input',
      label: 'Company Name',
      required: true,
      requiredError: 'Company Name is required',
      noInitialWhiteSpace: true,
      minValue: 1,
      maxValue: 126,
      allowAlphabetWithSpace: true,
    },
    {
      id: 'address_1',
      type: 'input',
      label: 'Address Line 1',
      required: true,
      requiredError: 'Address Line 1 is required',
      noInitialWhiteSpace: true,
      maxValue: 126,
      isCheckForMaxValue: true,
    },
    {
      id: 'address_2',
      type: 'input',
      label: 'Address Line 2',
      required: false,
      noInitialWhiteSpace: true,
      maxValue: 126,
      isCheckForMaxValue: true,
    },
    {
      id: 'city',
      type: 'input',
      label: 'City',
      required: true,
      noInitialWhiteSpace: true,
      allowAlphabet: true,
      isCheckForMaxValue: true,
      requiredError: 'City is required',
      minValue: 2,
      maxValue: 50,
      minError: 'Invalid City entered',
    },
    {
      id: 'state',
      type: 'input',
      label: 'State/Province/Region',
      required: false,
      noInitialWhiteSpace: true,
      requiredError: 'State/Province/Region is required',
    },
    {
      id: 'zipCode',
      type: 'input',
      label: 'Postal Code',
      required: false,
      isCheckForMaxValue: true,
      noInitialWhiteSpace: true,
      minValue: 4,
      maxValue: 10,
      minError: 'Postal code must be between 4 and 10 characters',
    },
    {
      id: 'contactPerson',
      type: 'input',
      label: 'Contact Person',
      required: false,
      noInitialWhiteSpace: true,
      minValue: 1,
      maxValue: 126,
      allowAlphabetWithSpace: true,
    },
  ],
};
