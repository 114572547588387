<div class="print-popup-wrapper">
  <div class="print-popup-header">
    <div class="ibc-body-text-large-bold">{{ printPopupData.title }}</div>
    <div class="card-action-icons">
      <app-svg-icon
        [iconName]="'close'"
        class="cursor"
        [isStaticSvg]="true"
        [isFrom]="'Popup'"
        (staticIconClicked)="closePrintPopup()"></app-svg-icon>
    </div>
  </div>

  <div class="print-popup-content">
    <div class="papersize-preview">
      <mat-form-field appearance="outline" class="mat-md mat-select-variants">
        <mat-label>Paper Size</mat-label>
        <mat-select [formControl]="paperSizeFormControl">
          @for (paperSize of paperSizeList; track paperSizeList) {
            <mat-option [value]="paperSize.value">{{
              paperSize.viewValue
            }}</mat-option>
          }
        </mat-select>
        <mat-icon matPrefix class="dropdown-icon">{{
          actionIcons.keyboardDownArrow
        }}</mat-icon>
      </mat-form-field>
    </div>

    <div class="scrollable-container">
      <div class="ibc-body-text-large-bold confirmation-message">
        {{ popupMessages.printColumnSelection }}
      </div>

      <div
        class="print-config-options-container"
        *ngIf="printPopupData.code?.isFromSummary">
        <ng-container *ngFor="let list of summaryTableColumns; let i = index">
          <span>
            <mat-checkbox
              [checked]="list.isSelected"
              (click)="handleCheckboxSelection(i, list.isSelected)"
              (keydown)="$event.stopPropagation()">
            </mat-checkbox>
            <span class="ibc-body-text-regular">{{ list.label }}</span>
          </span>
        </ng-container>
      </div>

      <ng-container *ngIf="!printPopupData.code?.isFromSummary">
        <ng-container *ngFor="let objKey of objectKeys(summaryTableColumns)">
          <div class="section-layout">
            <span>
              <mat-checkbox
                [checked]="selectAllColumnsInSection(objKey)"
                (change)="onSectionCheckboxClicked($event.checked, objKey)"
                (keydown)="$event.stopPropagation()">
              </mat-checkbox>
              <span class="ibc-heading-h7-bold-subtitle">
                {{ printService.getHeaderSectionKeys(objKey) }}
              </span>
            </span>

            <div class="columns-layout">
              <ng-container
                *ngFor="
                  let item of summaryTableColumns[objKey].config;
                  let itemIndex = index
                ">
                <span>
                  <mat-checkbox
                    [checked]="item.isSelected"
                    (click)="
                      handleInfoPageCheckboxSelection(
                        objKey,
                        itemIndex,
                        item.isSelected
                      )
                    "
                    (keydown)="$event.stopPropagation()">
                  </mat-checkbox>
                  <span class="ibc-body-text-regular">{{ item.label }}</span>
                </span>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="print-popup-footer">
    <button
      class="cursor ibc-medium-button ibc-secondary-default-button"
      (click)="closePrintPopup()">
      <span class="ibc-body-text-regular">{{ popupButtons.Cancel }}</span>
    </button>
    <button
      class="ibc-medium-button ibc-secondary-accent-button-md"
      [ngClass]="!this.paperSizeFormControl.value ? '' : 'cursor'"
      (click)="calculateTableWidthForPrint(false)"
      [disabled]="!this.paperSizeFormControl.value">
      <span class="ibc-body-text-regular">
        {{ popupButtons.Preview }}
      </span>
    </button>
    <button
      class="ibc-medium-button ibc-primary-default-button cursor"
      (click)="calculateTableWidthForPrint(true)">
      <span class="ibc-body-text-regular">
        {{ popupButtons.Print }}
      </span>
    </button>
  </div>
</div>
