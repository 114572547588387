import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import { URLConstants } from 'src/app/shared/utility/url-constants';
import { IBagInformationData } from '../model/bag.model';
import { HttpSignatures } from 'src/app/shared/utility/constants';

@Injectable({
  providedIn: 'root',
})
export class BagService {
  public selectedIdsForBulkEdit = new BehaviorSubject<any>([]);
  public selectedPiecesId = new BehaviorSubject<any>([]);
  public clickedIcon = new BehaviorSubject<string>('');
  public deletedPieceData = new BehaviorSubject<any>([]);
  public bagPieceTableData = new BehaviorSubject<any>([]);
  public isBagPrevSummary = new BehaviorSubject<any>('');
  public isBagMiscellaneousValidData = new BehaviorSubject<boolean>(true);
  mockFlag = true;

  constructor(
    public baseService: BaseService,
    private readonly http: HttpClient
  ) {}

  public setBulkEditIds(data: any) {
    this.selectedIdsForBulkEdit.next(data);
  }

  public setSelectedPiecesId(data: any[]) {
    this.selectedPiecesId.next(data);
  }

  public setDeletedPiecesId(data: string[]) {
    this.deletedPieceData.next(data);
  }

  public setPieceTableData(data: any) {
    this.bagPieceTableData.next(data);
  }

  public checkBagExtExistence(bagExt: string): Observable<any> {
    const url = URLConstants.checkBagExt + bagExt;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public getPieceDataByExt(
    requestType: string,
    data: any,
    originId: number,
    destinationId: number
  ): Observable<any> {
    const url = `${URLConstants.getShipmentData}/${requestType}/${data}/${originId}/${destinationId}`;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public getBulkEditData(value: number[]): Observable<any> {
    const url = URLConstants.getBagList;
    return this.baseService.initiateApiRequest('POST', url, '', 'json');
  }

  public saveBagData(data: IBagInformationData): Observable<any> {
    const url = URLConstants.saveBagData;
    return this.baseService.initiateApiRequest('PUT', url, data, 'json');
  }

  public updateBag(data: IBagInformationData): Observable<any> {
    const url = URLConstants.updateBagData;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public getSearchData(event: any): Observable<any> {
    const apiUrl = URLConstants.getBagBasicList;
    return this.baseService.initiateApiRequest('POST', apiUrl, event, 'json');
  }

  public getSelectedBagData(event: any): Observable<any> {
    const url = 'http://localhost:4200/assets/mock/bagList.json';
    const data$: Observable<any> = this.http.get(url);
    return data$.pipe(
      map(items => {
        const filteredItems = items.data.bag.filter(
          (item: any) =>
            event.bagId &&
            (item.bagId.includes(event.bagId) || item.bagId === event.bagId)
        );
        return { ...items, data: { ...items.data, bag: filteredItems } };
      })
    );
  }

  public deleteBag(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.deleteBag,
      event,
      '',
      'json'
    );
  }

  public deleteBagShipment(event: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      HttpSignatures['Post'],
      URLConstants.deleteBagShipment,
      event,
      '',
      'json'
    );
  }

  public updateClickedIcon(icon: string) {
    this.clickedIcon.next(icon);
  }

  public isBagSummaryPrevSearch(data: string) {
    this.isBagPrevSummary.next(data);
  }

  public isBagMiscellaneousValid(data: boolean) {
    this.isBagMiscellaneousValidData.next(data);
  }

  public getBagBulkEditUniqueData(bagIds: string): Observable<any> {
    const url = URLConstants.uniqueBagDataBulkEdit + bagIds;
    return this.baseService.initiateApiRequest('GET', url, '', 'json');
  }

  public updateBulkBag(data: any): Observable<any> {
    const url = URLConstants.updateBulkBag;
    return this.baseService.initiateApiRequest('POST', url, data, 'json');
  }

  public advanceBag(data: any): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.advanceBagInformation,
      data,
      'json'
    );
  }
}
