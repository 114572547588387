import {
  FormConfig,
  IBasicSearchPaginationProps,
  Widget,
} from 'src/app/shared/models/dynamic.model';
import {
  ComponentMapping,
  IManifestDetailsData,
  IManifestInformationData,
  IManifestRequiredBagData,
  ManifestBagGridColumnData,
  TableColumns,
  TimelineDataItem,
} from '../../model/manifest.model';
import {
  AccordionTabs,
  searchDataParam,
} from 'src/app/shared/utility/constants';

export const widgets: Widget[] = [
  {
    id: 1,
    title: 'Number of Bags',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'bagNumbers',
    name: 'noOfBags',
    controlName: 'noOfBags',
  },
  {
    id: 2,
    title: 'Number of Shipments',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'hawbNumbers',
    name: 'noOfHawbs',
    controlName: 'noOfShipment',
  },
  {
    id: 3,
    title: 'Total Weight',
    value: '--',
    extraClasses: 'pt-6',
    iconName: 'weight',
    name: 'totalWeight',
    controlName: 'totalWeight',
  },
];

/* Manifest Information Form Fields Configuration */
export const manifestInfoFormFieldsConfig: FormConfig = {
  fields: [
    {
      id: 'manifestDate',
      type: 'input',
      label: 'Manifest Date',
      required: true,
      requiredError: 'Manifest Date should be in MM-DD-YYYY',
    },
    {
      id: 'originName',
      type: 'input',
      label: 'Origin',
      required: true,
      requiredError: 'Origin is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'origin',
      type: 'input',
      label: 'Origin',
    },
    {
      id: 'destinationName',
      type: 'input',
      label: 'Destination',
      required: true,
      requiredError: 'Destination is required',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destination',
      type: 'input',
      label: 'Destination',
    },
    {
      id: 'agentName',
      type: 'input',
      label: 'Agent',
      minValue: 1,
      maxValue: 126,
      isCheckForMaxValue: true,
    },
    {
      id: 'agentId',
      type: 'input',
      label: 'Agent',
    },
    {
      id: 'mawbExt',
      type: 'input',
      label: 'Mawb',
      minValue: 11,
      maxValue: 11,
      minError: '11 numbers is required',
      allowNumeric: true,
    },
    {
      id: 'mawbId',
      type: 'input',
      label: 'Mawb Id',
    },
    {
      id: 'airlineName',
      type: 'input',
      label: 'Airline',
      isDisableField: true,
    },
    {
      id: 'airlineId',
      type: 'input',
      label: 'Airline',
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight Number',
      requiredError: 'Flight Number is required',
      isDisableField: true,
    },
    {
      id: 'flightAirlineName',
      type: 'input',
      label: 'Flight Airline Name',
    },
    {
      id: 'flightAirlineId',
      type: 'input',
      label: 'Flight Data',
    },
    {
      id: 'manifestId',
      type: 'input',
      label: 'Manifest',
      isDisableField: true,
    },
    {
      id: 'information',
      type: 'input',
      label: 'Information',
    },
  ],
};

/* Manifest Timeline Configuration */
export const timelineData: TimelineDataItem[] = [
  {
    date: '3/21/2024',
    time: '11:11:11',
    event: 'Manifest updated',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Notes updated',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Notes added',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Manifest created',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Bag details edited',
  },
  {
    date: '12/12/2008',
    time: '10:10:10',
    event: 'Bag attachment added',
  },
];

/* Component Mappings Configuration */
export const componentMappings: ComponentMapping[] = [
  { title: AccordionTabs.StatusCodes, componentType: '' },
  { title: 'Notes', componentType: '' },
  { title: 'Events', componentType: '' },
  { title: 'Attachments', componentType: '' },
  { title: 'Details', componentType: '' },
];

/* Piece Grid Input Row Data Configuration */
export const bagsGridInputRowData: ManifestBagGridColumnData[] = [
  {
    bagId: '',
    bagExt: '',
    weight: '',
    packagingType: '',
    pieces: 0,
    isInputRow: true,
  },
];

/* Bag Table Grid Columns Configuration */
export const bagTableGridColumns = [
  { key: 'bagExt', label: 'Bag', isSelected: true, defaultPrintWidth: 150 },
  {
    key: 'weight',
    label: 'Weight (lb)',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'packagingType',
    label: 'Packaging',
    isSelected: true,
    defaultPrintWidth: 130,
  },
  { key: 'pieces', label: 'Pieces', isSelected: true, defaultPrintWidth: 120 },
];

export const defaultManifestBagData = (): IManifestRequiredBagData[] => [
  {
    bagExt: '',
    weight: '',
    pieces: 0,
    packagingType: '',
    action: '',
  },
];

export const defaultManifestInfoData = (): IManifestDetailsData => ({
  manifestDate: '',
  originName: '',
  origin: 0,
  destinationName: '',
  destination: 0,
  agentName: '',
  agentId: 0,
  mawbExt: 0,
  airline: '',
  flightNumber: '',
  manifestId: 0,
  information: '',
});

export const defaultManifestInformationData = (): IManifestInformationData => ({
  manifestDate: '',
  origin: 0,
  destination: 0,
  mawbExt: 0,
  airlineName: '',
  information: '',
  flightNumber: '',
  manifestId: 0,
  details: {
    mawbId: 0,
  },
  notes: {
    ibc: [],
    customer: [],
  },
  bags: defaultManifestBagData(),
});

export const manifestDetailRequiredKeys = [
  'manifestDate',
  'origin',
  'destination',
  'mawbExt',
  'airlineName',
  'information',
  'manifestId',
  'agentId',
  'flights',
  'agentName',
];

// manifest summary
export const searchFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'manifestDate',
      type: 'input',
      label: 'Manifest Date',
      minValue: 1,
      maxValue: 11,
      allowNumeric: true,
    },
    {
      id: 'manifestNo',
      type: 'input',
      label: 'Manifest Number',
      minValue: 1,
      maxValue: 14,
      allowNumeric: true,
    },
    {
      id: 'originId',
      type: 'input',
      label: 'Origin',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'destinationId',
      type: 'input',
      label: 'Destination',
      minValue: 1,
      maxValue: 126,
      noInitialWhiteSpace: true,
      isCheckForMaxValue: true,
    },
    {
      id: 'agentName',
      type: 'input',
      label: 'Agent Name',
    },
    {
      id: 'agentCode',
      type: 'input',
      label: 'Agent Number',
    },
    {
      id: 'airlineCode',
      type: 'input',
      label: 'Airline Number',
    },
    {
      id: 'mawbExt',
      type: 'input',
      label: 'MAWB',
      allowNumeric: true,
      minValue: 1,
      maxValue: 14,
    },
    {
      id: 'airlineName',
      type: 'input',
      label: 'Airline',
    },
    {
      id: 'flightNumber',
      type: 'input',
      label: 'Flight',
      minValue: 1,
      maxValue: 6,
      allowAlphaNumeric: true,
    },
  ],
};

export const gridSummaryColumns: TableColumns[] = [
  {
    isSelected: true,
    defaultView: true,
    isEditable: false,
    key: 'date',
    label: 'Date',
    value: 'date',
    defaultPrintWidth: 150,
  },
  {
    key: 'manifestNo',
    label: 'Manifest Number',
    value: 'manifestNo',
    isSelected: true,
    defaultView: true,
    isEditable: false,
    defaultPrintWidth: 130,
  },
  {
    value: 'origin',
    isSelected: true,
    defaultView: true,
    key: 'origin',
    label: 'Origin',
    isEditable: false,
    defaultPrintWidth: 100,
  },
  {
    defaultView: true,
    isEditable: false,
    key: 'destination',
    label: 'Destination',
    value: 'destination',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    value: 'noOfBags',
    isEditable: false,
    isSelected: true,
    defaultView: true,
    key: 'noOfBags',
    label: 'Bags',
    defaultPrintWidth: 100,
  },
  {
    isSelected: true,
    defaultView: true,
    isEditable: false,
    key: 'isPublished',
    label: 'Published',
    value: 'isPublished',
    defaultPrintWidth: 100,
  },
  {
    isEditable: false,
    value: 'agentCode',
    isSelected: true,
    key: 'agentCode',
    label: 'Agent Code',
    defaultView: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'mawbExt',
    label: 'MAWB',
    isSelected: true,
    value: 'mawbExt',
    defaultView: true,
    isEditable: false,
    defaultPrintWidth: 150,
  },
  {
    key: 'airlineCode',
    label: 'Airline Code',
    value: 'airlineCode',
    isSelected: true,
    defaultView: true,
    isEditable: false,
    defaultPrintWidth: 130,
  },
  {
    isSelected: false,
    defaultView: false,
    isEditable: false,
    key: 'agentName',
    label: 'Agent Name',
    value: 'agentName',
    defaultPrintWidth: 120,
  },
];

export const defaultGridDisplayColumns = [
  'select',
  'date',
  'manifestNo',
  'origin',
  'destination',
  'noOfBags',
  'isPublished',
  'agentCode',
  'mawbExt',
  'airlineCode',
  'agentName',
  'action',
];

export const ManifestExcelHeaders: string[] = [
  'Date',
  'Manifest Number',
  'Origin',
  'Destination',
  'Bags',
  'Agent Code',
  'MAWB',
  'Airline Code',
  'Agent Name',
];

export const defautPagination = {
  fromIndex: 0,
  toIndex: 10,
  sortingOrder: searchDataParam.desc,
  sortingField: searchDataParam.manifestId,
};

export const reduceWidthForFields = {
  manifestFlightNumber: 16,
  manifestFlightNumberHelp: 12,
};

export const fieldControlNames = {
  manifestFlightNumber: 'manifest-flightNumber',
  manifestFlightNumberHelp: 'manifest-flightNumber-help',
};

export const ManifestSearchConfig: IBasicSearchPaginationProps =
  defautPagination;

export const ManifestInfoSectionHeaders: string[] = [
  'Widgets',
  'Manifest Information',
  'Notes',
  'Events',
  'Attachments',
  'Details',
  'Bag(s) linked',
];

export const ManifestInfoSectionHeaderKeys: string[] = [
  'widgets',
  'manifestInfo',
  'notes',
  'events',
  'attachments',
  'details',
  'manifestsBags',
];

export const ManifestInfoHeaders = [
  {
    key: 'manifestDate',
    label: 'Manifest Date',
    isSelected: true,
    defaultPrintWidth: 130,
  },
  {
    key: 'originName',
    label: 'Origin',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'destinationName',
    label: 'Destination',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'agentName',
    label: 'Agent',
    isSelected: true,
    defaultPrintWidth: 130,
  },
  {
    key: 'mawbExt',
    label: 'MAWB',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'airlineName',
    label: 'MAWB Airline',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'flightNumber',
    label: 'Flight',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'flightAirlineName',
    label: 'Flight Airline',
    isSelected: true,
    defaultPrintWidth: 120,
  },
  {
    key: 'manifestId',
    label: 'Manifest Number',
    isSelected: true,
    defaultPrintWidth: 130,
  },
];

export const ManifestDetailsHeaders = [
  {
    key: 'mawbId',
    label: 'MAWB ID',
    isSelected: true,
    defaultPrintWidth: 150,
  },
];

export const manifestWidgetTableGridColumns = [
  {
    key: 'noOfBags',
    label: 'Number of Bags',
    isSelected: true,
    defaultPrintWidth: 150,
  },
  {
    key: 'noOfHawbs',
    label: 'Number of Shipments',
    isSelected: true,
    defaultPrintWidth: 180,
  },
  {
    key: 'totalWeight',
    label: 'Total Weight',
    isSelected: true,
    defaultPrintWidth: 150,
  },
];
