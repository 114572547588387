import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import {
  defaultHawbInformation,
  hawbInformation,
  Miscellaneous,
  PieceList,
  CommodityList,
  CommodityMiscellaneous,
  EventList,
  hawbColumnData,
  Origin,
  ConsigneeShippingModel,
  ConsigneeShipperStateModel,
  defaultConsigneeShipping,
  IRolesData,
} from 'src/app/features/model/hawb.model';
import {
  AddCommodity,
  AddDispCode,
  AddEvents,
  AddMiscellaneousCommodity,
  AddPiece,
  AddPodCode,
  DeleteCommodity,
  DeletePiece,
  RemoveUnsavedMiscellaneousCommodities,
  ResetCommodity,
  ResetDispCodes,
  ResetEvents,
  ResetMiscellaneousCommodity,
  ResetPODCodes,
  ResetPiece,
  UpdateHawbInformation,
  UpdateMiscellaneous,
  FetchHawbsList,
  ResetHawbsList,
  FetchSelectedHawbById,
  FetchHawbsInformationByHawbExt,
  ResetHawbInfo,
  DeletePodCode,
  DeleteStatusCode,
  FetchHawbsInformationListSuccess,
  AddRoles,
  ResetRoles,
  DeleteRoles,
} from './hawb.action';

// Roles
@State<IRolesData>({
  name: 'roles',
  defaults: {
    roles: [],
  },
})
@Injectable()
export class RolesState {
  @Action(AddRoles)
  updateRoles(ctx: StateContext<IRolesData>, { payload }: AddRoles) {
    const state = ctx.getState();
    // Check if the role already exists to avoid duplication
    const existingRoleIndex = state.roles.findIndex(
      role => role.id === payload.id
    );
    if (existingRoleIndex > -1) {
      // If the role already exists, update it
      const updatedRoles = [...state.roles];
      updatedRoles[existingRoleIndex] = { ...payload };
      ctx.setState({
        ...state,
        roles: updatedRoles,
      });
    } else {
      // If it's a new role, add it to the roles array
      ctx.setState({
        ...state,
        roles: [...state.roles, payload],
      });
    }
  }

  @Action(ResetRoles)
  resetRoles(ctx: StateContext<IRolesData>) {
    ctx.setState({
      roles: [],
    });
  }

  @Action(DeleteRoles)
  deleteRoles(ctx: StateContext<IRolesData>, { payload }: DeleteRoles) {
    const state = ctx.getState();
    const updatedRoles = state.roles.filter(role => role.id !== payload.id);

    ctx.setState({
      ...state,
      roles: updatedRoles,
    });
  }
}

// Disp Codes
export class DispCodesStateModel {
  hawbInformation!: hawbInformation;
}

@State<DispCodesStateModel>({
  name: 'dispCodes',
  defaults: {
    hawbInformation: defaultHawbInformation,
  },
})
@Injectable()
export class DispCodesState {
  @Action(AddDispCode)
  addDispCode(
    ctx: StateContext<DispCodesStateModel>,
    { payload }: AddDispCode
  ) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        statusCodeList: [...state.hawbInformation.statusCodeList, payload],
      },
    };
    ctx.setState(newState);
  }

  @Action(DeleteStatusCode)
  deleteDispCode(ctx: StateContext<HawbStateModel>, { id }: DeleteStatusCode) {
    const state = ctx.getState();
    const updatedStatus = state.hawbInformation.statusCodeList.filter(
      code => code.id !== id
    );
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        statusCodeList: updatedStatus,
      },
    };
    ctx.setState(newState);
  }

  @Action(ResetDispCodes)
  resetDispCodes(ctx: StateContext<DispCodesStateModel>) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        statusCodeList: [],
      },
    };
    ctx.setState(newState);
  }
}

// POD
export class HawbStateModel {
  hawbInformation!: hawbInformation;
}

@State<HawbStateModel>({
  name: 'podCodes',
  defaults: {
    hawbInformation: defaultHawbInformation,
  },
})
@Injectable()
export class PODState {
  @Action(AddPodCode)
  addPodCode(ctx: StateContext<HawbStateModel>, { payload }: AddPodCode) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        pod: [...state.hawbInformation.pod, payload],
      },
    };
    ctx.setState(newState);
  }

  @Action(DeletePodCode)
  deletePodCode(ctx: StateContext<HawbStateModel>, { id }: DeletePodCode) {
    const state = ctx.getState();
    const updatedPods = state.hawbInformation.pod.filter(pod => pod.id !== id);
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        pod: updatedPods,
      },
    };
    ctx.setState(newState);
  }

  @Action(ResetPODCodes)
  resetpodCodes(ctx: StateContext<HawbStateModel>) {
    const state = ctx.getState();
    const newState = {
      ...state,
      hawbInformation: {
        ...state.hawbInformation,
        pod: [],
      },
    };
    ctx.setState(newState);
  }
}

// Miscellaneous Section
export interface MiscellaneousStateModel {
  miscellaneous: Miscellaneous;
}

@State<MiscellaneousStateModel>({
  name: 'miscellaneous',
  defaults: {
    miscellaneous: {
      isResidential: false,
      signatureRequired: false,
    },
  },
})
@Injectable()
export class MiscellaneousState {
  @Action(UpdateMiscellaneous)
  updateMiscellaneous(
    ctx: StateContext<MiscellaneousStateModel>,
    action: UpdateMiscellaneous
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      miscellaneous: action.payload,
    });
  }
}

// Hawb Crud
export interface HawbInformationStateModel {
  hawbInformation: hawbInformation;
  hawbsInformationList: hawbInformation[];
}
@State<HawbInformationStateModel>({
  name: 'hawbInformation',
  defaults: {
    hawbInformation: defaultHawbInformation,
    hawbsInformationList: [],
  },
})
@Injectable()
export class HawbInformationState {
  @Action(UpdateHawbInformation)
  updateHawbInformation(
    ctx: StateContext<HawbInformationStateModel>,
    action: UpdateHawbInformation
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbInformation: action.payload,
    });
  }

  @Action(FetchHawbsInformationListSuccess)
  fetchHawbsInformationListSuccess(
    { getState, setState }: StateContext<HawbInformationStateModel>,
    { hawbs }: FetchHawbsInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      hawbsInformationList: hawbs,
    });
  }

  @Action(FetchSelectedHawbById)
  FetchSelectedHawbById(
    ctx: StateContext<HawbInformationStateModel>,
    action: FetchSelectedHawbById
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbInformation: state.hawbsInformationList.filter(
        element => element.hawbId == action.hawbId
      )[0],
    });
  }

  @Action(FetchHawbsInformationByHawbExt)
  FetchHawbsInformationListById(
    ctx: StateContext<HawbInformationStateModel>,
    action: any
  ) {
    const state = ctx.getState();
    const data = action.payload;
    ctx.setState({
      ...state,
      hawbInformation: data.hawbInformation,
      hawbsInformationList: data.hawbsInformationList,
    });
  }

  @Action(ResetHawbInfo)
  resetHawbInfo(
    ctx: StateContext<HawbInformationStateModel>,
    action: ResetHawbInfo
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbInformation: defaultHawbInformation,
      hawbsInformationList: [],
    });
  }
}

// Piece table data
export interface PieceStateModel {
  pieces: PieceList[];
}

@State<PieceStateModel>({
  name: 'piece',
  defaults: {
    pieces: [],
  },
})
@Injectable()
export class PieceState {
  @Action(AddPiece)
  addPiece(ctx: StateContext<PieceStateModel>, { payload }: AddPiece) {
    const state = ctx.getState();
    // Check if the piece already exists to avoid duplication
    const existingPieceIndex = state.pieces.findIndex(
      piece => piece.pieceNumber === payload.pieceNumber
    );
    if (existingPieceIndex > -1) {
      // If the piece already exists, update it
      const updatedPieces = [...state.pieces];
      updatedPieces[existingPieceIndex] = { ...payload };
      ctx.setState({
        ...state,
        pieces: updatedPieces,
      });
    } else {
      // If it's a new piece, add it to the pieces array
      ctx.setState({
        ...state,
        pieces: [...state.pieces, payload],
      });
    }
  }

  @Action(DeletePiece)
  deletePiece(ctx: StateContext<PieceStateModel>, { payload }: DeletePiece) {
    const state = ctx.getState();
    const filteredPieces = state.pieces.filter(
      piece => piece.pieceNumber !== payload
    );
    ctx.setState({
      ...state,
      pieces: filteredPieces,
    });
  }

  @Action(ResetPiece)
  resetPiece({ setState }: StateContext<PieceStateModel>) {
    setState({
      pieces: [],
    });
  }
}

// Commodity Table
export interface CommodityStateModel {
  commodities: CommodityList[];
}

@State<CommodityStateModel>({
  name: 'commodities',
  defaults: {
    commodities: [],
  },
})
@Injectable()
export class CommodityState {
  @Action(AddCommodity)
  addCommodity(
    ctx: StateContext<CommodityStateModel>,
    { payload }: AddCommodity
  ) {
    const state = ctx.getState();
    // Check if the commodity already exists to avoid duplication
    const existingCommodityIndex = state.commodities.findIndex(
      commodity => commodity.commodityNumber === payload.commodityNumber
    );
    if (existingCommodityIndex > -1) {
      // If the commodity already exists, update it
      const updatedCommodities = [...state.commodities];
      updatedCommodities[existingCommodityIndex] = { ...payload };
      ctx.setState({
        ...state,
        commodities: updatedCommodities,
      });
    } else {
      // If it's a new commodity, add it to the commodities array
      ctx.setState({
        ...state,
        commodities: [...state.commodities, payload],
      });
    }
  }

  @Action(ResetCommodity)
  resetCommodity({ setState }: StateContext<CommodityStateModel>) {
    setState({
      commodities: [],
    });
  }

  @Action(DeleteCommodity)
  deleteCommodity(
    ctx: StateContext<CommodityStateModel>,
    { payload }: DeleteCommodity
  ) {
    const state = ctx.getState();
    const filteredcommodity = state.commodities.filter(
      commodities => commodities.commodityNumber !== payload
    );
    ctx.setState({
      ...state,
      commodities: filteredcommodity,
    });
  }
}

// Miscellaneous Commodity
export class MiscellaneousCommodityStateModel {
  miscellaneousCommodities!: CommodityMiscellaneous[];
}

@State<MiscellaneousCommodityStateModel>({
  name: 'miscellaneousCommodities',
  defaults: {
    miscellaneousCommodities: [],
  },
})
@Injectable()
export class MiscellaneousCommodityState {
  @Action(AddMiscellaneousCommodity)
  updateForm(
    ctx: StateContext<MiscellaneousCommodityStateModel>,
    { payload }: AddMiscellaneousCommodity
  ) {
    const state = ctx.getState();
    const updatedMiscellaneousCommodities = [...state.miscellaneousCommodities];
    const existingIndex = updatedMiscellaneousCommodities.findIndex(
      misc => misc.id === payload.id
    );
    if (existingIndex !== -1) {
      updatedMiscellaneousCommodities[existingIndex] = payload;
    } else {
      updatedMiscellaneousCommodities.push(payload);
    }
    ctx.setState({
      ...state,
      miscellaneousCommodities: updatedMiscellaneousCommodities,
    });
  }

  @Action(RemoveUnsavedMiscellaneousCommodities)
  removeUnsavedMiscellaneousCommodities(
    ctx: StateContext<MiscellaneousCommodityStateModel>
  ) {
    const state = ctx.getState();
    const updatedMiscellaneousCommodities =
      state.miscellaneousCommodities.filter(misc => misc.isSavedData);
    ctx.setState({
      ...state,
      miscellaneousCommodities: updatedMiscellaneousCommodities,
    });
  }

  @Action(ResetMiscellaneousCommodity)
  resetMiscellaneousCommodity(
    ctx: StateContext<MiscellaneousCommodityStateModel>
  ) {
    ctx.setState({
      miscellaneousCommodities: [],
    });
  }
}
// Events
export interface EventsStateModel {
  events: EventList[];
}
@Injectable()
@State<EventsStateModel>({
  name: 'events',
  defaults: {
    events: [],
  },
})
export class EventsState {
  @Action(AddEvents)
  addEvents(ctx: StateContext<EventsStateModel>, action: AddEvents) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      events: action.payload,
    });
  }

  @Action(ResetEvents)
  resetEvents(ctx: StateContext<EventsStateModel>, action: ResetEvents) {
    ctx.setState({
      events: [],
    });
  }
}

// Hawbs
export interface HawbsListStateModel {
  hawbs: hawbColumnData[];
  totalData: number;
  origins: Origin[];
  destinations: Origin[];
}

@Injectable()
@State<HawbsListStateModel>({
  name: 'hawbs',
  defaults: {
    hawbs: [],
    totalData: 0,
    origins: [],
    destinations: [],
  },
})
export class HawbsListState {
  @Action(FetchHawbsList)
  FetchHawbsList(
    ctx: StateContext<HawbsListStateModel>,
    action: FetchHawbsList
  ) {
    const state = ctx.getState();
    const payload = action.payload;
    if (payload.data && payload.data.hawb && payload.data.totalRecords) {
      ctx.setState({
        ...state,
        hawbs: payload.data.hawb,
        totalData: payload.data.totalRecords,
      });
    } else {
      ctx.setState({
        ...state,
        hawbs: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetHawbsList)
  ResetHawbsList(
    ctx: StateContext<HawbsListStateModel>,
    action: ResetHawbsList
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      hawbs: [],
      totalData: 0,
    });
  }
}

export class UpdateMainConsignee {
  static readonly type = '[Consignee] Update Consignee';
  constructor(public payload: ConsigneeShippingModel) {}
}
export class UpdateMainShipper {
  static readonly type = '[Shipper] Update Shipper';
  constructor(public payload: ConsigneeShippingModel) {}
}

@State<ConsigneeShipperStateModel>({
  name: 'consigneeShipper',
  defaults: defaultConsigneeShipping,
})
@Injectable()
export class ConsigneeShippingState {
  @Action(UpdateMainConsignee)
  updateMainConsignee(
    ctx: StateContext<ConsigneeShipperStateModel>,
    action: UpdateMainConsignee
  ) {
    const state = ctx.getState();
    const updatedConsignee = {
      ...state.consignee,
      ...action.payload,
    };
    ctx.setState({
      ...state,
      ...{
        ...state,
        consignee: updatedConsignee,
      },
    });
  }

  @Action(UpdateMainShipper)
  updateMainShipper(
    ctx: StateContext<ConsigneeShipperStateModel>,
    action: UpdateMainShipper
  ) {
    const state = ctx.getState();
    const updatedShipper = {
      ...state.shipper,
      ...action.payload,
    };
    ctx.setState({
      ...state,
      ...{
        ...state,
        shipper: updatedShipper,
      },
    });
  }
}
