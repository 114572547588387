import { Directive, HostListener, inject, InjectionToken } from '@angular/core';
import { UnsavedChangeAlert } from '../../core/guards/unsaved-changes-warning.guard';

export const UNSAVED_CHANGES_DATA_COMPONENT =
  new InjectionToken<UnsavedChangeAlert>('Component accessor');

@Directive({
  selector: '[appUnsavedChangesWarning]',
  standalone: true,
})
export class UnsavedChangesWarningDirective {
  component: UnsavedChangeAlert | null = inject<UnsavedChangeAlert | null>(
    UNSAVED_CHANGES_DATA_COMPONENT,
    { optional: true }
  );

  @HostListener('window:beforeunload', ['$event'])
  async beforeUnload(event: BeforeUnloadEvent) {
    if (await this.component?.hasUnsavedChanges?.()) {
      event.preventDefault();
      event.returnValue = 'Has Unsaved changes';
    }
  }
}
