import { TableColumnsProp } from 'src/app/shared/models/tables.model';
import {
  AccordionTabs,
  searchDataParam,
} from 'src/app/shared/utility/constants';
import {
  ButtonsConfig,
  ComponentMapping,
  FormConfig,
} from 'src/app/shared/models/dynamic.model';
import { BusinessInformationComponent } from './accounts-information/business-information/business-information.component';
import { GovernmentInformationComponent } from './accounts-information/government-information/government-information.component';
import { AddressComponent } from './accounts-information/address/address.component';
import {
  IAccountsDetailData,
  IAddressInfoDetailsData,
  IBusinessInfoDetailsData,
  ICustomerService,
  IGovernmentInfoDetailsData,
} from '../../model/sm-accounts';

export const accountsAddressTab = ['address', 'billingAddress'];

export const defautAccountPagination = {
  sortingOrder: searchDataParam.desc,
  sortingField: searchDataParam.accountId,
  fromIndex: 0,
  toIndex: 10,
};

export const accountSummaryGridColumns: TableColumnsProp[] = [
  {
    key: 'accountNumber',
    label: 'Account Number',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'accountName',
    label: 'Account Name',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'organizationName',
    label: 'Organization Name',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'shipments',
    label: 'Shipment Count',
    isSelected: true,
    defaultView: true,
  },
  {
    key: 'additionalInfo',
    label: 'Additional Information',
    isSelected: true,
    defaultView: true,
  },
];

export const AccountInfoCrudFormConfig: FormConfig = {
  fields: [
    {
      id: 'accountName',
      type: 'input',
      label: 'Account Name',
      required: true,
      requiredError: 'Account Name is required',
      allowAlphabet: true,
      minValue: 2,
      maxValue: 126,
      minError: 'Invalid Account Name entered',
    },
    {
      id: 'accountNumber',
      type: 'input',
      label: 'Account Number',
      required: true,
      requiredError: 'Account Number is required',
      allowNumeric: true,
      minValue: 7,
      maxValue: 7,
      minError: 'Account number should be always 7 digits',
    },
    {
      id: 'organizationName',
      type: 'select',
      label: 'Organization Name',
      required: true,
      requiredError: 'Organization Name is required',
    },
    {
      id: 'description',
      type: 'input',
      label: 'Description',
      noInitialWhiteSpace: true,
      allowAlphaNumericWithSpace: true,
      maxValue: 126,
      isCheckForMaxValue: true,
    },
    {
      id: 'additionalInformation',
      type: 'input',
      label: 'Additonal Information',
      noInitialWhiteSpace: true,
      allowAlphaNumericWithSpace: true,
      maxValue: 500,
      isCheckForMaxValue: true,
    },
    {
      id: 'accountId',
      type: 'extra',
    },
    {
      id: 'organizationId',
      type: 'extra',
    },
    {
      type: 'extra',
      id: 'isAccountHasDependancy',
    }
  ],
};

export const accountsAccordionTabConfig: ComponentMapping[] = [
  {
    title: AccordionTabs.Address,
    componentType: AddressComponent,
  },
  {
    title: AccordionTabs.BusinessInformation,
    componentType: BusinessInformationComponent,
  },
  {
    title: AccordionTabs.GovernmentInformation,
    componentType: GovernmentInformationComponent,
  },
  { title: AccordionTabs.Attachments, componentType: '' },
  { title: AccordionTabs.Events, componentType: '' },
  { title: AccordionTabs.Details, componentType: '' },
];

export const organizationCrudFormConfig: FormConfig = {
  fields: [
    {
      id: 'organizationName',
      type: 'input',
      label: 'Organization Name',
      required: true,
      requiredError: 'Organization Name is required',
    },
    {
      id: 'description',
      type: 'input',
      label: 'Description',
    },
    {
      id: 'additionalInfo',
      type: 'input',
      label: 'Additional Information',
    },
  ],
};

export const commonBtnForModalFooter = [
  {
    name: 'Cancel',
    btnClass: 'ibc-medium-button ibc-secondary-default-button cursor',
  },
];

export const buttonsForAccountsAlreadyExistModal: ButtonsConfig[] = [
  ...commonBtnForModalFooter,
  {
    name: 'Try Another',
    btnClass: 'ibc-medium-button ibc-secondary-default-button cursor',
  },
  {
    name: 'Create Linked Account',
    btnClass: 'ibc-secondary-accent-button-md cursor',
  },
  {
    name: 'Use Suggested Account',
    btnClass: 'ibc-medium-button ibc-primary-default-button cursor',
  },
];

export const buttonsForFirstLinkedAccount: ButtonsConfig[] = [
  ...commonBtnForModalFooter,
  {
    name: 'Proceed',
    btnClass: 'ibc-medium-button ibc-primary-default-button cursor',
  },
];

export const businessInformationFormFields: FormConfig = {
  fields: [
    {
      id: 'DUNSNumber',
      type: 'input',
      label: 'DUNS Number',
      noInitialWhiteSpace: true,
      allowNumeric: true,
      minValue: 9,
      maxValue: 9,
      minError: 'Invalid DUNS Number entered',
    },
    {
      id: 'FEINNumber',
      type: 'input',
      label: 'FEIN Number',
      noInitialWhiteSpace: true,
      allowNumeric: true,
      minValue: 9,
      maxValue: 9,
      minError: 'Invalid FEIN Number entered',
    },
    {
      id: 'stateTaxId',
      type: 'input',
      label: 'State Tax ID',
      noInitialWhiteSpace: true,
      allowNumeric: true,
      minValue: 9,
      maxValue: 9,
      minError: 'Invalid State Tax ID entered',
    },
  ],
};

export const governmentInformationFields: FormConfig = {
  fields: [
    {
      id: 'salesPerson',
      type: 'select',
      label: 'Sales Person',
      required: true,
      requiredError: 'Sales Person is required',
    },
    {
      id: 'salesPersonId',
      type: 'extra',
      label: 'Sales Person',
    },
    {
      id: 'clearanceType',
      type: 'select',
      label: 'Clearance Type',
    },
    {
      id: 'clearanceTypeId',
      type: 'extra',
      label: 'Clearance Type',
    },
    {
      id: 'selectEmailsForInvoiceNotification',
      type: 'multi-select-autocomplete',
      label: 'Select Emails for Invoice Notification',
    },
    {
      id: 'selectEmailsforGatewayNotification',
      type: 'multi-select-autocomplete',
      label: 'Select Emails for Gateway Notification',
    },
  ],
};

export const customerServiceTableColumnConfig: TableColumnsProp[] = [
  {
    key: 'serviceTypeId',
    label: 'Service Type',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'discount',
    label: 'Discount',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'rateScale',
    label: 'Rate Scale',
    isSelected: true,
    defaultPrintWidth: 100,
  },
  {
    key: 'fuelSurcharge',
    label: 'Fuel Surcharge',
    isSelected: true,
    defaultPrintWidth: 100,
  },
];

export const customerServiceFormConfig: FormConfig = {
  fields: [
    {
      id: 'serviceTypeId',
      type: 'select',
      label: 'Service Type',
      required: true,
      requiredError: 'Service Type is required',
    },
    {
      id: 'serviceType',
      type: 'extra',
    },
    {
      id: 'discount',
      type: 'input',
      label: 'Discount',
    },
    {
      id: 'rateScale',
      type: 'select',
      label: 'Rate Scale',
      required: true,
      requiredError: 'Rate Scale is required',
    },
    {
      id: 'rateScaleId',
      type: 'extra',
    },
    {
      id: 'fuelSurcharge',
      type: 'select',
      label: 'Fuel Surcharge',
    },
    {
      id: 'fuelSurchargeId',
      type: 'extra',
    },
    {
      id: 'id',
      type: 'extra',
    },
    {
      id: 'tempId',
      type: 'extra',
    },
    {
      id: 'isRowDisable',
      type: 'extra',
    },
    {
      id: 'action',
      type: 'extra',
    },
  ],
};

export const firstLinkedAccountRadioOptions: string[] = [
  'Change Existing Shipments to Account 1234501',
  'Leave existing shipments as Account 1234500',
];

export const defaultAccountsDetailData = (): IAccountsDetailData => ({
  accountName: '',
  accountNumber: 0,
  organizationName: '',
  organizationId: 0,
  description: '',
  additionalInformation: '',
  accountId: 0,
  isAccountHasDependancy: false,
});

export const defaultAccountsAddressInfoData = (): IAddressInfoDetailsData => ({
  address: defaultAccountsAddressData,
  billingAddress: defaultAccountsAddressData,
  isSameAsAdress: false,
});

export const defaultAccountsAddressData = {
  country: '',
  phone: '',
  email: '',
  name: '',
  companyName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  contactPerson: '',
};

export const defaultAccountsBusinessInfoData =
  (): IBusinessInfoDetailsData => ({
    DUNSNumber: '',
    FEINNumber: '',
    stateTaxId: '',
  });

export const defaultAccountsGovernmentInfoData =
  (): IGovernmentInfoDetailsData => ({
    salesPerson: '',
    salesPersonId: 0,
    clearanceType: '',
    clearanceTypeId: 0,
    selectEmailsForInvoiceNotification: [],
    selectEmailsforGatewayNotification: [],
    customerService: defaultCustomerServiceData,
  });

export const defaultCustomerServiceData: ICustomerService[] = [
  {
    id: 0,
    tempId: '',
    action: 'create',
    serviceType: '',
    serviceTypeId: '',
    discount: '',
    rateScale: '',
    rateScaleId: '',
    fuelSurcharge: '',
    fuelSurchargeId: '',
    isRowDisable: false,
  },
];

export const AccountExcelHeaders: string[] = [
  'Account Number',
  'Account Name',
  'Organization Name',
  'Shipment Count',
  'Additional Info',
];
