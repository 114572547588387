import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoTrailingSpace]',
})
export class NoTrailingSpaceDirective {
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    if (value.length > 0 && value.endsWith('')) {
      inputElement.value = value.trim();
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.value;

    if (event.key === '' && value.length > 0 && value.endsWith('')) {
      event.preventDefault();
    }
  }
}
