import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import { customerMaagementFields } from '../../accounts.config';
import {
  FormField,
  IDropdownResponse,
} from 'src/app/shared/models/dynamic.model';
import {
  ActionIcons,
  conditionCheckConstants,
  ModeIcons,
  pageType,
  validationErrorMessages,
} from 'src/app/shared/utility/constants';
import { CommonService } from 'src/app/shared/services/common.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SaveCustomerManagementDetails } from '../../store/accounts.action';
import { Store } from '@ngxs/store';
import { MatSelectChange } from '@angular/material/select';
import { AccountsService } from 'src/app/features/service/accounts.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-customer-management',
  templateUrl: './customer-management.component.html',
  styleUrl: './customer-management.component.scss',
})
export class CustomerManagementComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public customerTabDropdownData$!: Observable<IDropdownResponse[]>;
  public customerManagementTabData$!: Observable<any>;
  public icons = ActionIcons;
  public customerManagementTabFormData: any;
  public pageMode!: string;
  public formFields!: FormField[];
  public customerManagementForm!: FormGroup;
  public errorMessages: any;
  public clearanceTypeData: any[] = [];
  public clearanceChannelData: any[] = [];
  public selectedClearanceType: string[] = [];
  public selectedClearanceChannel: any[] = [];
  public setCustomerManagementErrorTooltip: any = {};
  public setCustomerManagementTooltipEvent: any = {};
  public setCustomerManagementWidth: any = {};
  public filteredClearanceChannels: any[] = [];
  public scanPreferenceData: any[] = [];

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly formUtilsService: ValidatorService,
    public readonly commonService: CommonService,
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
    private readonly accountsService: AccountsService
  ) {
    this.customerTabDropdownData$ = this.store.select(
      state => state.accountDropdowns
    );
    this.setInitReq();
  }

  ngOnInit() {
    this.dataFetch();
    this.checkMode();
    this.handleNoClearanceToggle();
    this.handleClearanceTypeChanges();
  }

  ngAfterViewInit(): void {
    this.checkCustomerManagementValidData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public noClearanceToggleChanges(event: MatSlideToggleChange) {
    this.customerManagementForm.get('noNeedClearance')?.setValue(event.checked);
    this.enableOrDisableClearanceFields();
    this.dipatchCustomerManagementFormChanges();
  }

  private setInitReq() {
    this.createForm();
    this.formFields = customerMaagementFields.fields.filter(
      controls => controls.type !== conditionCheckConstants.extra
    );

    this.getLookupDataFromState();

    this.customerManagementTabData$ = this.store.select(
      state => state.accountsCustomerManagement
    );
  }

  private createForm() {
    const { form, errorMessages } = this.formUtilsService.generateForm(
      customerMaagementFields
    );
    this.customerManagementForm = form;
    this.errorMessages = errorMessages;
  }

  public dipatchCustomerManagementFormChanges() {
    const customerManagementFormValues = this.customerManagementForm.getRawValue();
    const clearanceTypeData = this.clearanceTypeData.filter(item =>
      customerManagementFormValues.clearanceType.includes(item.id)
    );
    const clearanceChannelData = this.filteredClearanceChannels.length > 0
      ? this.filteredClearanceChannels.filter(item =>
          customerManagementFormValues.clearanceChannel.includes(item.id)
        )
      : [];
    this.store.dispatch(
      new SaveCustomerManagementDetails({
        ...customerManagementFormValues,
        clearanceTypeData,
        clearanceChannelData,
      })
    );
    this.customerManagementForm
      .get('clearanceChannel')
      ?.setValue(this.selectedClearanceChannel);
    this.commonService.checkPristineAccordionData(false);
    this.validateCustomereManagementTab();
  }

  private getLookupDataFromState() {
    this.customerTabDropdownData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.clearanceTypeData = data.clearanceType.length
          ? data.clearanceType
          : [];
        this.clearanceChannelData = data.clearanceChannel.length
          ? data.clearanceChannel
          : [];
        this.scanPreferenceData = data.fullmanValidation.length
          ? data.fullmanValidation
          : [];
      });
  }

  private dataFetch() {
    this.commonService.savedBagData
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === pageType.dataBind) {
          this.fetchCustomerManagementTabData();
        }
      });
  }

  private fetchCustomerManagementTabData() {
    this.customerManagementTabData$
      .pipe(takeUntil(this.destroy$))
      .subscribe(customerManagementData => {
        this.customerManagementTabFormData = customerManagementData;
        this.customerManagementForm.patchValue(
          this.customerManagementTabFormData
        );
      });
  }

  private checkMode() {
    this.commonService.isCheckForMode
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data === ModeIcons.view) {
          this.pageMode = ModeIcons.view;
          this.customerManagementForm.disable();
        } else {
          this.pageMode = data != '' ? data : ModeIcons.add;
          this.customerManagementForm.enable();
        }
        this.enableOrDisableClearanceFields();
      });
  }

  public onSelectionClearanceTypeChange(event: MatSelectChange) {
    const value = event.value;
    this.selectedClearanceType = value.filter((data: any) => data !== 'All');
    this.updateFormControl();
  }

  private updateFormControl() {
    this.customerManagementForm
      .get('clearanceType')
      ?.setValue(this.selectedClearanceType);
    this.dipatchCustomerManagementFormChanges();
  }

  private deselectAllClearanceType() {
    this.selectedClearanceType = [];
    this.updateFormControl();
    this.dipatchCustomerManagementFormChanges();
  }

  public clearClearanceType() {
    this.deselectAllClearanceType();
  }

  public onSelectionclearanceChannelChange(event: MatSelectChange) {
    const value = event.value;
    this.selectedClearanceChannel = value;
    this.updateclearanceChannelFormControl();
  }

  private updateclearanceChannelFormControl() {
    this.customerManagementForm
      .get('clearanceChannel')
      ?.setValue(this.selectedClearanceChannel);
    this.dipatchCustomerManagementFormChanges();
  }

  private deselectAllclearanceChannel() {
    this.selectedClearanceChannel = [];
    this.updateclearanceChannelFormControl();
    this.dipatchCustomerManagementFormChanges();
  }

  public clearclearanceChannel() {
    this.deselectAllclearanceChannel();
  }
  
  public onscanPreferenceChange(event: any) {
    if (event.value) {
      this.customerManagementForm
        .get('scanPreference')
        ?.setValue(event.value);
      const selectedFullManFile = this.scanPreferenceData.filter(
        (data: any) => data.name === event.value
      );
      this.customerManagementForm
        .get('scanPreferenceId')
        ?.setValue(selectedFullManFile[0].id);
      this.dipatchCustomerManagementFormChanges();
    }
  }
  
  private validateCustomereManagementTab() {
    if (this.customerManagementForm.valid) {
      this.accountsService.checkCustomerManagementDataValid(true);
    } else {
      this.accountsService.checkCustomerManagementDataValid(false);
    }
    this.cdRef.detectChanges();
  }

  private checkCustomerManagementValidData() {
    this.accountsService.isCheckForCustomerManagementData
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        if (data === true) {
          this.commonService.validateFormFields(this.customerManagementForm);
          this.validateCustomereManagementTab();
        } else {
          this.customerManagementForm.markAsPristine();
          this.cdRef.detectChanges();
        }
      });
  }

  public fetchWidth(event: MouseEvent, controlName: string) {
    this.commonService.setErrorTooltipData(
      event,
      controlName,
      this.setCustomerManagementWidth,
      this.setCustomerManagementTooltipEvent,
      this.setCustomerManagementErrorTooltip
    );
  }

  public getErrorMessage(controlName: string) {
    if (
      this.customerManagementForm.get(controlName)?.errors?.[
        'autocompleteError'
      ]
    ) {
      return validationErrorMessages.autocompleteError;
    } else {
      return this.formUtilsService.getErrorMessage(
        this.customerManagementForm,
        this.errorMessages,
        controlName
      );
    }
  }

  public checkControlHasError(controlName: string) {
    return this.commonService.checkControlError(
      this.customerManagementForm,
      controlName
    );
  }

  private enableOrDisableClearanceFields() {
    const noNeedClearanceValue =
      this.customerManagementForm.get('noNeedClearance')?.value;
      this.checkClearance(noNeedClearanceValue);
      if(!noNeedClearanceValue) {
        this.filteredClearanceChannels = this.customerManagementTabFormData.clearanceChannelData;
        this.selectedClearanceChannel = this.customerManagementTabFormData.clearanceChannel;
        this.selectedClearanceType = this.customerManagementTabFormData.clearanceType;
        this.customerManagementForm
          .get('clearanceChannel')
          ?.setValue(this.selectedClearanceChannel);
      } else {
        this.filteredClearanceChannels = [];
        this.selectedClearanceChannel = [];
        this.selectedClearanceType = [];
        this.customerManagementForm
          .get('clearanceChannel')
          ?.setValue(this.selectedClearanceChannel);
      }
      this.customerManagementForm.markAsPristine();
  }

  private handleNoClearanceToggle(): void {
    this.customerManagementForm
      .get('noNeedClearance')
      ?.valueChanges.subscribe((noClearance: boolean) => {
        this.checkClearance(noClearance);
      });
  }
  
  private checkClearance(noClearance: boolean) {
    if (noClearance) {
      this.commonService.removeControlRequiredValidation(
        this.customerManagementForm,
        'clearanceType'
      );
      this.commonService.removeControlRequiredValidation(
        this.customerManagementForm,
        'clearanceChannel'
      );
      this.customerManagementForm.get('clearanceType')?.setValue([]);
      this.customerManagementForm.get('clearanceChannel')?.setValue([]);
      this.selectedClearanceType = [];
      this.selectedClearanceChannel = [];
      this.customerManagementForm.get('clearanceType')?.disable();
      this.customerManagementForm.get('clearanceChannel')?.disable();
    } else {
      this.commonService.setControlRequiredValidation(
        this.customerManagementForm,
        'clearanceType'
      );
      this.commonService.setControlRequiredValidation(
        this.customerManagementForm,
        'clearanceChannel'
      );
      if (this.pageMode !== ModeIcons.view) {
        this.customerManagementForm.get('clearanceType')?.enable();
      }
    }
    this.customerManagementForm.get('clearanceType')?.updateValueAndValidity();
    this.customerManagementForm
      .get('clearanceChannel')
      ?.updateValueAndValidity();
  }

  private handleClearanceTypeChanges(): void {
    this.customerManagementForm
      .get('clearanceType')
      ?.valueChanges.subscribe((selectedTypes: string[]) => {
        this.updateClearanceChannelOptions(selectedTypes);
    });
  }

  private updateClearanceChannelOptions(selectedTypes: string[]): void {
    if (selectedTypes.length === 0) {
      this.filteredClearanceChannels = [];
      this.selectedClearanceChannel = [];
      this.customerManagementForm.get('clearanceChannel')?.setValue([]);
      this.customerManagementForm.get('clearanceChannel')?.disable();
      return;
    }
    const allowedChannels = new Set<number>();
    if (selectedTypes.includes('C') || selectedTypes.includes('E')) {
      [1, 2, 3].forEach(id => allowedChannels.add(id));
    }
    if (selectedTypes.includes('T')) {
      [4, 5, 6].forEach(id => allowedChannels.add(id));
    }
    if (
      (selectedTypes.includes('C') || selectedTypes.includes('E')) &&
      selectedTypes.includes('T')
    ) {
      [1, 2, 3, 4,5,6].forEach(id => allowedChannels.add(id));
    }
    this.filteredClearanceChannels = this.clearanceChannelData.filter(channel =>
      allowedChannels.has(channel.id)
    );
    this.customerManagementForm.get('clearanceChannel')?.enable();
    this.customerManagementForm.get('clearanceChannel')?.setValue([]);
  }
}
