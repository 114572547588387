import { IDropdownResponse } from "src/app/shared/models/dynamic.model";

export interface ComponentMapping {
  title: string;
  componentType: any;
}

export interface MawbColumnsData {
  mawb: number;
  mawbId: number;
  origin: string;
  destination: string;
  agentCode: number;
  airlineCode: number;
  clearanceType: string;
  agentName: string;
  airlineName: string;
  mawbExt?: string;
}

export interface ManifestGridColumns {
  manifestId: string;
  manifestNumber: string;
  date: string;
  bags: number;
  shipments: number;
  isInputRow: boolean;
  id?: any;
}

export interface FlightGridColumns {
  airlineCode: string;
  flightNumber: string;
  slacCount: string;
  slacWeight: string;
  arrivalDate: string;
  mawbPart: string;
  isInputRow: boolean;
}

export interface IMawbDetailsData {
  mawbId: number;
  mawbExt: string;
  originAirport: string;
  destinationAirport: string;
  airlineId: number;
  airlineName: string;
  information: string;
  receivingAgent: string;
  clearanceType: string;
  agentCode: number;
  agentName: string;
  clearanceTypeCode: string;
  destinationID: number;
  originID: number;
}

export interface IMawbDetailsStateModel {
  mawbInfo: IMawbDetailsData;
}

export const defaultMawbInfoData = (): IMawbDetailsData => ({
  mawbId: 0,
  mawbExt: '',
  originAirport: '',
  destinationAirport: '',
  airlineId: 0,
  airlineName: '',
  information: '',
  receivingAgent: '',
  clearanceType: '',
  agentCode: 0,
  agentName: '',
  clearanceTypeCode: '',
  destinationID: 0,
  originID: 0,
});

export interface IMawbMiscellaneousData {
  usCbpEccf86EntryNumber?: any;
  clearanceAgent?: string;
}

export interface IMawbMiscellaneousStateModel {
  miscellaneous: IMawbMiscellaneousData;
}

export interface IMawbManifestData {
  id: string;
  noofpieces: string;
  noofbag: string;
  noofshipment: string;
  date: string;
  source?: string;
}
export interface IManifestStateModel {
  manifestList: IMawbManifestData[];
}
export interface FlightList {
  id: number;
  airlineCode: string;
  flightNumber: string;
  slacCount: string;
  slacWeight: string;
  arrivalDate: string;
  mawbPart: string;
  isInputRow: boolean;
  notes: INotesSectionData;
  source?: string;
  attachments: [];
}
export interface INotesSectionData {
  ibc: INotesDetail[];
  customer: INotesDetail[];
}
export interface INotesDetail {
  id: string | number;
  comments: string;
  action: string;
  isFromScreen?: string;
  notesId?: string | null;
  userId?: 0;
  userName?: string;
  facility?: string;
  date?: string;
  dateTime?: string;
  type?: string;
  source?: string;
  isEditable?: boolean;
  isDisable?: boolean;
  isSaved?: boolean;
}
export interface FlightStateModel {
  flights: FlightList[];
}
export interface IMawbManifestListData {
  id?: any;
  manifestsMawb?: any;
  source?: any;
  manifest?: any;
  manifestId?: string;
  manifestNumber?: string;
  date?: string;
  bags?: number;
  shipments?: number;
  isInputRow?: boolean;
}
export interface IMawbNotesDetails {
  id: string;
  comments: string;
  action: string;
  userId: number;
  userName: string;
}
export interface IMawbAttachmentData {
  id: any;
  type: string;
  fileName: string;
  signedUrl: string;
  action: string;
  attachmentTypeValuId: string;
  isFromScreen?: string;
}
export interface IMawbNotesData {
  ibc: IMawbNotesDetails[];
  customer: IMawbNotesDetails[];
}
export interface IMawbInformationData {
  mawbDate: string;
  mawbId: number;
  mawbExt: string;
  originAirport: number;
  destinationAirport: number;
  airlineId: any;
  airlineName: string;
  information: string;
  receivingAgent: any;
  clearanceType: string;
  agentCode: number;
  agentName: string;
  clearanceTypeCode: string;
  destinationID: string;
  originID: string;
  notes: IMawbNotesData;
  attachmentList: IMawbAttachmentData[];
  miscellaneous: IMawbMiscellaneousData;
  eventsList: IMawbEventData[];
  manifestList: IMawbLinkedManifestData[];
  mawbInfo?: any;
  flights: any;
}

export interface IMawbEventData {
  id: string;
  date: string;
  time: string;
  info: string;
  zone: string;
  name: string;
}

export interface IMawbLinkedManifestData {
  id: string;
  action: string;
  manifestNumber: number;
  mawb?: any;
}
export interface MawbBasicSearchResultsPayload {
  statusCode: number;
  message: string;
  data: {
    totalRecords: number;
    sortingOrder: string;
    sortingField: string;
    mawb: MawbColumnsData[];
  };
}

export interface MawbInfoResetStateModel {
  data: IMawbInformationData;
}

export interface IFlightMiscellaneousData {
  cargoOperator?: any;
  firstUsAirport?: string;
  firstUsAirportDate?: any;
  id: any;
  flightId: any;
  isSavedData: boolean;
}

export interface IFlightMiscellaneousStateModel {
  flightMiscellaneous: IFlightMiscellaneousData;
}

export interface MawbDropdownList {
  origins: IDropdownResponse[];
  destinations: IDropdownResponse[];
  agentData: IDropdownResponse[];
  clearanceTypeData: IDropdownResponse[];
  attachmentType: IDropdownResponse[];
  airlineData: IDropdownResponse[];
}