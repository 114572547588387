import {
  CbpRequestStatusPayload,
  CbpResponseStatusPayload,
  FullmanFileSearchDataPayload,
} from '../fullman-upload.model';

export class FetchFullmanFileData {
  static readonly type = '[Fullman Upload] Fullman File Data';
  constructor(public payload: FullmanFileSearchDataPayload) {}
}

export class ResetFullmanFileList {
  static readonly type = '[Fullman Upload] Reset Fullman Upload List';
}

export class FetchCbpRequestStatusData {
  static readonly type = '[CBP Request State] CBP Request Status Data';
  constructor(public payload: CbpRequestStatusPayload) {}
}

export class ResetCbpRequestList {
  static readonly type = '[CBP Request State] Reset CBP Request State List';
}

export class FetchCbpResponseStatusData {
  static readonly type = '[CBP Response State] CBP Response Status Data';
  constructor(public payload: CbpResponseStatusPayload) {}
}

export class ResetCbpResponseList {
  static readonly type = '[CBP Response State] Reset CBP Response State List';
}
