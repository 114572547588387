export const ErrorHandlerConstants = {
  Error_400: 'Element Not Found Please Provide Valid Trade Id',
  Error_500:
    'Sorry, Operation could not be completed at this time.\n Back-end returned with an Internal Server Error. Please contact customer support',
  Default_Error:
    'Sorry, Operation could not be completed at this time.\n Please contact customer support',
  Initial_Error_Text: 'Sorry, Operation could not be completed. \n Error: \n',
  String_Declare: 'string',
  Connective_Error:
    'Sorry, we are having connectivity issues, Please contact our Pulse customer engagement for more help',
  Error_502:
    'Sorry, Operation could not be completed at this time.\n Back-end returned with an Timeout Error. Please contact customer support',
  SessionTimout: 'Session timedout, Please login again',
  InvalidPackageType: 'Please enter a valid packaging type',
};

export const SnackBar = {
  Close: 'Close',
  bulkGatewaySuccessMessage: 'Selected 4 pieces gatewayed successfully',
  BulkBaggingSucess: '3 selected unbagged pieces bagged successfully',
};

export const HttpSignatures: HttpSignature = {
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Get: 'GET',
};

export interface HttpSignature {
  [name: string]:
    | 'DELETE'
    | 'GET'
    | 'HEAD'
    | 'JSONP'
    | 'OPTIONS'
    | 'POST'
    | 'PUT';
}

export enum StatusCodes {
  Success = 200,
  SuccessfulRequest = 204,
  BadRequest = 400,
  Unauthorised = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}

export const ModalPopupMessages = {
  AddComment: 'Add Comment',
  NotSignedUpServiceType:
    'You have not signed up for this service. Would you still like to go ahead and use this service?',
  InvalidEntry: 'Invalid Entry',
  HAWBValidation: 'Shipment should always have at least one piece',
  Validation: 'Validation',
  FillMandatoryFields:
    'Changes could not be saved. Please check your input and try again',
  AddHAWB: 'Would you like to add a Shipment for',
  DeleteHawb: (messageType: string) =>
    `Would you like to delete the selected ${messageType}?`,
  Warning: 'Warning',
  Error: 'error',
  Reset: 'You have unsaved changes. Would you like to go back?',
  ResetPiece: 'Reset Piece Information',
  ResetFlight: 'Reset Flight Information',
  ResetCommodity: 'Reset Commodity Information',
  close: 'close',
  resetHeader: 'Reset',
  DeletePiece: 'Are you sure you want to delete this piece?',
  ChangeSuccess: 'Changes have been saved successfully',
  success: 'success',
  error: 'error',
  ChangeResetSuccess: 'Changes reset successfully',
  SaveChanges: 'Would you like to save the changes?',
  SaveCommodityInfo: 'Save Commodity Information',
  save: 'save',
  DeleteCommodity: 'Are you sure you want to delete this commodity?',
  MandatoryFields:
    'Please fill out all mandatory fields and check all inputs before proceeding',
  Block: 'Block',
  Unblock: 'Unblock',
  BlockMessage: 'Are you sure you want to block the Shipment ?',
  UnblockMessage: 'Are you sure you want to unblock the Shipment ?',
  SavePiece: 'Save Piece Information',
  DeleteStatusCode: 'delete the Status Code',
  StatusCode: 'Status Code',
  DeletePOD: 'delete the POD',
  POD: 'POD',
  unlinkPiece: 'Are you sure you want to unlink this piece(s)?',
  linkPiece: 'Piece added successfully',
  unlink: 'Unlink Piece(s)',
  unlinkSuccess: 'Piece removed from the bag',
  noChanges: 'No Changes were made to save',
  noMoreFlights: 'Allowed only to add a maximum of 3 flights only',
  cancel:
    'You have made changes. Are you sure you want to discard the changes?',
  flightInfo: 'Flight Maximum Limit',
  noChangeSave: 'No Changes',
  notesInformationText:
    'Notes are a useful record of key information. Here, user can add important notes relevant to the Shipment. Remember, clear and concise notes help streamline communication and tracking throughout the Shipment lifecycle',
  statusCodeInformationText:
    'The status code represents the current state or condition of the Shipment. Choose the appropriate status code that accurately reflects the progress or status of the shipment. Consistent use of status codes aids in effective tracking and management of shipments.',
  podInformationText:
    'The Proof of Delivery (POD) functionality encompasses various delivery confirmation methods to ensure a comprehensive and adaptable system for verifying the receipt of shipments.',
  attachmentsInformationText:
    'Attachments serve as supporting documentation for the Shipment, providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Shipment record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the Shipment lifecycle.',
  miscellaneousInformationText:
    'This section captures additional details and miscellaneous information related to the Shipment. Here, user can include relevant details that contribute to a comprehensive understanding of the Shipment. Clear and accurate miscellaneous information enhances communication and clarity throughout the Shipment lifecycle',
  pieceAttachmentsInformationText:
    'Attachments serve as supporting documentation for the Piece providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Piece record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the piece lifecycle.',
  commodityAttachmentsInformationText:
    'Attachments serve as supporting documentation for the Commodity, providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Commodity record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the Commodity lifecycle.',
  pieceNotesInformationText:
    'Notes are a useful record of key information. Here, user can add important notes relevant to the piece. Remember, clear and concise notes help streamline communication and tracking.',
  commodityNotesInformationText:
    'Notes are a useful record of key information. Here, user can add important notes relevant to the commodity. Remember, clear and concise notes help streamline communication and tracking.',
  commodityMiscellaneous:
    'This section captures additional details and miscellaneous information related to the commodity. Here, user can include relevant details that contribute to a comprehensive understanding of the commodity. Clear and accurate miscellaneous information enhances communication and clarity.',
  resetBag: 'Reset Bag Information ?',
  deleteNote: 'Are you sure you want to delete the note ?',
  app: 'App',
  ui: 'UI',
  bagNotesText:
    'Notes are a useful record of key information, and the sources of that information. Here, user can add important notes relevant to the Bag. Remember, clear and concise notes help streamline communication and tracking throughout the Bag lifecycle',
  loreum:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmodtempor incididunt ut labore et dolore magna aliqua. Ut enim ad minimveniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex eacommodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  bagAttachment:
    'Attachments serve as supporting documentation for the Bag, providing additional context and detail. Here, user can upload relevant files, such as invoices, receipts, or inspection reports, to enrich the Bag record. Clear and comprehensive attachments facilitate efficient communication and documentation throughout the Bag lifecycle.',
  bagMiscellaneous:
    'This section captures additional details and miscellaneous information related to the Bag. Here, user can include relevant details that contribute to a comprehensive understanding of the Bag. Clear and accurate miscellaneous information enhances communication and clarity throughout the Bag lifecycle.',
  unlinkBagManifest: 'Are you sure you want to unlink this bag(s)?',
  unlinkBag: 'Unlink Bag(s)',
  unlinkBagSuccess: 'Bag removed from the Manifest',
  linkBagSuccess: 'Bag added successfully',
  resetManifest: 'Reset Manifest Information ?',
  deleteConfirmationText: 'If Yes, type "Delete" and click Delete',
  clearConfirmationText: 'If so, type "Clear" and click Clear',
  PieceShipmentAdd: 'Piece already in a different shipment',
  commodityAdd: 'Commodity added successfully',
  FillAgentMandatoryField:
    'Agent is required. Please fill in the Agent before publishing the Manifest.',
  savedSearchesHeader: 'Saved Searches',
  recentSearchesHeader: 'Recent Searches',
  saveAsNewSearchHeader: 'Save Search',
  pinSearch: 'Pin Search',
  mawbMiscellaneousMessage:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ',
  updateSearch: 'Update Search',
  searchDelete: 'Would you really like to delete the Search',
  clearRecentSearchContent: 'Do you want to delete all Recent Searches?',
  FillHawbMandatoryField:
    'Please fill out all mandatory fields before proceeding',
  resetMAWB: 'Reset MAWB Information?',
  restMawbInfo: '‘Would you like to cancel the MAWB creation and go back?',
  BulkDeleteHeader: 'Bulk Delete?',
  singleDeleteHeader: (headerName: string) => `Delete ${headerName} ?`,
  bulkDeleteMessage: (count: number | string, itemName: string) =>
    `Do you want to delete these ${count} ${itemName}?`,
  singleDeleteMessage: (itemName: string) =>
    `Do you want to delete the ${itemName} `,
  singleDeleteSuccessMessage: (itemName: string) =>
    `Selected ${itemName} deleted successfully`,
  bulkDeleteSuccessMessage: (count: number | string, itemName: string) =>
    `Selected ${count} ${itemName} deleted successfully`,
  downloadFileText: 'Select file type',
  BagManifestDeleteSuccess: (itemName: string) =>
    `${itemName} removed from the Manifest`,
  PieceCommodityDeleteSuccess: (itemName: string) =>
    `${itemName} deleted successfully`,
  PieceBagDeleteSuccess: (itemName: string) =>
    `${itemName} removed from the Bag`,
  addShipment: 'Add Shipment?',
  downloadSuccess: (itemName: string) => `${itemName} download successful`,
  mawbExist: 'Mawb already exists. Please enter another one',
  resetAgentInfo: '‘Would you like to cancel the Agent creation and go back?',
  resetAgent: 'Reset Agent Information ?',
  searchSuccess: (itemName: string, action: string, count?: number) =>
    count
      ? `${count} ${itemName} ${action} successfully`
      : `${itemName} ${action} successfully`,
  searchLimitWarning: (itemName: string, count: number) =>
    `${itemName} search limit reached: Maximum of ${count} searches allowed`,
  alreadyExists: (itemName: string) =>
    `${itemName} already exists. Please enter another one`,
  linkManifestSuccess: 'Manifest added successfully',
  unlinkManifestMawb: 'Are you sure you want to unlink this Manifest(s)?',
  unlinkManifest: 'Unlink Manifest(s)',
  actionCancel: 'Action cancelled',
  unlinkFlightMawb: 'Are you sure you want to delete this Flight(s)?',
  unlinkFlight: 'Delete Flight(s)',
  addFlightSuccess: 'Flight added successfully',
  MandatoryFlightFields:
    'Please fill out all mandatory fields and check all inputs before proceeding',
  DiscardChangesHeader: 'Discard changes',
  DiscardChangesContent: 'Are you sure you want to discard the changes?',
  PublishMessage: (itemName: string, moduleName: string) =>
    `Would you like to publish ${moduleName} ${itemName} ?`,
  PublishMultipleMessage: (count: number, moduleName: string) =>
    `Would you like to publish ${count} ${moduleName}s?`,
  PublishRemainingMessage: (items: any[]) =>
    `${items} Manifests do not fulfill the publish criteria. Would you like to still proceed with publishing the remaining Manifests?`,
  restMawb: 'Reset MAWB Information ?',
  flightDeleteSuccess: (itemName: string) => `${itemName} deleted successfully`,
  updateBag: 'Update Bag ?',
  blockShipmet: 'Block Shipment',
  unblockShipment: 'Unblock Shipment',
  advancedSearchHelpModalHeaderText: 'Advanced Search Text Operators',
  fillAllMandatoryFields: 'Fill all the mandatory fields',
  Close: 'Close',
  deleteSingleAttachmentMessage: (itemName: string) =>
    `Do you want to delete the selected ${itemName} ?`,
  deleteBulkAttachmentMessage: (count: number, itemName: string) =>
    `Do you want to delete these ${count} ${itemName} ?`,
  printColumnSelection: 'Choose columns to print',
  organizationCrudModalHeader: 'Organization',
  deleteManifestFromMawb: (id: number) =>
    `Do you want to delete the Manifest ${id}?`,
  mawbPartValidationMessage:
    "Please enter 'A' for the first split, 'B' for the second split, or 'C' for the third split.",
  shipmentSelection: 'Shipment Selection',
  noteSuccess: 'Note added in shipment successfully',
  bulkGatewayHeader: 'Bulk Gateway',
  bulkGatewayMessage: 'Would you like to gateway all selected 4 pieces?',
  bulkGatewayConfirmationMessage:
    'If Yes, please type “Gateway 4” and click confirm',
  bulkBag: 'Bulk Bag',
  bulkBagConfirmationMessage: (pieceCount: number, bagExt: string) =>
    `Would you like to bag all the selected ${pieceCount} pieces into ${bagExt}?`,
  partialBulkBagConfirmationMessage: (
    totalPieces: number,
    baggedPieces: number,
    unBaggedPieces: number,
    bagExt: string
  ) =>
    `Out of the selected ${totalPieces} pieces, ${baggedPieces} piece(s) is/are already bagged. Would you like to bag remaining ${unBaggedPieces} pieces into ${bagExt}?`,
  bulkBagConfirmationText: (pieceCount: number) =>
    `If Yes, please type "Bag ${pieceCount}" and click Confirm`,
  bulkBaggingErrorMessage: (
    shipmentDestination: string,
    bagDestination: string
  ) =>
    `Shipment's Destination ${shipmentDestination} and entered Bag's destination ${bagDestination} are not same. Hence bulk bagging not possible.`,
  printWarningContent:
    'Selected Columns may not fit for the selected paper size while printing',
  printWarningContentConfirmation:
    'Are you sure you want to continue printing?',
  mawbManifestOriginDestinationMessage: `The entered Origin or Destination does not match the linked Manifest's Origin and Destination. Please either delink the Manifest or update its Origin and Destination to match.`,
  attachmentsHeader: 'Attachments',
  podDetailsHeader: 'POD Details',
  additionalDetailsHeader: 'Additional Details',
  deleteLinkingSuccess: (itemName: string, action: string, count?: number) =>
    count ? `${count} ${itemName} ${action}` : `${itemName} ${action}`,
  gatewayWeightMismatchMessage:
    'The measured weight and/or measured dimension varies from the data in the fullman file by more than 5%. Would you like to override the existing data?',
  gatewayLocationMismatch: `The scanned shipment doesn't belong to this location. Would you still like to proceed with it?`,
  masterShipmentsHeader: 'Master Shipment Selector',
  gatewayCbpFailureMessage:
    'The shipment has not been cleared by the CBP. Would you like to proceed with the Gateway process?',
};

export const actionText = {
  deleted: 'deleted',
  pinned: 'pinned',
  unpinned: 'unpinned',
  saved: 'saved',
  update: 'updated',
  pin: 'Pin',
  Saved: 'Saved',
  published: 'published',
  added: 'added',
  samesAsBillingAddress: 'Same as Billing Address',
  removedFromMAWB: 'removed from the MAWB',
};

export const searchLimitCount = {
  pin: 10,
  saved: 1000,
};

export const SingleDeleteHeaderText = {
  Shipment: 'Shipment',
  Bag: 'Bag',
  Manifest: 'Manifest',
  Search: 'Search',
  Piece: 'Piece',
  Commodity: 'Commodity',
  Mawb: 'Mawb',
  RecentSearch: 'Recent search',
  RecentSearches: 'Recent searches',
  savedSearch: 'Saved search',
  savedSearches: 'Saved searches',
  Flight: 'Flight',
  Flights: 'Flights',
  Role: 'Role',
  Attachment: 'attachment',
  BillingAddress: 'Billing Address',
  Attachments: 'attachment(s)',
  SubAccounts: 'Sub Accounts',
};

export const BulkDeleteItemNames = {
  Shipments: 'Shipments',
  Bags: 'Bags',
  Manifests: 'Manifests',
  Searches: 'Searches',
  Pieces: 'Pieces',
  Commodities: 'Commodities',
  Mawbs: 'MAWBs',
  Flights: 'Flights',
  Flight: 'Flight',
  Agents: 'Agents',
  AgentInformation: 'Agent Information',
  AdvancedSearch: 'Advanced Search',
  Commodity: 'Commodity',
  Piece: 'Piece',
  Accounts: 'Accounts',
  AccountInfo: 'Account Information',
  SubAccountInfo: 'Sub - Account Information',
  StatusCodes: 'Status Codes',
  RolesAndPermissions: 'Roles & Permissions',
};

export const DownloadItemNames = {
  Shipments: 'Shipment(s)',
  Bags: 'Bag(s)',
  Manifests: 'Manifest(s)',
  Mawbs: 'MAWB(s)',
};

export const popupButtons = {
  Clear: 'Clear',
  Cancel: 'Cancel',
  Delete: 'Delete',
  Download: 'Download',
  Yes: 'Yes',
  No: 'No',
  Discard: 'Discard Changes',
  ContinueEdit: 'Continue Editing',
  Block: 'Block',
  Print: 'Print',
  BulkBag: 'Bulk Bag',
  Confirm: 'Confirm',
  Preview: 'Preview',
  Ok: 'OK',
  Save: 'Save',
  Close: 'Close',
  gatewayOverrideExisting: 'Override existing',
  gatewayRetainExisting: 'Retain existing',
  gateway: 'Gateway',
  editShipment: 'Edit Shipment',
  proceedWithGateway: 'Proceed with Gateway',
};

export const ActionIcons = {
  close: 'close',
  edit: 'edit',
  save: 'save',
  reset: 'reset',
  Reset: 'Reset',
  refresh: 'refresh',
  filter: 'filter',
  add: 'add',
  delete: 'delete',
  view: 'view',
  bulkEdit: 'bulk-edit',
  addCom: 'Addcom',
  AddTooltip: 'Add',
  addCommodity: 'addCom',
  download: 'Download',
  downloadFile: 'downloadFile',
  Downloadfile: 'Downloadfile',
  pinSearch: 'pin',
  unPinSearch: 'unpin',
  advanceSearch: 'advancedSearch',
  hamburger: 'hamburger',
  search: 'Search',
  searchSmallCase: 'search',
  default: 'Default',
  selected: 'Selected',
  searchPopupPin: 'searchActionPin',
  searchPopupEdit: 'searchActionEdit',
  searchPopupDelete: 'searchActionDelete',
  searchPopupSave: 'searchActionSave',
  hamburgerRecentListPin: 'hamburgerRecentListPin',
  hamburgerRecentListSave: 'hamburgerRecentListSave',
  hamburgerRecentListDelete: 'hamburgerRecentListDelete',
  rightArrow: 'arrowRight',
  print: 'print',
  bulkview: 'bulk-view',
  fieldClear: 'fieldClear',
  infoCircle: 'infoCircle',
  fetchResult: 'fetchResult',
  details: 'details',
  publish: 'Publish',
  advancedSearchTrigger: 'advancedSearchTriggerHeader',
  blockShipmet: 'Blockshipment',
  block: 'Block',
  advancedSearchHelp: 'advancedSearchHelpHeader',
  datePicker: 'datePicker',
  notesShortcut: 'notesShortcut',
  keyboardDownArrow: 'keyboard_arrow_down',
  helpOutlinedIcon: 'help_outline_outlined_icon',
  totalValueIcon: 'totalValue',
  blockIcon: 'block',
  Print: 'Print',
  advancedSearchPinHeader: 'advancedSearchPinHeader',
  advancedSearchHamburgerHeader: 'advancedSearchHamburgerHeader',
  weightIcon: 'weight',
  bulkGateway: 'bulkGateway',
  bulkBag: 'bulkBag',
};

export const ModeIcons = {
  mode: 'mode',
  edit: 'Edit',
  view: 'View',
  add: 'Add',
  save: 'Save',
  close: 'Close',
  bulkEdit: 'Bulk-Edit',
  smallview: 'view',
  smallEdit: 'edit',
  deleteAPI: 'deleteAPI',
  delete: 'delete',
  MainView: 'MainView',
  resetSelection: 'resetSelection',
  block: 'blockShipment',
  unblock: 'unblock',
  bulkView: 'Bulk-View',
  smallBulkView: 'bulk-view',
};

export const HawbStatus = {
  unblocked: 'unblocked',
  blocked: 'blocked',
  default: 'default',
};

export const CrudActions = {
  create: 'c',
  update: 'u',
  delete: 'd',
  api: 'API',
  ui: 'UI',
  add: 'a',
  updatePiece: 'update',
};

export const NotesType = {
  ibc: 'ibc',
  customer: 'customer',
};

export const pageType = {
  hawb: 'hawb',
  piece: 'piece',
  commodity: 'commodity',
  bag: 'bag',
  bagPiece: 'bag-piece',
  pieceInfo: 'piece-information',
  commodityInfo: 'commodity-information',
  manifest: 'manifest',
  mawb: 'mawb',
  dataBind: 'dataBind',
  flightInformation: 'flight-information',
  agent: 'agent',
  mawbFlight: 'mawb-flight',
  agentInformation: 'agent-information',
  shipmentCommodity: 'shipment-commodity',
  account: 'account',
  accountInformation: 'account-information',
  accountBillingAddress: 'accountBillingAddress',
  subAccount: 'sub-account',
  setupAndManitenance: 'sm-dashboard',
  shipmentPiece: 'shipment-piece',
  hawbAdvanceSearch: 'hawb_advanceSearch',
  shipmentGateway: 'shipment-gateway',
  shipmentGatewayPiece: 'shipment-gateway-piece',
  bagAdvanceSearch: 'bag_advanceSearch',
  statusCode: 'status-codes',
  mawbAdvanceSearch: 'mawb_advanceSearch',
  manifestAdvanceSearch: 'manifest_advanceSearch',
  smStatusCodes: 'sm-status-codes',
  smRolesAndPermissions: 'sm-roles-and-permissions',
  shipmentGatewayMasterShipment: 'shipment-gateway-master-shipment',
};

export const pageUrl = {
  hawbSummary: '/hawb',
  hawbInformationPage: '/hawb/hawb-information',
  hawbAdvancedSearchPage: 'hawb/hawb-advanced-search',
  hawbAdvancedSearchCommodityInformationPage:
    '/hawb/hawb-advanced-search/commodity-information',
  hawbAdvancedSearchPieceInformationPage:
    'hawb/hawb-advanced-search/piece-information',
  bagSummary: '/bag',
  bagAdvancedSearchPage: 'bag/bag-advanced-search',
  bagPieceInformationPage: 'bag/bag-advanced-search/piece-information',
  mawbSummaryPage: '/mawb',
  mawbAdvancedSearchPage: 'mawb/mawb-advanced-search',
  flightInformationPage: '/mawb/mawb-advanced-search/flight-information',
  manifestSummaryPage: '/manifest',
  manifestAdvancedSearchPage: '/manifest/manifest-advanced-search',
  hawbPiecePage: '/hawb/hawb-information/piece-information',
  hawbCommodityPage: '/hawb/hawb-information/commodity-information',
  bagInformationPage: '/bag/bag-information',
  manifestInformationPage: '/manifest/manifest-information',
  accountInformationPage: '/sm-dashboard/account/account-information',
  subAccountInformationPage:
    '/sm-dashboard/account/account-information/sub-account',
  mawbInformationPage: '/mawb/mawb-information',
  accountsSummaryPage: 'sm-dashboard/account',
  bagPiecePage: '/bag/bag-information/piece-information',
  printPreviewTable: '/print-preview',
  printPreviewInfoTable: '/info-print-preview',
  flightInfoPage: '/mawb/mawb-information/flight-information',
};

export const AttachmentDownloadRequestType = {
  hawb: 'HAWB',
  piece: 'HAWB_PIECE',
  commodity: 'HAWB_COMMODITIES',
  bag: 'BAG',
  manifest: 'MANIFEST',
  mawb: 'MAWB',
  bagExt: 'Bag_Ext',
  mawbInfo: 'MAWB Information',
  mawbs: 'MAWBS',
  role: 'Role',
  shipment: 'Shipment',
  mawbFlight: 'MAWB_FLIGHT',
};

export const validationErrorMessages = {
  originDestination: 'Origin and Destination should not be the same',
  autocompleteError: 'Invalid value entered',
  accountAutoCompleteError: 'Invalid Account entered',
  manifestAlreadyAdded: 'Bag already in the Manifest.',
  pieceAlreadyPresent: 'Piece already in the bag',
  invalidMawb: 'Invalid MAWB entered',
  invalidFlight: 'Invalid Flight Number entered',
  pieceinShipment: 'Piece_Ext already added to the shipment',
  manifestAfterSearch: 'No Manifests matched your search',
  manifestBeforeSearch: 'Please perform a search to view Manifests',
  pieceBagPackageError:
    'Invalid Packaging Type. Please select a valid packaging type from the following options: B Box, C Crate, G Gaylord, O Other, A Pallet.',
  bagAfterSearch: 'No Bags matched your search',
  bagBeforeSearch: 'Please perform a search to view Bags',
  piecePackageError:
    'Invalid Packaging Type. Please select a valid packaging type from the following options: B Box, C Crate, L Letter, M Metal Film Can, O Other, P IBC Pak, T Tube, A Pallet.',
  resetHawb: 'Reset Shipment Information',
  hawbAfterSearch: 'No Shipments matched your search',
  hawbBeforeSearch: 'Please perform a search to view Shipments',
  minHawbWeightError: 'Please enter value less than to Max Shipment weight',
  maxHawbWeightError: 'Please enter value more than to Min Shipment Weight',
  minBagWeightError: 'Please enter value less than to Max Bag weight',
  maxBagWeightError: 'Please enter value more than to Min Bag Weight',
  minHawbValueError: 'Please enter value less than to Max Shipment Value',
  maxHawbValueError: 'Please enter value more than to Min Shipment Value',
  tenCharactersReq: '10 characters required',
  manifestAlreadyPresent: 'Manifest already in the MAWB',
  name: 'name',
  clearanceTypeError:
    'Invalid Clearance type. Please select a valid Clearance type from the following options: C - CFS, E - ECCF and 6 - CFS Type 86',
  publishCriteriaMismatchError:
    'MAWB cannot be published due to the following issues: Clearance agent not assigned, no flights linked, and Pieces from a blocked shipment are present in the linked Manifest',
  publishPartialSelectedMismatchError: (invalidCount: number) =>
    `${invalidCount} MAWBs do not fulfill the publish criteria. Would you like to still proceed with publishing the remaining MAWBs?`,
  publishPartialSelectedSingleMismatchError: (invalidCount: number) =>
    `${invalidCount} MAWB do not fulfill the publish criteria. Would you like to still proceed with publishing the remaining MAWB?`,
  publishClearanceAgent: 'MAWB cannot be published without a Clearance Agent',
  publishNoFlights: 'MAWB cannot be published without flight',
  publishShipmentBlocked:
    'MAWB cannot be published due to pieces from a blocked shipment in the linked Manifest',
  publishSelectedAllFailed:
    'None of the selected MAWBs meet the publish criteria',
  updateMawb: 'Update Mawb?',
  updateShipments: 'Update Shipments?',
  updateManifest: 'Update Manifest?',
  uploadFileTypeExtension: 'Upload only .csv, .pdf and .xls file types',
  fileSizeLimitMessage: 'File size exceeds the maximum limit of 25 MB',
  otherCriteria: ' and other criteria',
  hawbRoleAlreadyExists: (role: string) => `Role ${role} already exists`,
  mawbDateValidation: `Please ensure the date remains within the allowed range of 1 month +/- from MAWB's created date`,
  manifestDateValidation:
    'Please ensure the date remains within the allowed range of 1 month +/- from Manifest created date',
  DeleteRolesHawb: (messageType: string, count: number) =>
    `Do you want to delete "${messageType}" from these ${count} Shipments?`,
  hawbShipperConsignee: (messageType: string, count: number) =>
    ` ${messageType} deleted successfully from the Shipper & Consignee tab and Roles tab of ${count} Shipments`,
  hawbRestRoles: (messageType: string, count: number) =>
    ` ${messageType} deleted successfully from the Roles tab of ${count} Shipments`,
};

export const MasterLookup = {
  agentInfo: 'agentInfo',
  clearanceType: 'clearanceType',
  airlineCode: 'airlineCode',
  serviceTypeGroups: 'serviceTypeGroups',
  customerId: 'customerId',
  customerGroup: 'customerGroup',
  origin: 'origin',
  destination: 'destination',
  serviceType: 'serviceType',
  packaging: 'packaging',
  finalFacility: 'finalFacility',
  noApiControl: 'originIdVal',
  packagingTypeName: 'packagingTypeName',
  packagingType: 'packagingType',
  clearanceTypeVal: 'clearanceTypeVal',
  clearanceTypeCode: 'clearanceTypeCode',
  originID: 'originID',
  destinationID: 'destinationID',
  airlineName: 'airlineName',
  agentName: 'agentName',
  cargoOperatorName: 'cargoOperatorName',
  firstUsAirportName: 'firstUsAirportName',
  airportCode: 'airportCode',
  countries: 'countries',
  attachmentType: 'attachmentType',
  comparisonError: 'comparisonError',
  minHawbValue: 'minHawbValue',
  maxHawbValue: 'maxHawbValue',
  maxBagWeight: 'maxBagWeight',
  minBagWeight: 'minBagWeight',
  hawbFinalDestination: 'hawbFinalDestination',
  hawbFinalDestinationId: 'hawbFinalDestinationId',
  hawbRoleTypes: 'hawb-roleTypes',
  statusCode: 'statusCodes',
};

export const searchDataParam = {
  id: 'id',
  summary: 'summary',
  desc: 'desc',
  hawbExt: 'hawbExt',
  summaryInfo: 'summary-info',
  hawbId: 'hawbId',
  manifestId: 'manifestId',
  bagExt: 'bagExt',
  information: 'information',
  basicSearch: 'basicSearch',
  bagId: 'bagId',
  mawbExt: 'mawbExt',
  mawbId: 'mawbId',
  flightInformation: 'flight-information',
  asc: 'asc',
  storedUrl: 'storedUrl',
  previousShipmentStatus: 'previousShipmentStatus',
  refreshPaginationProps: 'refreshPaginationProps',
  PieceList: 'PieceList',
};

export const shipmentStatus = {
  block: 'blocked',
  unblock: 'unblocked',
};

export const manifestFlightTootlip = {
  noFlight: 'Flight can be added to the Manifest',
};

export const WidgetValues = {
  noOfBags: 'noOfBags',
  noOfHawbs: 'noOfHawbs',
  totalWeight: 'totalWeight',
  totalNoOfPieces: 'totalNoOfPieces',
  totalValue: 'totalValue',
  noOfManifests: 'noOfManifests',
  noOfFlights: 'noOfFlights',
  lbUnit: ' lb',
  emptyValue: '--',
  singleZero: '0',
  multipleZero: '0.00',
  location: 'location',
  bagLength: 2,
};

export const GridColumnsStorageNames = {
  hawb: 'hawbDisplayColumns',
  bag: 'bagDisplayColumns',
  manifest: 'manifestDisplayColumns',
  mawb: 'mawbDisplayColumns',
  mawbManifest: 'mawbManifestDisplayColumns',
  mawbFlight: 'mawbFlightDisplayColumns',
  agent: 'agentDisplayColumns',
  agentFacility: 'agentFacilityDisplayColumns',
  shipmentCommodity: 'shipmentCommodity',
  bagPiece: 'bagDisplayColumns',
  account: 'accountDisplayColumns',
  subAccount: 'subAccountDisplayColumns',
  shipmentGateway: 'shipmentGatewayColumns',
  shipmentGatewayPiece: 'shipmentGatewayPieceDisplayColumns',
  statusCode: 'statusCodeDisplayColumns',
  smStatusCodes: 'smStatusCodeDisplayColumns',
  smRolesAndPermissions: 'smRolesAndPermissionsDisplayColumns',
  shipmentGatewayMasterShipment: 'gatewayMasterShipmentColumn',
};

export const keyStrokes = {
  enter: 'Enter',
};

export const actionIcons = {
  save: 'saveIcon',
  filter: 'filterIcon',
  search: 'searchIcon',
};

export const OverlayPositions = {
  top: 'top' as const,
  end: 'end' as const,
  center: 'center' as const,
};

export const BasicSearchPlaceholder = {
  infoIconText:
    'In the basic search functionality, entering a single value will trigger a search across all nine criteria',
  infoText: ' separated by space',
  placeholderText:
    'Search by Shipment, Piece, Agent Shipment, Customer Reference, Bag, MAWB, Manifest, Piece and Bag Barcodes',
  agents: 'Search by Agent Status, Code, Name, Type and Facility',
  accounts:
    'Search by Organization Name, Account Number or Name, Sub - Account Number or Name',
  statusCodes:
    'Search by Status Code Type, Status Code, Applicable For and Description',
  rolesAndPermissions: 'Search by Group, Department, Role, Facility, User',
};

export const gridMessages = {
  noSearch: 'Please perform a search to view',
  noMawbMatch: (itemName: string) => `No ${itemName} matched your search`,
  noRecentSearch: 'No recent search is performed',
  noSavedSearch: 'No search is saved',
};

export const AccordionTabs = {
  Roles: 'Roles',
  ShipperConsginee: 'Shipper & Consignee',
  Miscellaneous: 'Miscellaneous',
  Events: 'Events',
  Attachments: 'Attachments',
  Details: 'Details',
  Notes: 'Notes',
  Shipments: 'Shipments',
  Bags: 'Bags',
  Manifests: 'Manifests',
  Mawbs: 'Mawbs',
  SpecialSearches: 'Specialsearches',
  StatusCodes: 'Statuscodes',
  Criteria: 'Criteria',
  StatusCodeTitle: 'Status Codes',
  mawbmiscellaneous: 'MAWB Miscellaneous',
  Route: 'Route',
  ShipperConsgineeTitle: 'Shipperconsignee',
};

export const DataTypes = {
  String: 'string',
  Object: 'object',
  Number: 'number',
};

export const HttpConstSignatures = {
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Get: 'GET',
  json: 'json',
};

export const TableHeaderValues = {
  addCom: 1,
  view: 2,
  edit: 3,
  delete: 4,
  save: 5,
  close: 6,
  add: 1,
};

export const ScrollData = {
  smooth: 'smooth',
  auto: 'auto',
};
export const selectedIdForSummaryTable = {
  Mawb: 'mawbId',
  Agent: 'agentId',
  Hawb: 'hawbId',
  Manifest: 'manifestId',
  Bag: 'bagId',
  Account: 'accountNumber',
  Id: 'id',
  FlightId: 'flightId',
  Piece: 'pieceNumber',
  Commodity: 'commodityNumber',
  SmStatusCodes: 'statusCodeType',
  SmRolesAndPermissions: 'group',
};

export const selectionTableNames = {
  Mawb: 'Mawb',
  MawbManifest: 'Mawb-Manifest',
  MawbFlight: 'Mawb-Flight',
  AgentFacility: 'agent-information',
  hawb: 'hawb',
  ShipmentCommodity: 'shipment-commodity',
  SubAccount: 'sub-account',
  ShipmentPiece: 'shipment-piece',
  ShipmentGateway: 'shipment-gateway',
  ManifestBag: 'manifest-bag',
  BagPiece: 'bag-piece',
  StatusCodes: 'status-codes',
};

export const InputRowKeys = {
  Mawb: 'mawb',
  MawbManifest: 'manifestNumber',
  MawbFlight: 'airlineCode',
  Agent: 'agentCode',
  AgentFacility: 'facilityCode',
  Hawb: 'hawbId',
  ManifestNo: 'manifestNo',
  Bag: 'bagId',
  Account: 'accountNumber',
  hawbExt: 'hawbExt',
  ManifestBag: 'bagExt',
  pieceExt: 'pieceExt',
  smStatusCodes: 'statusCodeType',
  smRolesAndPermissions: 'group',
};

export const TableProps = {
  select: 'select',
  action: 'action',
  customize: 'customize',
  inputRow: 'isInputRow',
};

export const fileFormats = {
  csv: 'csv',
  xlsx: 'xlsx',
};

export const fileFormatsType = {
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const dateTimeFormat = {
  dateTime12hours: 'MM-dd-yyyy h:mm:ss a',
  dateTime24hours: 'MM-dd-yyyy_HH-mm-ss',
  dateTime24hoursWithSpace: 'MM-dd-yyyy HH:mm:ss',
  onlyDate: 'MM-dd-yyyy',
  yearMonthDateFormat: 'yyyy-MM-dd',
};

export const editMessageTemplate = {
  editMessage: (count: number, itemType: string) =>
    `Would you like to edit ${count} ${itemType} at once?`,
};

export const setupAndMaintenanceMessages = {
  view_more: 'View More',
};
export const FetchUrlById = {
  hawbId: 'hawbIds/',
};

export const AttachmentMessages = {
  removeAttachment: 'Remove Attachment',
  removeAtatchmentMessage: `Are you sure you want to remove the attachment ?`,
  deleteAttachment: 'Attachment(s) deleted  successfully',
  AddAttachment: 'Attachment added successfully',
};
export const SvgIconFrom = {
  page: 'Page',
};

export const MessageTemplates = {
  AllItemsSelected: (itemType: string, itemCount: number) =>
    `All ${itemCount} ${itemType} on this page are selected.`,
  SelectAllItems: (itemType: string, totalItemCount: number) =>
    `Select all ${totalItemCount} ${itemType}`,
};

export const popupWidth = {
  downloadPopup: '380px',
  searchPopup: '500px',
  deletePopup: '360px',
  hawbDelete: '400px',
  advancedSearchPopup: '1100px',
  printPopup: '900px',
  gatewayPopup: '95vw',
  warningPopup: '340px',
  gatewayLocationConfirmationPopup: '416px',
};

export const hyperlinkLabels = {
  mawb: 'MAWB',
  agent: 'Code',
  hawb: 'Shipment',
  manifest: 'Manifest Number',
  bag: 'Bag',
  account: 'Account Number',
  smRolesAndPermissions: 'Group',
};

export const LocalDataStorage = {
  FlightList: 'FlightList',
};

export const ShipmentsMiscellaneousMessages = {
  ResidentialHeader: 'Residential',
  Residential:
    '(Indicates that the Shipment is to be delivered at the Residence of the Consignee)',
  SignatureRequiredHeader: 'Signature Required',
  SignatureRequired:
    '(Indicates that the Delivery Agent should capture the Signature of the Consignee upon Delivery of the Shipment)',
};

export const ElementRefContainerNames = {
  container: 'container',
  panel: 'panel',
  mainContainer: 'maincontainer',
};

export const AttachmentsIconsOrder = [1, 5, 6, 4];
export const ShipmentIconsOrder = [1, 9, 10, 2, 3, 4, 5, 6, 7, 8];

export const fileNameExtensions = ['pdf', 'csv', 'xls'];
export const fileMaximumSize = 25;

export const IconsOrder = {
  add: 1,
  view: 2,
  edit: 3,
  delete: 4,
  save: 5,
  close: 6,
  downloadFile: 7,
  print: 8,
  blockShipment: 9,
  unblock: 10,
};

export const downloadInfoPageSectionKeys = {
  mawbInfo: 'mawbInfo',
  mawbmiscellaneous: 'mawbmiscellaneous',
  notes: 'notes',
  events: 'events',
  attachments: 'attachments',
  details: 'details',
  mawbManifests: 'mawbManifests',
  mawbFlights: 'mawbFlights',
  manifestInfo: 'manifestInfo',
  manifestsBag: 'manifestsBags',
  widgets: 'widgets',
  blockUnblock: 'blockUnblock',
  hawbInfo: 'hawbInfo',
  hawbPieces: 'hawbPieces',
  hawbCommodity: 'hawbCommodity',
  hawbRoles: 'hawbRoles',
  hawbMiscellaneous: 'hawbMiscellaneous',
  hawbStatusCode: 'hawbStatusCode',
  hawbConsignee: 'hawbConsignee',
  hawbShipper: 'hawbShipper',
  bagInfo: 'bagInfo',
  bagCriteria: 'bagCriteria',
  bagMiscellaneous: 'bagMiscellaneous',
  bagPieces: 'bagPieces',
};

export const downloadInfoPageSectionHeaders = {
  mawbInfo: 'MAWB Information',
  mawbmiscellaneous: 'MAWB Miscellaneous',
  notes: 'Notes',
  events: 'Events',
  attachments: 'Attachments',
  details: 'Details',
  mawbManifests: 'Manifest(s) linked',
  mawbFlights: 'Flight(s) linked',
  manifestInfo: 'Manifest Information',
  manifestBag: 'Bag(s) linked',
  widgets: 'Widgets',
  blockUnblock: 'Block or Unblock',
  hawbInfo: 'Shipment Information',
  hawbPieces: 'Piece(s)',
  hawbCommodity: 'Commodity(s)',
  hawbRoles: 'Roles',
  hawbMiscellaneous: 'Shipment Miscellaneous',
  hawbStatusCode: 'Status Codes',
  hawbConsignee: 'Consignee',
  hawbShipper: 'Shipper',
  bagInfo: 'Bag Information',
  bagMiscellaneous: 'Miscellaneous',
  bagCriteria: 'Criteria',
  bagPieces: 'Piece(s) linked',
};

export const RefreshRelatedData = {
  refreshBagPaginationProps: 'refreshBagPaginationProps',
  manifestBulkEditData: 'manifestBulkEditData',
  refreshManifestPaginationProps: 'refreshManifestPaginationProps',
  bagBulkEditData: 'bagBulkEditData',
  hawbBulkEditData: 'hawbBulkEditData',
};

export const AttachmentType = {
  CommercialInvoice: 'Commercial Invoices',
  Others: 'Others',
};

export const AttachmentTypeFlag = {
  CustomerInvoice: 'r',
  Others: 'o',
};
export const RolesType = {
  consignee: 'Consignee',
  shipper: 'Shipper',
  agentInfo: 'Agent-Info',
  address: 'Address',
  account: 'account',
  accountBillingAddress: 'accountBillingAddress',
  other: 'Other',
};

export const RolesTypeId = {
  C: 'C',
  S: 'S',
};

export const countryCodes = {
  usa: 'USA',
  canada: 'CAN',
  brazil: 'BRA',
};

export const matClasses = {
  matExpansionIndicator: 'mat-expansion-indicator',
};

export const dynamicClassNames = {
  bagCrudClass: 'bag-crud-container',
  shipmentCrudClass: 'shipment-crud-container',
  mawbCrudClass: 'mawb-crud-container',
  flightCrudClass: 'flight-crud-container',
  manifestCrudClass: 'manifest-crud-container',
};

export const formFieldIds = {
  manifestDate: 'manifestDate',
  shipmentBasicSearch: 'shipmentBasicSearchFields',
  dateAndTimeZone: 'dateandTimeZone',
  bagFlightNumber: 'bagFlightNumber',
  maxBagWeight: 'maxBagWeight',
  maxShipmentValue: 'maxShipmentValue',
  manifestMawbAirline: 'manifestMawbAirline',
  manifestAirlineFlight: 'manifestAirlineFlight',
  username: 'username',
  attachments: 'attachments',
  addtionalDetails: 'additionalDetails',
  codeAndDescription: 'codeAndDescription',
  dateTimeAndTimezone: 'dateTimeAndTimezone',
};

export const StaticSymbols = {
  dollar: '$',
  dash: '-',
  colon: ':',
  lbUnit: ' lb',
};

export const matFormFieldsTypes = {
  input: 'input',
  select: 'select',
  date: 'datepicker',
};

export const BasicSearchValues = {
  pin: 'Pin',
  advancedSearch: 'Advanced Search',
  hamburger: 'Hamburger',
  helperText: 'Helper Text',
};

export const formFieldTypes = {
  input: 'input',
  datePicker: 'datepicker',
  select: 'select',
};

export const AddDeleteRequestType = {
  Hawb: 'HAWB',
  Hawb_Piece: 'HAWB_PIECE',
  Hawb_Commodities: 'HAWB_COMMODITIES',
  Bag: 'BAG',
  Manifest: 'MANIFEST',
  Mawb: 'MAWBS',
  mawbFlight: 'MAWB_FLIGHT',
};

export const breadcrumbNames = {
  Shipment: 'Shipment',
  Bag: 'Bag',
  Manifest: 'Manifest',
  Account: 'Account',
  MAWB: 'MAWB',
  Flight: 'Flight',
  AccountInfo: 'Account Information',
  SubAccountInfo: 'Sub - Account Information',
  StatusCodes: 'Status Code',
  RolesAndPermissions: 'Roles & Permissions',
};

export const requestTypeIdCheck = {
  id: 'id',
  object: 'object',
};

export const LabelsAndPlaceHolders = {
  variousValues: 'Various Values',
};

export const BulkValidationMessages = {
  HawbValueRangeError:
    'Shipment Value must be between {minValues} and {maxValues}, and min value cannot be greater than max value.',
  HawbMinMaxError:
    'Shipment Value: Minimum value cannot be greater than maximum value.',
  BagWeightRangeError:
    'Bag Weight must be between {minWeight} and {maxWeight}, and min weight cannot be greater than max weight.',
  BagWeightMinMaxError:
    'Bag Weight: Minimum weight cannot be greater than maximum weight.',
};

export const AdvanceSearchBulkEditConsingeeShipper = [
  'id',
  'type',
  'action',
  'roleType',
  'address',
  'country',
];

export const FlightMawbPart = {
  A: 'A',
};

export const MawbPartValuesForFlight = ['A', 'B', 'C'];

export const ShipmentGatewayConstants = {
  details: 'Details',
  consignee: 'Consignee',
  shipper: 'Shipper',
  route: 'Route',
  recievedAt: 'Received at',
  manifestedTo: 'Manifested to',
};

export const ButtonText = {
  editShipment: 'Edit Shipment',
  cancel: 'Cancel',
  save: 'Save',
  createNewBag: 'Create New Bag',
  gateway: 'Gateway',
};

export const tooltipTexts = {
  gatewayScan: 'Scan or type manually',
};

export const MawbManifestTooltipMessages = {
  originDestinationAndMawbDateTooltip:
    'Please fill in MAWB Origin, Destination and MAWB Date first',
  invalidManifestMessage: 'No valid Manifests to link',
  invalidManifestEntered: 'Invalid Manifest entered',
};

export const MawbFlightRemoval = [
  'airline',
  'mawbPart',
  'slacCount',
  'slacWeight',
  'arrivalDate',
  'airlineId',
  'airlineCode',
];

export const MawbFlightMandatoryCheck = [
  'airline',
  'mawbPart',
  'arrivalDate',
  'airlineId',
  'airlineCode',
];

export const rolesOptionalFields = [
  'state',
  'email',
  'companyName',
  'address_2',
  'phone',
];

export const tabKeyboardEvent = new KeyboardEvent('keydown', {
  key: 'Tab',
  code: 'Tab',
  keyCode: 9,
  charCode: 0,
  which: 9,
  bubbles: true,
  cancelable: true,
});

export const NotesConstants = {
  noNotes: 'No Notes available on this date',
  noCloseDate: 'No Notes available on this date. Showing closest future note.',
  noFutureDate: 'No future notes available.',
};
