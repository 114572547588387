<div class="mailing-address-container">
  <div class="heading primary">
    <span class="ibc-heading-h7-bold-primary ng-star-inserted">
      {{ mailingAddressHeader(isFrom) }}
    </span>
  </div>
  <div class="address-container" [formGroup]="accountAddressForm">
    <mat-form-field
      *ngFor="let field of formFields"
      appearance="outline"
      class="mat-md">
      <mat-label>{{ field.label }}</mat-label>
      <div class="mat-form-field-icon-container" matSuffix>
        <app-svg-icon
          *ngIf="
            pageMode !== modeIcons.view &&
            (field.id === 'countryCode' || field.id === 'state')
          "
          [iconName]="actionIcons.lookupSearch"
          class="lookupSearch-icon cursor {{
            getLookupFieldClearIcon(field.id) ? 'set-height' : ''
          }}"
          [isStaticSvg]="true"
          (click)="
            field.id === 'state'
              ? getStateList(
                  accountAddressForm.get('countryCode')?.value?.split(' - ')[0],
                  true
                )
              : onLookupSearchIconClicked(field.id)
          "
          (keydown)="$event.stopPropagation()">
        </app-svg-icon>
        <app-svg-icon
          *ngIf="
            pageMode !== modeIcons.view && getLookupFieldClearIcon(field.id)
          "
          [iconName]="actionIcons.close"
          class="dropdown-close-icon cursor"
          [isStaticSvg]="true"
          (click)="clearLookupFieldValues(field.id)"
          (keydown)="$event.stopPropagation()">
        </app-svg-icon>
        <app-svg-icon
          *ngIf="checkControlHasError('phone') && field.id === 'phone'"
          matSuffix
          [iconName]="actionIcons.infoCircle"
          [isStaticSvg]="true"
          [isFrom]="svgIconFromList.page"
          class="cursor info-icon-error"
          (click)="fetchWidthPhone($event, 'phone')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-svg-icon
          *ngIf="checkControlHasError(field.id) && field.id !== 'phone'"
          matSuffix
          [iconName]="'infoCircle'"
          [isStaticSvg]="true"
          class="cursor info-icon-error"
          (click)="fetchWidth($event, field.id)"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
      </div>
      <input
        *ngIf="field.id === 'countryCode'"
        [id]="'accountAddressCountry' + isFrom"
        appOnOptionSelectedTabNewInput
        matInput
        autocomplete="off"
        type="text"
        formControlName="countryCode"
        [placeholder]="getAddressPlaceholder('countryCode')"
        (click)="onLookupSearchIconClicked('countryCode')"
        (keydown)="commonService.preventTypingOrPasting($event)"
        (paste)="commonService.preventTypingOrPasting($event)" />
      <input
        *ngIf="field.id === 'state'"
        [id]="'accountAddressState'"
        appOnOptionSelectedTabNewInput
        matInput
        type="text"
        formControlName="state"
        autocomplete="off"
        [placeholder]="getAddressPlaceholder('state')"
        (click)="
          getStateList(
            accountAddressForm.get('countryCode')?.value?.split(' - ')[0],
            true
          )
        "
        (keydown)="commonService.preventTypingOrPasting($event)"
        (paste)="commonService.preventTypingOrPasting($event)" />
      <input
        matInput
        *ngIf="field.id === 'phone'"
        placeholder="Phone"
        formControlName="phone"
        [placeholder]="getAddressPlaceholder('phone')"
        [appPhoneNumber]="
          accountAddressForm.get('countryCode')?.value ===
          validationCountryCode.usa
            ? true
            : false
        "
        (focusout)="dispatchAccountsAddressFormChanges()" />
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError('phone') &&
          setAddressInfoErrorTooltip['phone'] &&
          field.id === 'phone'
        "
        [tooltipWidth]="setAddressInfoWidth['phone']"
        [tooltipText]="getErrorMessage('phone')"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip('phone', setAddressInfoErrorTooltip)
        "></app-custom-error-tooltip>
      <input
        matInput
        *ngIf="
          field.id !== 'countryCode' &&
          field.id !== 'state' &&
          field.id !== 'phone'
        "
        formControlName="{{ field.id }}"
        (blur)="dispatchAccountsAddressFormChanges()" />
      <app-custom-error-tooltip
        *ngIf="
          checkControlHasError(field.id) &&
          setMailingAddressErrorTooltip[field.id] &&
          field.id !== 'phone'
        "
        [tooltipWidth]="setMailingAddressWidth[field.id]"
        [tooltipText]="fetchErrorMessages(field.id)"
        (tooltipCloseClicked)="
          commonService.closeErrorTooltip(
            field.id,
            setMailingAddressErrorTooltip
          )
        ">
      </app-custom-error-tooltip>
    </mat-form-field>
  </div>
</div>
