import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ValidatorPattern } from '../utility/validators.pattern';

@Directive({
  selector: '[appDigitWithTwoDecimalNumber]',
})
export class AppDigitWithTwoDecimalNumberDirective {
  @Input() appDigitWithTwoDecimalNumber!: number;
  public regexPattern!: RegExp;
  private readonly specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    '-',
  ];

  constructor(private readonly el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (this.appDigitWithTwoDecimalNumber === 7) {
      this.regexPattern = new RegExp(ValidatorPattern.SevenDigitTwoDecimal);
    } else if (this.appDigitWithTwoDecimalNumber === 17) {
      this.regexPattern = new RegExp(ValidatorPattern.SeventeenDigitTwoDecimal);
    } else if (this.appDigitWithTwoDecimalNumber === 2) {
      this.regexPattern = new RegExp(ValidatorPattern.TwoDigitTwoDecimal);
    }

    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (next && !String(next).match(this.regexPattern)) {
      event.preventDefault();
    }
    const checkDotFirstPosition: string = next.substring(0, 1);
    const checkSecondPosition: string = next.substring(0, 2);

    if (
      checkDotFirstPosition === '.' ||
      (next.length > 1 &&
        checkDotFirstPosition === '0' &&
        checkSecondPosition !== '0.')
    ) {
      event.preventDefault();
    }

    if (next.length > this.appDigitWithTwoDecimalNumber) {
      if (next[this.appDigitWithTwoDecimalNumber] !== '.') {
        if (!next.includes('.')) {
          event.preventDefault();
        }
      }
    }
  }
}
