<div class="government-information-container">
  <div class="form-container" [formGroup]="accountGovtInfoForm">
    <div *ngFor="let field of formFields">
      <div
        *ngIf="
          field.type === formFieldType.input ||
          field.type === formFieldType.select
        "
        class="form-field-container">
        <mat-form-field
          class="mat-md remove-subscript-wrapper"
          appearance="outline">
          <mat-icon
            matPrefix
            class="dropdown-icon"
            *ngIf="field.type === formFieldType.select">
            {{ actionIcons.keyboardDownArrow }}
          </mat-icon>
          <mat-label>{{ field.label }}</mat-label>
          <div
            *ngIf="
              field.type === formFieldType.select &&
              field.id === 'clearanceType'
            ">
            <input
              id="govtInfoClearanceType"
              appOnOptionSelectedTabNewInput
              matInput
              formControlName="clearanceType"
              [matAutocomplete]="cleranceTypeAutoComplete"
              (input)="
                dispatchBusinessFormChanges();
                formAutocompleteValueChanges(
                  'clearanceType',
                  'clearanceTypeId',
                  clearanceTypeData,
                  'govtInfoClearanceType'
                );
                onTypeSelected(
                  accountGovtInfoForm.get('clearanceType')?.value,
                  clearanceTypeData,
                  'clearanceTypeId'
                )
              " />
            <mat-autocomplete
              #cleranceTypeAutoComplete="matAutocomplete"
              (optionSelected)="
                onTypeSelected(
                  accountGovtInfoForm.get('clearanceType')?.value,
                  clearanceTypeData,
                  'clearanceTypeId'
                )
              "
              appOnOptionSelectedTabNewInput
              [controlId]="'govtInfoClearanceType'">
              <mat-option
                *ngFor="let item of filteredClearanceTypeOptions | async"
                [value]="item.name">
                {{ item.name }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <div
            *ngIf="
              field.type === formFieldType.select && field.id === 'salesPerson'
            ">
            <input
              id="govtInfoSalesPerson"
              appOnOptionSelectedTabNewInput
              matInput
              formControlName="salesPerson"
              [matAutocomplete]="salesPersonAutoComplete"
              (input)="
                dispatchBusinessFormChanges();
                formAutocompleteValueChanges(
                  'salesPerson',
                  'salesPerson',
                  filterSalesPersonOption,
                  'govtInfoSalesPerson'
                )
              " />
            <mat-autocomplete
              #salesPersonAutoComplete="matAutocomplete"
              (optionSelected)="
                onTypeSelected(
                  accountGovtInfoForm.get('salesPerson')?.value,
                  salesPersonData,
                  'salesPersonId'
                )
              "
              appOnOptionSelectedTabNewInput
              [controlId]="'govtInfoSalesPerson'">
              <mat-option
                *ngFor="let item of filterSalesPersonOption | async"
                [value]="item.name">
                {{ item.name }}
              </mat-option>
            </mat-autocomplete>
          </div>
          <input
            *ngIf="field.type === formFieldType.input"
            matInput
            formControlName="{{ field.id }}"
            (blur)="dispatchBusinessFormChanges()" />
          <app-svg-icon
            *ngIf="checkControlForError(field.id)"
            matSuffix
            [iconName]="'infoCircle'"
            [isStaticSvg]="true"
            class="cursor info-icon-error"
            (click)="fetchWidth($event, field.id)"
            (keydown)="$event.stopPropagation()">
          </app-svg-icon>
          <app-custom-error-tooltip
            *ngIf="
              checkControlForError(field.id) &&
              setGovernmentInfoErrorTooltip[field.id]
            "
            [tooltipWidth]="setGovernmentInfoWidth[field.id]"
            [tooltipText]="fetchErrorMessages(field.id)"
            (tooltipCloseClicked)="
              commonService.closeErrorTooltip(
                field.id,
                setGovernmentInfoErrorTooltip
              )
            ">
          </app-custom-error-tooltip>
        </mat-form-field>
      </div>
      <div
        *ngIf="field.type === formFieldType.multiSelectAutoComplete"
        class="chips-autocomplete-container">
        <mat-checkbox
          *ngIf="field.id === 'selectEmailsforGatewayNotification'"
          class="basic-checkbox"
          (change)="onSameAsInvoiceEmailChange($event)"
          [disabled]="pageMode === ModeIcons.view">
          Same as Invoice Email Addresses
        </mat-checkbox>

        <mat-form-field
          class="chip-autocomplete-field"
          appearance="outline"
          *ngIf="field.id === 'selectEmailsForInvoiceNotification'">
          <mat-label>{{ field.label }}</mat-label>
          <mat-chip-grid #chipGrid1 aria-label="email selection">
            <mat-chip-row
              *ngFor="let email of emailsForInvoiceNotifications; let i = index"
              (removed)="removeForInvoiceEmail(email)">
              {{ email }}
              <button matChipRemove [attr.aria-label]="'remove ' + email">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input
            placeholder="Select Email"
            #emailInput
            [formControl]="emailCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipGrid1"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addForInvoiceEmail($event)"
            [disabled]="pageMode === ModeIcons.view" />
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectedForInvoiceEmail($event)">
            <mat-option
              *ngFor="let data of filterUserInvoiceEmailData | async"
              [value]="data">
              {{ data.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field
          class="chip-autocomplete-field"
          appearance="outline"
          *ngIf="field.id === 'selectEmailsforGatewayNotification'">
          <mat-label>{{ field.label }}</mat-label>
          <mat-chip-grid #chipGrid2 aria-label="email selection">
            <mat-chip-row
              *ngFor="let email of emailsForGatewayNotifications; let i = index"
              (removed)="removeForGatewayEmail(email)">
              {{ email }}
              <button matChipRemove [attr.aria-label]="'remove ' + email">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>
          <input
            placeholder="Select Email"
            #emailInput
            [formControl]="emailCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipGrid2"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addForGatewayEmail($event)"
            [disabled]="pageMode === ModeIcons.view" />
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectedForGatewayEmail($event)">
            <mat-option
              *ngFor="let data of filterUserGatewayEmailData | async"
              [value]="data">
              {{ data.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="customer-service-grid">
    <div class="table-tabs-container">
      <div class="table-tabs-header">
        <div class="table-tabs-title ibc-heading-h7-bold-subtitle">
          <span class="table-tab-name">{{ headerText.CustomerService }}</span>
        </div>
        <div class="tab-icons">
          <app-svg-icon
            [list]="customerServiceTableHeaderIcons"
            class="icon-section"
            (iconClicked)="onCustomerServiceIconClicked($event)"></app-svg-icon>
        </div>
      </div>
    </div>
    <app-add-inline-edit-table
      [inlineCrudTableCustomizeConfig]="customerServiceTableColumnConfig"
      [formConfig]="customerServiceFormConfig"
      [isFrom]="tableName.customerService"
      (inlineCrudTableFormValue)="customerServiceGridFormData = $event"
      (inlineTableSelectionChange)="
        onCustomerServiceTableSelectionChange($event)
      "
      [pageMode]="pageMode">
    </app-add-inline-edit-table>
  </div>
</div>
