import { Action, State, StateContext } from '@ngxs/store';
import {
  FetchCbpRequestStateModel,
  FetchCbpResponseStateModel,
  FetchFullmanFileStateModel,
} from '../fullman-upload.model';
import {
  FetchCbpRequestStatusData,
  FetchCbpResponseStatusData,
  FetchFullmanFileData,
  ResetCbpRequestList,
  ResetCbpResponseList,
  ResetFullmanFileList,
} from './fullman-upload.action';
import { updateState } from 'src/app/shared/utility/store.utility';
import { Injectable } from '@angular/core';

@Injectable()
@State<FetchFullmanFileStateModel>({
  name: 'fullmanFileList',
  defaults: {
    fullman: [],
    totalData: 0,
  },
})
export class FullmanFileListState {
  @Action(FetchFullmanFileData)
  FetchFullmanFileData(
    ctx: StateContext<FetchFullmanFileStateModel>,
    action: FetchFullmanFileData
  ) {
    const payload = action.payload;
    if (payload.data?.fullman && payload.data?.totalRecords) {
      updateState(ctx, {
        fullman: payload.data.fullman,
        totalData: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        fullman: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetFullmanFileList)
  ResetFullmanFileList(ctx: StateContext<FetchFullmanFileStateModel>) {
    updateState(ctx, {
      fullman: [],
      totalData: 0,
    });
  }
}

@Injectable()
@State<FetchCbpRequestStateModel>({
  name: 'cbpRequestState',
  defaults: {
    cbpRequestData: [],
    totalRecords: 0,
  },
})
export class CbpRequestState {
  @Action(FetchCbpRequestStatusData)
  FetchCbpRequestStatusData(
    ctx: StateContext<FetchCbpRequestStateModel>,
    action: FetchCbpRequestStatusData
  ) {
    const payload = action.payload;
    if (payload.data.cbpRequestData && payload.data.totalRecords) {
      updateState(ctx, {
        cbpRequestData: payload.data.cbpRequestData,
        totalRecords: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        cbpRequestData: [],
        totalRecords: 0,
      });
    }
  }
  @Action(ResetCbpRequestList)
  ResetCbpRequestList(ctx: StateContext<FetchCbpRequestStateModel>) {
    updateState(ctx, {
      cbpRequestData: [],
      totalRecords: 0,
    });
  }
}

@Injectable()
@State<FetchCbpResponseStateModel>({
  name: 'cbpResponseState',
  defaults: {
    cbpRequestData: [],
    totalRecords: 0,
  },
})
export class CbpResponseState {
  @Action(FetchCbpResponseStatusData)
  FetchCbpResponseStatusData(
    ctx: StateContext<FetchCbpResponseStateModel>,
    action: FetchCbpResponseStatusData
  ) {
    const payload = action.payload;
    if (payload.data.cbpResponseData && payload.data.totalRecords) {
      updateState(ctx, {
        cbpRequestData: payload.data.cbpResponseData,
        totalRecords: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        cbpRequestData: [],
        totalRecords: 0,
      });
    }
  }
  @Action(ResetCbpResponseList)
  ResetCbpResponseList(stateContext: StateContext<FetchCbpResponseStateModel>) {
    updateState(stateContext, {
      cbpRequestData: [],
      totalRecords: 0,
    });
  }
}
