<div class="customer-management-container" [formGroup]="customerManagementForm">
  <div class="toggle-container">
    <span class="padding-right"> Needs no clearance </span>
    <section class="custom-translation-toggle">
      <mat-slide-toggle
        #customToggle
        class="custom-translation-toggle"
        hideIcon="true"
        color="primary"
        formControlName="noNeedClearance"
        checked="false"
        (change)="noClearanceToggleChanges($event)">
      </mat-slide-toggle>
    </section>
  </div>
  <div class="form-container">
    <div *ngFor="let field of formFields">
      <div *ngIf="field?.id === 'clearanceType'">
        <mat-form-field
          appearance="outline"
          class="mat-md custom-select remove-subscript-wrapper clearance-type-form-field">
          <mat-label>{{ field?.label }}</mat-label>
          <mat-select
            multiple
            formControlName="clearanceType"
            (selectionChange)="onSelectionClearanceTypeChange($event)">
            <mat-option
              [value]="item.id"
              *ngFor="let item of clearanceTypeData">
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-icon matPrefix class="dropdown-icon"
            >keyboard_arrow_down</mat-icon
          >
          <app-svg-icon
            *ngIf="
              selectedClearanceType.length > 0 &&
              !customerManagementForm.disabled
            "
            matSuffix
            [iconName]="icons.close"
            class="dropdown-close-icon"
            [isStaticSvg]="true"
            (click)="clearClearanceType()"
            (keydown)="$event.stopPropagation()">
          </app-svg-icon>
        </mat-form-field>
      </div>
      <div *ngIf="field?.id === 'clearanceChannel'">
        <mat-form-field
          appearance="outline"
          class="mat-md custom-select remove-subscript-wrapper clearance-type-form-field">
          <mat-label>{{ field?.label }}</mat-label>
          <mat-select
            multiple
            formControlName="clearanceChannel"
            (selectionChange)="onSelectionclearanceChannelChange($event)">
            <mat-option
              [value]="item.id"
              *ngFor="let item of filteredClearanceChannels">
              {{ item.name }}
            </mat-option>
          </mat-select>
          <mat-icon matPrefix class="dropdown-icon"
            >keyboard_arrow_down</mat-icon
          >
          <app-svg-icon
            *ngIf="
              selectedClearanceChannel.length > 0 &&
              !customerManagementForm.disabled
            "
            matSuffix
            [iconName]="icons.close"
            class="dropdown-close-icon"
            [isStaticSvg]="true"
            (click)="clearclearanceChannel()"
            (keydown)="$event.stopPropagation()">
          </app-svg-icon>
        </mat-form-field>
      </div>
      <div *ngIf="field?.id === 'scanPreference'">
        <mat-form-field
          appearance="outline"
          class="mat-md custom-select remove-subscript-wrapper clearance-type-form-field">
          <mat-select
            formControlName="scanPreference"
            (selectionChange)="onscanPreferenceChange($event)">
            <mat-option
              *ngFor="let options of scanPreferenceData"
              [value]="options.name">
              <span tabindex="-1" (keydown)="$event.stopPropagation()">
                {{ options.name }}
              </span>
            </mat-option>
          </mat-select>
          <mat-label>{{ field.label }}</mat-label>
          <mat-icon matPrefix class="dropdown-icon"
            >keyboard_arrow_down</mat-icon
          >
          <app-svg-icon
            *ngIf="checkControlHasError(field.id)"
            matSuffix
            [iconName]="'infoCircle'"
            [isStaticSvg]="true"
            [isFrom]="'Page'"
            class="cursor info-error-icon"
            (click)="fetchWidth($event, field.id)"
            (keydown)="$event.stopPropagation()">
          </app-svg-icon>
          <app-custom-error-tooltip
            *ngIf="
              checkControlHasError(field.id) &&
              setCustomerManagementErrorTooltip[field.id]
            "
            [tooltipWidth]="setCustomerManagementWidth[field.id]"
            [tooltipText]="getErrorMessage(field.id)"
            (tooltipCloseClicked)="
              commonService.closeErrorTooltip(
                field.id,
                setCustomerManagementErrorTooltip
              )
            ">
          </app-custom-error-tooltip>
        </mat-form-field>
      </div>
      <div *ngIf="field?.id === 'customTranslation'">
        <div class="toggle-container">
          <span class="padding-right"> Custom Translation </span>
          <section class="custom-translation-toggle">
            <mat-slide-toggle
              #customToggle
              class="custom-translation-toggle"
              hideIcon="true"
              color="primary"
              formControlName="customTranslation"
              checked="false"
              (change)="dipatchCustomerManagementFormChanges()">
            </mat-slide-toggle>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
