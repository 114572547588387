<div class="basic-search-hamburger-menu-container">
  <div class="basic-search">
    <mat-form-field
      appearance="outline"
      class="mat-md basic-search-textfield w-100">
      <mat-label>Basic Search</mat-label>
      <input
        matInput
        [placeholder]="placeholder"
        [formControl]="basicSearch"
        (input)="
          onInput($event);
          searchService.setHamburgerSaveNewButtonData(basicSearch.value || '');
          isSearchFieldHasValue.emit(true)
        " />
      <div class="basic-search-options" matSuffix>
        <div
          *ngFor="let options of inputSearchOptions"
          [appEnterSubmit]="isPageConstant">
          <app-svg-icon
            [iconName]="options.name"
            [isFrom]="'Page'"
            [isStaticSvg]="true"
            class="basicSearchInputIcons cursor {{
              options.name === searchIcon && !basicSearch.value
                ? 'disabled'
                : ''
            }} {{ options.name === searchIcon ? 'basicSearch' : '' }}"
            [matTooltip]="
              options.name === searchIcon ? searchIconTooltip : infoIconTooltip
            "
            matTooltipPosition="above"
            (click)="search(false)"
            (keydown)="$event.stopPropagation()">
          </app-svg-icon>
        </div>
      </div>
    </mat-form-field>
  </div>

  <div class="search-options" *ngIf="!hideSearchOptions">
    <div
      *ngFor="let options of searchOptions"
      class="search-option-icons {{ options.name }} {{
        getPinIconStatus(options) ? 'disabled' : 'cursor'
      }}">
      <app-svg-icon
        [iconName]="options.name"
        [isFrom]="'Page'"
        [isStaticSvg]="true"
        (click)="
          getPinIconStatus(options)
            ? $event.stopPropagation()
            : onSearchOptionsIconClicked(options, searchOverlay)
        "
        (keydown)="$event.stopPropagation()"
        [matTooltip]="getTooltipDisplayName(options.name)"
        matTooltipPosition="above">
      </app-svg-icon>
    </div>
  </div>

  <div class="list-all-results" *ngIf="hideSearchOptions">
    <button
      class="cursor ibc-primary-default-button"
      (click)="onListAllResultsClicked()">
      List All Results
    </button>
  </div>
</div>
<div #searchOverlay></div>
