import { Component, Inject, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { InteractionType } from '@azure/msal-browser';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Router } from '@angular/router';
import { pageUrl, StatusCodes } from 'src/app/shared/utility/constants';
import { UserService } from 'src/app/features/service/user.service';
import { UserProfileInfo } from 'src/app/features/components/user-profile/user-profile.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  public isOverlayOpened!: boolean;
  public userFullName!: string;
  public userFirstName!: string;
  public userIntial!: string;
  public userLocation!: string;
  public userProfileInfo!: UserProfileInfo;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly commonService: CommonService,
    @Inject(MSAL_GUARD_CONFIG)
    private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly authService: MsalService,
    private readonly sharedService: SharedService,
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly errorHandlerService: ErrorHandlerService
  ) {
    this.commonService.checkOverlayOpened
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.isOverlayOpened = data;
      });
    this.fetchUserDetailsFromApi();
    this.getUserDetails();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }

  public logout() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
    this.sharedService.clearEntireSession();
    this.sharedService.clearEntireLocalStorage();
  }

  public redirectToUserProfile() {
    this.router.navigate([pageUrl.userProfile]);
  }

  private organizeHeaderDetails(data: UserProfileInfo) {
    this.userFirstName = data.firstName;
    this.userFullName = `${data.firstName}  ${data.lastName}`;
    this.userIntial = this.userFullName.charAt(0).toUpperCase();
    this.userLocation = data.defaultLocation.name;
  }

  private fetchUserDetailsFromApi() {
    this.userService
      .fetchUserProfileDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          if (response.statusCode === StatusCodes.Success) {
            this.userService.setUserProfileDetails(response.data);
            this.userProfileInfo = response.data;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  private getUserDetails() {
    this.userService.userProfileInfo.subscribe(data => {
      if (data) {
        this.organizeHeaderDetails(data);
      }
    });
  }
}
