import { SearchService } from './../search.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  OnInit,
} from '@angular/core';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SearchOverlayService } from 'src/app/shared/services/search-overlay.service';
import {
  ActionIcons,
  basicSearchInputRegex,
  BasicSearchValues,
  ModalPopupMessages,
  pageType,
  pageUrl,
  popupWidth,
  searchDataParam,
} from 'src/app/shared/utility/constants';
import {
  BasicSearchIcons,
  BasicSearchInputIcons,
} from 'src/app/shared/utility/svg-constants';
import { SearchPopupComponent } from '../search-options/search-popup/search-popup.component';
import { FormControl } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basic-search',
  templateUrl: './basic-search.component.html',
  styleUrl: './basic-search.component.scss',
})
export class BasicSearchComponent implements OnInit, OnDestroy {
  @Input() placeholder!: string;
  @Input() isFrom!: string;
  @Input() helpTextTooltip!: string;
  @Input() hideSearchOptions!: boolean;
  @Output() isSearchClicked = new EventEmitter<object>();
  @Output() isSearchFieldHasValue = new EventEmitter<boolean>(false);
  @Output() isAdvanceSearchClicked = new EventEmitter<boolean>(false);
  public searchOptions = BasicSearchIcons;
  public inputSearchOptions = BasicSearchInputIcons;
  public isOverlayOpen = false;
  public searchIconTooltip = ActionIcons.search;
  public infoIconTooltip!: string;
  public basicSearch = new FormControl('');
  public searchIcon = ActionIcons.searchSmallCase;
  public isPageConstant = searchDataParam.basicSearch;

  private readonly isLimitReached = false;
  private clickedHyperLinkedId: any;

  constructor(
    public searchService: SearchService,
    private readonly overlayService: SearchOverlayService,
    private readonly modalService: ModalService,
    private readonly commonService: CommonService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.getHyperlinkClickedDataForSearch();
    this.getMasterShipmentClickedDataForSearch();
    this.infoIconTooltip = this.helpTextTooltip;
    this.setPatchValue();
  }

  ngOnDestroy(): void {
    this.overlayService.closeOverlay();
  }

  public onInput(event: any): void {
    if ([pageType.hawb, pageType.bag, pageType.manifest, pageType.mawb, pageType.account, pageType.rolesAndPermissions].includes(this.isFrom)) {
      const inputValue = event.target.value;
      const sanitizedValue = inputValue.replace(basicSearchInputRegex.mainModules, '');
      this.basicSearch.setValue(sanitizedValue);
    } else if (pageType.usersManagement) {
      const inputValue = event.target.value;
      const sanitizedValue = inputValue.replace(basicSearchInputRegex.usersModule, '');
      this.basicSearch.setValue(sanitizedValue);
    }
  }

  public search(isFromComp: boolean) {
    if (this.basicSearch.value) {
      this.clickedHyperLinkedId = !isFromComp
        ? undefined
        : this.clickedHyperLinkedId;
      this.isSearchClicked.emit({
        data: this.basicSearch.value,
        shipmentId: this.clickedHyperLinkedId,
      });
    }
  }

  public getPinIconStatus(options: any) {
    return (
      options.name === 'pin' && (this.isLimitReached || !this.basicSearch.value)
    );
  }

  public onSearchOptionsIconClicked(clickedIcon: any, searchOverlay: any) {
    if (clickedIcon.name === ActionIcons.pinSearch) {
      this.pinSearchPopup();
    } else if (clickedIcon.name === ActionIcons.advanceSearch) {
      if (this.isFrom === pageType.hawb) {
        this.isAdvanceSearchClicked.emit(true);
        this.router.navigate([pageUrl.hawbAdvancedSearchPage]);
      }
      if (this.isFrom === pageType.bag) {
        this.isAdvanceSearchClicked.emit(true);
        this.router.navigate([pageUrl.bagAdvancedSearchPage]);
      }
      if (this.isFrom === pageType.mawb) {
        this.isAdvanceSearchClicked.emit(true);
        this.router.navigate([pageUrl.mawbAdvancedSearchPage]);
      }
      if (this.isFrom === pageType.manifest) {
        this.isAdvanceSearchClicked.emit(true);
        this.router.navigate([pageUrl.manifestAdvancedSearchPage]);
      }
    } else if (clickedIcon.name === ActionIcons.hamburger) {
      this.toggleOverlay(searchOverlay);
    }
  }

  public getTooltipDisplayName(optionName: string): string {
    const displayNames: { [key: string]: string } = {
      pin: BasicSearchValues.pin,
      advancedSearch: BasicSearchValues.advancedSearch,
      hamburger: BasicSearchValues.hamburger,
    };
    return displayNames[optionName] || optionName;
  }

  private pinSearchPopup() {
    const dataForPopup = {
      data: {
        searchName: this.basicSearch.value,
        description: '',
        searchId: 0,
        isFrom: this.isFrom,
        isBasicSearch: true
      },
    };
    this.modalService.openPopup(
      dataForPopup,
      ModalPopupMessages.pinSearch,
      SearchPopupComponent,
      popupWidth.searchPopup
    );
  }

  private toggleOverlay(searchOverlay?: any) {
    if (this.isOverlayOpen) {
      this.overlayService.closeOverlay();
    } else {
      this.overlayService.openSearchOverlay(searchOverlay, this.isFrom);
    }
    this.isOverlayOpen = !this.isOverlayOpen;
  }

  private getHyperlinkClickedDataForSearch() {
    this.searchService.clickedHyperlinkData.subscribe(clickedData => {
      if (clickedData) {
        this.overlayService.closeOverlay();
        this.basicSearch.setValue(clickedData.item?.savedSearchName);
        this.isOverlayOpen = clickedData.isOpenOverlay;
        this.search(true);
      }
    });
  }

  private getMasterShipmentClickedDataForSearch() {
    this.commonService.isSetMasterShipmentValue.subscribe(shipmentData => {
      if (Object.keys(shipmentData).length) {
        this.basicSearch.setValue(shipmentData.shipmentExt);
        this.clickedHyperLinkedId = shipmentData.shipmentId;
        this.search(true);
      }
    });
  }

  private setPatchValue() {
    this.commonService.patchSearchText.subscribe(patchData => {
      if (patchData) {
        this.basicSearch.setValue(patchData);
        setTimeout(() => {
          this.commonService.setSearchText('');
        }, 50);
      }
    });
  }
}
