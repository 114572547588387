import { FormConfig } from 'src/app/shared/models/dynamic.model';

export interface Widget {
  id: number;
  title: string;
  value: string;
  extraClasses: string;
  iconName: string;
  name: string;
  controlName?: string;
}

export interface PieceGridColumnData {
  id: string;
  pieceExt: string;
  pieceNumber: string | number;
  weight: string | number;
  dimensions: string;
  packagingType: string;
  hawbExt: string | number;
  isInputRow?: boolean;
  piece?: any;
  checked?: boolean;
}

export interface ComponentMapping {
  title: string;
  componentType: any;
}

export const miscellaneousFormfieldsConfig: FormConfig = {
  fields: [
    {
      id: 'tareWeight',
      type: 'input',
      label: 'Tare Weight(lb)',
    },
  ],
};

export interface bagsGridColumnData {
  bagId: number;
  date: string;
  weight: number;
  packagingType: string;
  noofPieces: number;
}

export interface IBagSummaryGridResult {
  statusCode: number;
  message: string;
  data: bagSummaryGridData;
}
export interface IBagDeleteResult {
  statusCode: number;
  message: string;
  data: any;
  code: number;
  statusCodes: number;
  status?: number;
}

export interface bagSummaryGridData {
  totalRecords: number;
  sortingOrder: string;
  sortingField: string;
  bag: bagColumnData[];
}

export interface bagColumnData {
  bagId: number;
  date: string;
  weight: number;
  packagingType: string;
  noofPieces: number;
  checked?: boolean;
  bagExt?: string;
}

export interface TableColumns {
  key: string;
  label: string;
  value: string;
  isSelected: boolean;
  defaultView: boolean;
}

export interface FieldOption {
  label: string;
  value: string;
}

export interface IDropdownResponse {
  id: number;
  name: string;
}

export interface IBagEventData {
  id: string;
  date: string;
  time: string;
  zone: string;
  name: string;
  info: string;
}

/* Bag Information Page */
export interface IBagInformationData {
  bagId: number;
  bagNumber: string;
  hawbId: number;
  origin: string;
  destination: string;
  packagingType: number;
  manifestNumber: string;
  flightNumber: string;
  description: string;
  notes: IBagNotesData;
  attachmentList: IBagAttachmentData[];
  miscellaneous: IBagMiscellaneousData;
  eventsList: IBagEventData[];
  pieceList: IBagLinkedPieceData[];
}

export interface IBagNotesData {
  ibc: IBagNotesDetails[];
  customer: IBagNotesDetails[];
}

export interface IBagNotesDetails {
  id: string;
  comments: string;
  action: string;
  userId: number;
  userName: string;
}

export interface IBagAttachmentData {
  id: any;
  type: string;
  fileName: string;
  signedUrl: string;
  action: string;
  attachmentTypeValuId: string;
  isFromScreen?: string;
}

export interface IBagMiscellaneousData {
  tareWeight?: string;
}

export interface IBagMiscellaneousStateModel {
  miscellaneous: IBagMiscellaneousData;
}

export interface IBagLinkedPieceData {
  id: string;
  action: string;
  bag?: any;
}

/* Bag Information Page - Get Piece Data by piece ext */
export interface IBagPieceInformationSuccessResponse {
  statusCode: number;
  message: string;
  data: IPieceList;
  status?: number;
}

export interface IPieceList {
  pieceList: IBagPieceListData[];
}

export interface IPieceStateModel {
  pieceList: IBagPieceData[];
}

export interface IBagPieceListData {
  piece: IBagPieceData;
  notes: IBagNotesData;
  attachments: IBagAttachmentData[];
  id?: any;
  piecesBag?: any;
  source?: any;
}

export interface IBagPieceData {
  id: string;
  pieceExt: string;
  pieceNumber: string;
  noofpieces: string;
  barcode: string;
  bag: string;
  weight: string;
  value: string;
  dimLength: string;
  dimWidth: string;
  dimHeight: string;
  packagingType: string;
  hawbId: string;
  hawbExt: string;
  agent: string;
  serviceType: string;
  customerId: string;
  customerGroupId: string;
  isInputRow?: boolean;
  dimensions?: string;
  source?: string;
}

export interface IBagLinkPieces {
  id: string;
  action: string;
}

export interface bagInfoResetStateModel {
  data: IBagInformationData;
}

export interface BagSearchPayload {
  bagExt: string;
  originId: string;
  destinationId: string;
  packagingType: string;
  manifestNumber: string;
  flightNumber: string;
  searchType: string;
  paging: {
    fromIndex: number;
    toIndex: number;
    sortingOrder: string;
    sortingField: string;
  };
  data?: any;
}

export interface DropdownList {
  id: number;
  name: string;
}

export interface IDropdownListResult {
  statusCode: number;
  message: string;
  data: DropdownList[];
}

export interface IBagInformationSearchPayload {
  bagId?: number;
  bagExt: any;
  searchType: string;
  paging: {
    fromIndex: number;
    toIndex: number;
    sortingOrder?: string;
    sortingField?: string;
  };
}

export interface bagInformationListData {
  totalRecords: number;
  sortingOrder: string;
  sortingField: string;
  bag: IBagInformationData[];
}

export interface IBagInformationListResult {
  statusCode: number;
  message: string;
  data: bagInformationListData;
}

export const defaultNotesData: IBagNotesData = {
  ibc: [],
  customer: [],
};

export const defaultIBagPieceData: any = {
  id: '',
  pieceExt: '',
  pieceNumber: '',
  noofpieces: '',
  barcode: '',
  bag: '',
  weight: '',
  value: '',
  dimLength: '',
  dimWidth: '',
  dimHeight: '',
  packagingType: '',
  hawbId: '',
  agent: '',
  serviceType: '',
  customerId: '',
  customerGroupId: '',
  isInputRow: false,
  dimensions: '',
  notes: defaultNotesData,
  action: '',
  attachments: [],
  piece: {},
  tempPieceId: 0,
};

export const defaultBagMiscellaneousData: IBagMiscellaneousData = {
  tareWeight: '',
};

export const defaultBagInformation: IBagInformationData = {
  bagId: 0,
  bagNumber: '',
  hawbId: 0,
  origin: '',
  destination: '',
  packagingType: 0,
  manifestNumber: '',
  flightNumber: '',
  description: '',
  notes: defaultNotesData,
  attachmentList: [],
  miscellaneous: defaultBagMiscellaneousData,
  pieceList: [],
  eventsList: [],
};

export interface IBagInfoData {
  bagId: number;
  bagNumber: string;
  hawbId: number;
  origin: string;
  destination: string;
  packagingType: number;
  manifestNumber: string;
  flightNumber: string;
  description: string;
  airline: string;
};

export const defaultBagInfo: IBagInfoData = {
  bagId: 0,
  bagNumber: '',
  hawbId: 0,
  origin: '',
  destination: '',
  packagingType: 0,
  manifestNumber: '',
  flightNumber: '',
  description: '',
  airline: '',
};

export const deafultPieceList: IBagPieceListData = {
  piece: defaultIBagPieceData,
  notes: defaultNotesData,
  attachments: [],
};

export interface IBagDeleteResult {
  statusCode: number;
  message: string;
  data: any;
  code: number;
  statusCodes: number;
  status?: number;
}

export interface PieceBagList {
  id: string;
  pieceExt: string;
  pieceNumber: string;
  noofpieces: string;
  barcode: string;
  bag: string;
  weight: string;
  value: string;
  dimLength: string;
  dimWidth: string;
  dimHeight: string;
  packagingType: string;
  hawbId: string;
  agent: string;
  serviceType: string;
  customerId: string;
  customerGroupId: string;
  isInputRow?: boolean;
  dimensions?: string;
  notes: IBagNotesData;
  action: string;
  attachments: IBagAttachmentData;
  piece?: {};
  tempPieceId: number;
}

export const defaultPieceList: PieceBagList = {
  ...defaultIBagPieceData,
  pieceExt: '',
  pieceNumber: '',
  noofpieces: '',
  barcode: '',
  bag: '',
  weight: '',
  value: '',
  dimLength: '',
  dimWidth: '',
  dimHeight: '',
  packagingType: '',
  hawbId: '',
  agent: '',
  serviceType: '',
  customerId: '',
  customerGroupId: '',
  isInputRow: false,
  dimensions: '',
  notes: defaultNotesData,
  action: '',
  attachments: [],
  tempPieceId: 0,
};
