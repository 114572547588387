import { MatPaginatorIntl } from '@angular/material/paginator';

export class CustomPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();

    // Customize the labels and text here
    this.itemsPerPageLabel = 'Items per page:';
    this.nextPageLabel = 'Next Page';
    this.previousPageLabel = 'Previous Page';
    this.firstPageLabel = 'First Page';
    this.lastPageLabel = 'Last Page';
    this.getRangeLabel = this.getCustomRangeLabel;
  }

  getCustomRangeLabel(page: number, pageSize: number, length: number): string {
    const startIndex = page * pageSize + 1;
    return `${startIndex} of ${length}`;
  }
}
