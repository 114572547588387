import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  defaultPieceModal,
  PieceModalStateModel,
} from '../advanced-search-piece-information/advanced-search-piece-information.config';
import {
  UpdatePieceModal,
  ResetPieceModal,
  ResetAttachmentModal,
  UpdateAttachmentModal,
  ResetDetailModal,
  UpdateDetailModal,
  ResetCommodityModal,
  UpdateCommodityModal,
  AddOrUpdateRoleModal,
  ResetRoleModals,
  AddOrUpdateNoteModal,
  ResetNoteModals,
  ResetMiscellaneousModal,
  UpdateMiscellaneousModal,
  ResetCrudModal,
  UpdateCrudModal,
  ResetBagsModal,
  UpdateBagsModal,
  ResetManifestsModal,
  UpdateManifestsModal,
  ResetMawbsModal,
  UpdateMawbsModal,
  ResetStatusModal,
  UpdateStatusModal,
  ResetConsigneeModal,
  UpdateConsigneeModal,
  UpdateResidential,
  UpdateSignatureReq,
  ResetMiscState,
  ResetAllModals,
  ResetWidgetFormValues,
  UpdateWidgetFormValues,
} from './advance-search.action';
import { AttachmentModalStateModel } from '../advanced-search-attachments/advance-search-attachment.config';
import {
  DetailModalStateModel,
  defaultState,
  defaultDetailsModal,
} from '../advanced-search-details/advance-search-details.config';
import {
  CommodityModalStateModel,
  defaultCommodityModal,
  defaultCommodityState,
} from '../advanced-search-commodity/advanced-search-commodity.config';
import {
  defaultRoleModal,
  defaultRoleState,
  RoleModalStateModel,
} from '../advanced-search-roles/advanced-search-roles.config';
import {
  NoteModalStateModel,
  defaultNoteState,
} from '../advanced-search-notes/advance-search-notes.config';
import {
  MiscellaneousModalStateModel,
  defaultMiscellaneousModal,
  defaultMiscellaneousState,
} from '../advanced-search-commodity-information/miscellaneous/miscellaneous-advance-search.config';
import {
  CrudModalStateModel,
  defaultCrudModal,
  defaultCrudState,
} from '../crud/crud.config';
import {
  BagModalStateModel,
  defaultBagState,
  defaultBagsModal,
} from '../advanced-search-bags/advanced-search-bags.config';
import {
  ManifestModalStateModel,
  defaultManifestState,
  defaultManifestsModal,
} from '../advanced-search-manifests/advanced-search-manifests.config';
import {
  MawbModalStateModel,
  defaultMawbState,
  defaultMawbsModal,
} from '../advanced-search-mawbs/advanced-search-mawbs.config';
import {
  defaultStatusModal,
  defaultStatusState,
  StatusModalStateModel,
} from '../advanced-search-statuscodes/advanced-search-statuscodes.config';
import {
  ConsigneeModalStateModel,
  defaultConsigneeState,
} from '../advanced-search-shipperconsignee/advanced-search-shipperconsignee.config';
import {
  MainMiscStateModel,
  defaultMiscState,
} from '../../hawb-miscellaneous/hawb-miscellaneous.config';
import {
  defaultHawbAdvanceState,
  WidgetFORMDEFAULTS,
} from 'src/app/shared/models/dynamic.model';
import { WidgetStateModel } from 'src/app/features/model/hawb.model';

@State<PieceModalStateModel>({
  name: 'pieceModal',
  defaults: {
    pieceModal: defaultPieceModal,
  },
})
@Injectable()
export class PieceModalState {
  // Action to update piece modal values
  @Action(UpdatePieceModal)
  updatePieceModal(
    ctx: StateContext<PieceModalStateModel>,
    { payload }: UpdatePieceModal
  ) {
    const state = ctx.getState();
    // Update the state with the new values
    ctx.setState({
      ...state,
      pieceModal: {
        ...state.pieceModal,
        ...payload, // Merge new values into the existing pieceModal object
      },
    });
  }

  // Action to reset piece modal values to default
  @Action(ResetPieceModal)
  resetPieceModal(ctx: StateContext<PieceModalStateModel>) {
    // Reset the state to the default values
    ctx.setState({
      pieceModal: defaultPieceModal,
    });
  }
}

@State<AttachmentModalStateModel>({
  name: 'attachmentModal',
  defaults: {
    attachmentModals: [], // Initialize as an empty array
  },
})
@Injectable()
export class AttachmentAdvanceSearchModalState {
  // Update or add attachment logic
  @Action(UpdateAttachmentModal)
  updateAttachmentModal(
    ctx: StateContext<AttachmentModalStateModel>,
    { payload }: UpdateAttachmentModal
  ) {
    const state = ctx.getState();
    const existingIndex = state.attachmentModals.findIndex(
      attachment => attachment.isFromScreen === payload.isFromScreen
    );

    if (existingIndex > -1) {
      const updatedAttachments = [...state.attachmentModals];
      updatedAttachments[existingIndex] = { ...payload };
      ctx.setState({
        ...state,
        attachmentModals: updatedAttachments,
      });
    } else {
      ctx.setState({
        ...state,
        attachmentModals: [...state.attachmentModals, payload],
      });
    }
  }

  // Reset action
  @Action(ResetAttachmentModal)
  resetAttachmentModal(ctx: StateContext<AttachmentModalStateModel>) {
    ctx.setState({
      ...ctx.getState(),
      attachmentModals: [], // Reset the array to empty
    });
  }
}

@State<DetailModalStateModel>({
  name: 'detailModal',
  defaults: defaultState,
})
@Injectable()
export class DetailAdvanceModalState {
  // Action to update the details
  @Action(UpdateDetailModal)
  updateDetailModal(
    ctx: StateContext<DetailModalStateModel>,
    action: UpdateDetailModal
  ) {
    const state = ctx.getState();
    const updatedDetails = { ...state.detailModal, ...action.payload };
    ctx.patchState({ detailModal: updatedDetails });
  }

  // Action to reset the details to the default values
  @Action(ResetDetailModal)
  resetDetailModal(ctx: StateContext<DetailModalStateModel>) {
    ctx.patchState({ detailModal: defaultDetailsModal });
  }
}

@State<CommodityModalStateModel>({
  name: 'commodityModal',
  defaults: defaultCommodityState,
})
@Injectable()
export class CommodityAdvanceModalState {
  // Selector to get the current commodity modal
  @Selector()
  static getCommodityModal(state: CommodityModalStateModel) {
    return state.commodityModal;
  }

  // Action to update the commodity modal
  @Action(UpdateCommodityModal)
  updateCommodityModal(
    ctx: StateContext<CommodityModalStateModel>,
    action: UpdateCommodityModal
  ) {
    const state = ctx.getState();
    const updatedCommodity = { ...state.commodityModal, ...action.payload };
    ctx.patchState({ commodityModal: updatedCommodity });
  }

  // Action to reset the commodity modal to default values
  @Action(ResetCommodityModal)
  resetCommodityModal(ctx: StateContext<CommodityModalStateModel>) {
    ctx.patchState({ commodityModal: defaultCommodityModal });
  }
}
@State<RoleModalStateModel>({
  name: 'roleModal',
  defaults: defaultRoleState,
})
@Injectable()
export class RoleAdvanceModalState {
  @Action(AddOrUpdateRoleModal)
  addOrUpdateRoleModal(
    ctx: StateContext<RoleModalStateModel>,
    action: AddOrUpdateRoleModal
  ) {
    const state = ctx.getState();
    const existingIndex = state.roleModals.findIndex(
      modal => modal.isFromScreen === action.payload.isFromScreen
    );

    if (existingIndex > -1) {
      // If an existing RoleModal with the same `isFromScreen` is found, update it
      const updatedRoleModals = [...state.roleModals];
      updatedRoleModals[existingIndex] = {
        ...updatedRoleModals[existingIndex],
        ...action.payload,
      };
      ctx.patchState({
        roleModals: updatedRoleModals,
      });
    } else {
      // If no match is found, add the new RoleModal
      ctx.patchState({
        roleModals: [...state.roleModals, action.payload],
      });
    }
  }

  // Action to reset all RoleModals
  @Action(ResetRoleModals)
  resetRoleModals(ctx: StateContext<RoleModalStateModel>) {
    ctx.patchState({
      roleModals: [],
    });
  }
}

@State<NoteModalStateModel>({
  name: 'noteModal',
  defaults: defaultNoteState,
})
@Injectable()
export class NoteAdvanceModalState {
  // Action to add or update the NoteModal based on `isFromScreen`
  @Action(AddOrUpdateNoteModal)
  addOrUpdateNoteModal(
    ctx: StateContext<NoteModalStateModel>,
    action: AddOrUpdateNoteModal
  ) {
    const state = ctx.getState();
    const existingIndex = state.noteModals.findIndex(
      modal => modal.isFromScreen === action.payload.isFromScreen
    );

    if (existingIndex > -1) {
      // If an existing NoteModal with the same `isFromScreen` is found, update it
      const updatedNoteModals = [...state.noteModals];
      updatedNoteModals[existingIndex] = {
        ...updatedNoteModals[existingIndex],
        ...action.payload,
      };
      ctx.patchState({
        noteModals: updatedNoteModals,
      });
    } else {
      // If no match is found, add the new NoteModal
      ctx.patchState({
        noteModals: [...state.noteModals, action.payload],
      });
    }
  }

  // Action to reset all NoteModals
  @Action(ResetNoteModals)
  resetNoteModals(ctx: StateContext<NoteModalStateModel>) {
    ctx.patchState({
      noteModals: [],
    });
  }
}

@State<MiscellaneousModalStateModel>({
  name: 'miscellaneousModal',
  defaults: defaultMiscellaneousState,
})
@Injectable()
export class MiscellaneousAdvanceModalState {
  // Action to update the MiscellaneousModal
  @Action(UpdateMiscellaneousModal)
  updateMiscellaneousModal(
    ctx: StateContext<MiscellaneousModalStateModel>,
    action: UpdateMiscellaneousModal
  ) {
    ctx.patchState({
      miscellaneousModal: {
        ...ctx.getState().miscellaneousModal,
        ...action.payload,
      },
    });
  }

  // Action to reset the MiscellaneousModal to default state
  @Action(ResetMiscellaneousModal)
  resetMiscellaneousModal(ctx: StateContext<MiscellaneousModalStateModel>) {
    ctx.patchState({
      miscellaneousModal: defaultMiscellaneousModal,
    });
  }
}

@State<CrudModalStateModel>({
  name: 'crudModal',
  defaults: defaultCrudState,
})
@Injectable()
export class CrudAdvanceModalState {
  // Action to update the CrudModal
  @Action(UpdateCrudModal)
  updateCrudModal(
    ctx: StateContext<CrudModalStateModel>,
    action: UpdateCrudModal
  ) {
    const state = ctx.getState();
    ctx.patchState({
      crudModal: { ...state.crudModal, ...action.payload },
    });
  }

  // Action to reset the CrudModal to default state
  @Action(ResetCrudModal)
  resetCrudModal(ctx: StateContext<CrudModalStateModel>) {
    ctx.patchState({
      crudModal: defaultCrudModal,
    });
  }
}

@State<BagModalStateModel>({
  name: 'bagsModal',
  defaults: defaultBagState,
})
@Injectable()
export class BagAdvanceModalState {
  // Action to update the BagsModal
  @Action(UpdateBagsModal)
  updateBagsModal(
    ctx: StateContext<BagModalStateModel>,
    action: UpdateBagsModal
  ) {
    const state = ctx.getState();
    ctx.patchState({
      detailModal: { ...state.detailModal, ...action.payload },
    });
  }

  // Action to reset the BagsModal to default state
  @Action(ResetBagsModal)
  resetBagsModal(ctx: StateContext<BagModalStateModel>) {
    ctx.patchState({
      detailModal: defaultBagsModal,
    });
  }
}

@State<ManifestModalStateModel>({
  name: 'manifestModal',
  defaults: defaultManifestState,
})
@Injectable()
export class ManifestAdvanceModalState {
  // Action to update the ManifestsModal
  @Action(UpdateManifestsModal)
  updateManifestModal(
    ctx: StateContext<ManifestModalStateModel>,
    action: UpdateManifestsModal
  ) {
    const state = ctx.getState();
    ctx.patchState({
      manifestModal: { ...state.manifestModal, ...action.payload },
    });
  }

  // Action to reset the ManifestsModal to default state
  @Action(ResetManifestsModal)
  resetManifestModal(ctx: StateContext<ManifestModalStateModel>) {
    ctx.patchState({
      manifestModal: defaultManifestsModal,
    });
  }
}

@State<MawbModalStateModel>({
  name: 'mawbModal',
  defaults: defaultMawbState,
})
@Injectable()
export class MawbAdvanceModalState {
  // Action to update the MawbsModal
  @Action(UpdateMawbsModal)
  updateMawbModal(
    ctx: StateContext<MawbModalStateModel>,
    action: UpdateMawbsModal
  ) {
    const state = ctx.getState();
    ctx.patchState({
      mawbModal: { ...state.mawbModal, ...action.payload },
    });
  }

  // Action to reset the MawbsModal to default state
  @Action(ResetMawbsModal)
  resetMawbModal(ctx: StateContext<MawbModalStateModel>) {
    ctx.patchState({
      mawbModal: defaultMawbsModal,
    });
  }
}

@State<StatusModalStateModel>({
  name: 'statusModal',
  defaults: defaultStatusState,
})
@Injectable()
export class StatusAdvanceModalState {
  // Action to update the StatusModal
  @Action(UpdateStatusModal)
  updateStatusModal(
    ctx: StateContext<StatusModalStateModel>,
    action: UpdateStatusModal
  ) {
    const state = ctx.getState();
    ctx.patchState({
      statusModal: { ...state.statusModal, ...action.payload },
    });
  }

  // Action to reset the StatusModal to default state
  @Action(ResetStatusModal)
  resetStatusModal(ctx: StateContext<StatusModalStateModel>) {
    ctx.patchState({
      statusModal: defaultStatusModal,
    });
  }
}

@State<ConsigneeModalStateModel>({
  name: 'consigneeModal',
  defaults: defaultConsigneeState,
})
@Injectable()
export class ConsigneeAdvanceModalState {
  // Action to update the RoleModal
  @Action(UpdateConsigneeModal)
  updateConsigneeModal(
    ctx: StateContext<ConsigneeModalStateModel>,
    action: UpdateConsigneeModal
  ) {
    const state = ctx.getState();
    ctx.patchState({
      consigneeModals: { ...state.consigneeModals, ...action.payload },
    });
  }

  // Action to reset the RoleModal to default state
  @Action(ResetConsigneeModal)
  resetConsigneeModal(ctx: StateContext<ConsigneeModalStateModel>) {
    ctx.patchState({
      consigneeModals: defaultRoleModal,
    });
  }
}

@State<MainMiscStateModel>({
  name: 'miscState',
  defaults: defaultMiscState,
})
export class MiscAdvanceState {
  // Action handler to update the residential field
  @Action(UpdateResidential)
  updateResidential(
    ctx: StateContext<MainMiscStateModel>,
    action: UpdateResidential
  ) {
    const state = ctx.getState();
    ctx.patchState({
      detailMiscModal: {
        ...state.detailMiscModal,
        residential: action.payload,
      },
    });
  }

  // Action handler to update the signature_req field
  @Action(UpdateSignatureReq)
  updateSignatureReq(
    ctx: StateContext<MainMiscStateModel>,
    action: UpdateSignatureReq
  ) {
    const state = ctx.getState();
    ctx.patchState({
      detailMiscModal: {
        ...state.detailMiscModal,
        signature_req: action.payload,
      },
    });
  }

  @Action(ResetMiscState)
  resetMiscState(ctx: StateContext<MainMiscStateModel>) {
    ctx.setState(defaultMiscState);
  }
}

@State<any>({
  name: 'resetState',
  defaults: defaultHawbAdvanceState,
})
@Injectable()
export class ResetHawbAdvanceSearchState {
  @Action(ResetAllModals)
  resetAllModals(ctx: StateContext<any>) {
    ctx.dispatch([
      new ResetPieceModal(),
      new ResetAttachmentModal(),
      new ResetDetailModal(),
      new ResetCommodityModal(),
      new ResetRoleModals(),
      new ResetNoteModals(),
      new ResetMiscellaneousModal(),
      new ResetCrudModal(),
      new ResetBagsModal(),
      new ResetManifestsModal(),
      new ResetMawbsModal(),
      new ResetStatusModal(),
      new ResetConsigneeModal(),
      new ResetMiscState(),
      new ResetWidgetFormValues(),
    ]);
  }
}

@State<WidgetStateModel>({
  name: 'widgetform',
  defaults: WidgetFORMDEFAULTS,
})
export class WidgetAdvanceState {
  @Action(UpdateWidgetFormValues)
  updateFormValues(
    ctx: StateContext<WidgetStateModel>,
    action: UpdateWidgetFormValues
  ) {
    ctx.patchState(action.payload);
  }

  @Action(ResetWidgetFormValues)
  resetFormValues(ctx: StateContext<WidgetStateModel>) {
    ctx.patchState(WidgetFORMDEFAULTS);
  }
}

