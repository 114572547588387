<!-- <style>
  :host {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 8px 0;
  }

  p {
    margin: 0;
  }

  .content {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    align-items: center;
    background: gainsboro;
    position: relative;
    min-height: 100%;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: blue;
    height: 100%;
    margin-top: 100px;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
  }

  .button {
    background: gray;
    color: white;
    height: 40px;
    width: 80px;
  }
  .displayData {
    display: block;
  }
</style> -->
<button id="logout" (click)="logout()" [hidden]="!isLogout">Logout</button>
<div *ngIf="isSpinnerLoad" class="spinner-container">
  <mat-spinner [diameter]="50"> </mat-spinner>
</div>
<app-layout *ngIf="!isForPrintPreview"></app-layout>
<router-outlet *ngIf="isForPrintPreview"></router-outlet>
<!-- <div class="content">
  <div class="card-container">
    <h1>Welcome to pactrakTNG</h1>
    <button class="button" (click)="getUser()">Test API</button>
    <button class="button" (click)="clear()">Clear Data</button>
    <button (click)="addcustomer()" mat-raised-button color="primary">
      Popup
    </button>
  </div>
  <div class="displayData">
    <h3>{{ userInfo }}</h3>
  </div>
</div> -->
