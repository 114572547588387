import {
  IManifestDetailsData,
  IManifestInformationData,
  IManifestRequiredBagData,
  ManifestSearchPayload,
} from 'src/app/features/model/manifest.model';

// Manifest Details Section
export class SaveManifestDetails {
  static readonly type = '[Details] Manifest Save';
  constructor(public payload: IManifestDetailsData) {}
}

export class ResetManifestDetails {
  static readonly type = '[Details] Manifest Reset';
}

//Bag table
export class LinkBagToManifest {
  static readonly type = '[Bag] Link To Manifest';
  constructor(public payload: IManifestRequiredBagData[]) {}
}

export class UnlinkBagFromManifest {
  static readonly type = '[Bag] Unlink From Manifest';
  constructor(public payload: string) {}
}

export class ResetBagList {
  static readonly type = '[Manifest Bag] Reset';
}

export class FetchOrigins {
  static readonly type = '[Manifests] Set Origins';
  constructor(public origins: any[]) {}
}

export class FetchDestinations {
  static readonly type = '[Manifests] Set Destinations';
  constructor(public destinations: any[]) {}
}

export class ResetManifestsList {
  static readonly type = '[Manifests] Reset Manifests List';
}

export class FetchManifestsList {
  static readonly type = '[Manifests] List';
  constructor(public payload: ManifestSearchPayload) {}
}
export class FetchManifestsInformationListSuccess {
  static readonly type = '[Manifest] Fetch Manifests Information List Success';
  constructor(public bags: IManifestInformationData[]) {}
}

export class FetchSelectedManifestById {
  static readonly type = '[Manifests] Fetch Selected Manifest By Id';
  constructor(public manifestId: number) {}
}

export class ResetManifestsInformationList {
  static readonly type = '[Manifests] Reset Manifests Information List';
}

export class FetchManifestDropdowns {
  static readonly type = '[Manifests] Fetch Dropdowns';
  constructor(public payload: any) {}
}