import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  MawbColumnsData,
  defaultMawbInfoData,
  FlightStateModel,
  IManifestStateModel,
  IMawbDetailsStateModel,
  IMawbManifestListData,
  IMawbMiscellaneousStateModel,
  IFlightMiscellaneousData,
  IMawbInformationData,
  MawbDropdownList,
} from 'src/app/features/model/mawb.model';
import {
  FetchMawbsList,
  RemoveUnsavedFlightMiscellaneous,
  ResetFlightMiscellaneous,
  ResetMawbsList,
  SaveFlightMiscellaneous,
  AddFlight,
  AddMawbManifest,
  DeleteFlight,
  DeleteMawbManifest,
  LinkManifestToMawb,
  ResetFlight,
  SaveMawbDetails,
  SaveMawbMiscellaneous,
  UpdateFlight,
  ResetMawbManifestList,
  UnlinkManifestFromMawb,
  FetchMawbsInformationListSuccess,
  FetchSelectedMawbById,
  ResetMawbsInformationList,
  ResetMawbManifests,
  FetchMawbDropdowns,
} from './mawb.action';
import { defaultMawbMiscellaneousData } from '../mawb.config';

export interface MawbsListStateModel {
  mawbs: MawbColumnsData[];
  totalData: number;
}

@Injectable()
@State<MawbsListStateModel>({
  name: 'mawbs',
  defaults: {
    mawbs: [],
    totalData: 0,
  },
})
export class MawbsListState {
  @Action(FetchMawbsList)
  FetchMawbsList(
    ctx: StateContext<MawbsListStateModel>,
    action: FetchMawbsList
  ) {
    const state = ctx.getState();
    const payload = action.payload;
    if (payload.data?.mawb && payload.data?.totalRecords) {
      ctx.setState({
        ...state,
        mawbs: payload.data.mawb,
        totalData: payload.data.totalRecords,
      });
    } else {
      ctx.setState({
        ...state,
        mawbs: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetMawbsList)
  ResetMawbsList(ctx: StateContext<MawbsListStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mawbs: [],
      totalData: 0,
    });
  }
}

@Injectable()
@State<IMawbDetailsStateModel>({
  name: 'mawbInfo',
  defaults: {
    mawbInfo: defaultMawbInfoData(),
  },
})
export class MawbDetailsState {
  @Action(SaveMawbDetails)
  saveMawbInfoData(
    ctx: StateContext<IMawbDetailsStateModel>,
    action: SaveMawbDetails
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mawbInfo: action.payload,
    });
  }
}

@Injectable()
@State<IMawbMiscellaneousStateModel>({
  name: 'mawbMiscellaneous',
  defaults: {
    miscellaneous: defaultMawbMiscellaneousData(),
  },
})
export class MawbMiscellaneousState {
  @Action(SaveMawbMiscellaneous)
  saveMawbMiscellaneousData(
    ctx: StateContext<IMawbMiscellaneousStateModel>,
    action: SaveMawbMiscellaneous
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      miscellaneous: action.payload,
    });
  }
}

@State<IManifestStateModel>({
  name: 'mawbManifestList',
  defaults: {
    manifestList: [],
  },
})
export class MawbManifestState {
  @Action(LinkManifestToMawb)
  linkManifestData(
    ctx: StateContext<IManifestStateModel>,
    action: LinkManifestToMawb
  ) {
    const state = ctx.getState();
    const updatedManifestList = [...state.manifestList, ...action.payload];
    ctx.setState({
      ...state,
      manifestList: updatedManifestList,
    });
  }

  @Action(UnlinkManifestFromMawb)
  unlinkMawbData(ctx: StateContext<IManifestStateModel>, { payload }: any) {
    const state = ctx.getState();
    const filteredManifest = state.manifestList.filter(
      linkedManifest => linkedManifest.id !== payload
    );
    ctx.setState({
      ...state,
      manifestList: filteredManifest,
    });
  }

  @Action(ResetMawbManifestList)
  resetManifestMawbData(ctx: StateContext<IManifestStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      manifestList: [],
    });
  }
}

@Injectable()
@State<FlightStateModel>({
  name: 'flight',
  defaults: {
    flights: [],
  },
})
export class FlightState {
  @Action(AddFlight)
  addFlight(ctx: StateContext<FlightStateModel>, { payload }: AddFlight) {
    const state = ctx.getState();
    if (payload && typeof payload === 'object' && !Array.isArray(payload)) {
      ctx.setState({
        ...state,
        flights: [...state.flights, payload],
      });
    }
  }

  @Action(UpdateFlight)
  updateFlight(ctx: StateContext<FlightStateModel>, { payload }: UpdateFlight) {
    const state = ctx.getState();
    const updatedFlights = state.flights.map(flight =>
      flight.id === payload.id ? { ...flight, ...payload } : flight
    );
    ctx.setState({
      ...state,
      flights: updatedFlights,
    });
  }

  @Action(DeleteFlight)
  deleteFlight(ctx: StateContext<FlightStateModel>, { payload }: DeleteFlight) {
    const state = ctx.getState();
    const filteredFlights = state.flights.filter(
      flight => flight.id !== payload
    );
    ctx.setState({
      ...state,
      flights: filteredFlights,
    });
  }

  @Action(ResetFlight)
  resetFlight({ setState }: StateContext<FlightStateModel>) {
    setState({
      flights: [],
    });
  }
}
export interface ManifestsMawbStateModel {
  manifestsMawb: IMawbManifestListData[];
}

@Injectable()
@State<ManifestsMawbStateModel>({
  name: 'manifestMawb',
  defaults: {
    manifestsMawb: [],
  },
})
export class ManifestsMawbState {
  @Action(AddMawbManifest)
  addMawbManifests(
    ctx: StateContext<ManifestsMawbStateModel>,
    { payload }: AddMawbManifest
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      manifestsMawb: [...state.manifestsMawb, ...payload],
    });
  }

  @Action(DeleteMawbManifest)
  deleteMawbManifest(
    ctx: StateContext<ManifestsMawbStateModel>,
    { payload }: DeleteMawbManifest
  ) {
    const state = ctx.getState();
    const filteredPieces = state.manifestsMawb.filter(manifest =>
      manifest.id ? manifest.id !== payload : manifest.manifest.id !== payload
    );
    ctx.setState({
      ...state,
      manifestsMawb: filteredPieces,
    });
  }

  @Action(ResetMawbManifests)
  resetMawbManifests(ctx: StateContext<ManifestsMawbStateModel>) {
    ctx.setState({
      manifestsMawb: [],
    });
  }
}
export class IFlightMiscellaneousStateModel {
  flightMiscellaneous: IFlightMiscellaneousData[] = [];
}

@State<IFlightMiscellaneousStateModel>({
  name: 'flightMiscellaneous',
  defaults: {
    flightMiscellaneous: [],
  },
})
@Injectable()
export class FlightMiscellaneousState {
  @Action(SaveFlightMiscellaneous)
  updateForm(
    ctx: StateContext<IFlightMiscellaneousStateModel>,
    { payload }: SaveFlightMiscellaneous
  ) {
    const state = ctx.getState();
    const updatedMiscellaneous = [...state.flightMiscellaneous];
    const existingIndex = updatedMiscellaneous.findIndex(
      misc => misc.id === payload.id
    );
    if (existingIndex !== -1) {
      updatedMiscellaneous[existingIndex] = payload;
    } else {
      updatedMiscellaneous.push(payload);
    }
    ctx.setState({
      ...state,
      flightMiscellaneous: updatedMiscellaneous,
    });
  }

  @Action(RemoveUnsavedFlightMiscellaneous)
  removeUnsavedMiscellaneousCommodities(
    ctx: StateContext<IFlightMiscellaneousStateModel>
  ) {
    const state = ctx.getState();
    const updatedMiscellaneous = state.flightMiscellaneous.filter(
      misc => misc.isSavedData
    );
    ctx.setState({
      ...state,
      flightMiscellaneous: updatedMiscellaneous,
    });
  }

  @Action(ResetFlightMiscellaneous)
  resetMiscellaneousCommodity(
    ctx: StateContext<IFlightMiscellaneousStateModel>
  ) {
    ctx.setState({
      flightMiscellaneous: [],
    });
  }
}

// // Mawb Crud
export interface MawbInformationStateModel {
  mawbInformation: IMawbInformationData;
  mawbsInformationList: IMawbInformationData[];
}
@State<MawbInformationStateModel>({
  name: 'mawbInformation',
  defaults: {
    mawbInformation: {} as IMawbInformationData,
    mawbsInformationList: [],
  },
})
@Injectable()
export class MawbInformationState {
  @Action(FetchMawbsInformationListSuccess)
  fetchMawbsInformationListSuccess(
    { getState, setState }: StateContext<MawbInformationStateModel>,
    { mawbs }: FetchMawbsInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      mawbsInformationList: mawbs,
    });
  }

  @Action(FetchSelectedMawbById)
  FetchSelectedMawbById(
    ctx: StateContext<MawbInformationStateModel>,
    action: FetchSelectedMawbById
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mawbInformation: state.mawbsInformationList.filter(
        element => element.mawbId == action.mawbId
      )[0],
    });
  }

  @Action(ResetMawbsInformationList)
  resetMawbInfo(ctx: StateContext<MawbInformationStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      mawbInformation: {} as IMawbInformationData,
      mawbsInformationList: [],
    });
  }
}

@Injectable()
@State<MawbDropdownList>({
  name: 'mawbDropdowns',
  defaults: {
    origins: [],
    destinations: [],
    clearanceTypeData: [],
    attachmentType: [],
    airlineData: [],
    agentData: [],
  },
})
export class MawbDropdownsListState {
  @Action(FetchMawbDropdowns)
  saveShipmentLookUpData(
    ctx: StateContext<MawbDropdownList>,
    action: FetchMawbDropdowns
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      origins: action.payload.origins,
      destinations: action.payload.destinations,
      clearanceTypeData: action.payload.clearanceTypeData,
      attachmentType: action.payload.attachmentType,
      airlineData: action.payload.airlineData,
      agentData: action.payload.agentData,
    });
  }
}