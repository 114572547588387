import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MaterialModule } from '../../material.module';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchModule } from '../search/search.module';
import { MatTableDataSource } from '@angular/material/table';
import { ITableProp } from '../../models/tables.model';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';
import {
  ActionIcons,
  gridMessages,
  lookupHeaders,
  lookupPluralHeaders,
} from '../../utility/constants';
import {
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';

@Component({
  selector: 'app-lookup-popup',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    MaterialModule,
    SearchModule,
    ScrollingModule,
  ],
  templateUrl: './lookup-popup.component.html',
  styleUrl: './lookup-popup.component.scss',
})
export class LookupPopupComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  public crudPopupData: any;
  public searchTextbox!: string;
  public lookupTableDataSource = new MatTableDataSource<any>([]);
  public lookupTableDisplayedColumns!: string[];
  public lookupTableColumns!: ITableProp[];
  public tableSortDirection!: string;
  public icons = ActionIcons;
  public noResultsMessage = '';

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  private readonly pageSize = 100;
  private allData: any[] = [];
  private currentIndex = this.pageSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly ref: MatDialogRef<LookupPopupComponent>,
    private readonly cdref: ChangeDetectorRef
  ) {
    this.tableSortDirection = 'desc';
  }

  ngOnInit(): void {
    this.crudPopupData = this.data;
    this.lookupTableDisplayedColumns =
      this.crudPopupData.code.displayedColumnsList;
    this.lookupTableColumns = this.crudPopupData.code.tableColumns;
    this.allData = this.crudPopupData.code.tableData;
    this.getnoMatchMessage();
    this.lookupTableDataSource.data = this.allData.slice(0, this.pageSize);
  }

  ngAfterViewInit() {
    this.getDataWithSort();
  }

  public closePopup() {
    this.ref.close(true);
  }

  public getPlaceholderText() {
    return `Search  ${this.crudPopupData.title}`;
  }

  public filterLookUpList() {
    const modifiedSearchValue =
      this.searchTextbox !== ''
        ? this.searchTextbox.replace(/[\s-]/g, '')?.toLowerCase()
        : '';
    let filteredData = this.allData;
    if (modifiedSearchValue) {
      filteredData = this.allData.filter((opt: any) =>
        opt[this.lookupTableDisplayedColumns[0]]
          .replace(/[\s-]/g, '')
          .toLowerCase()
          .includes(modifiedSearchValue)
      );
    }
    this.lookupTableDataSource.data = filteredData.slice(0, this.pageSize);
    this.currentIndex = this.pageSize;
  }

  public onSelectClicked(rowData: any) {
    this.ref.close({ popupClose: true, data: rowData });
  }

  public getMatchedRow(rowData: any) {
    return (
      rowData[this.lookupTableDisplayedColumns[0]] ===
      this.crudPopupData.code.selectedOptionData
    );
  }

  private getDataWithSort() {
    this.sort.active = this.lookupTableDisplayedColumns[0];
    this.lookupTableDataSource.sort = this.sort;
    this.cdref.detectChanges();
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.tableSortDirection = data.direction;
    });
  }

  private getnoMatchMessage() {
    if (this.crudPopupData.title === lookupHeaders.country) {
      this.noResultsMessage = gridMessages.noRecordsMatch(
        lookupPluralHeaders.countries
      );
    } else if (this.crudPopupData.title === lookupHeaders.state) {
      this.noResultsMessage = gridMessages.noRecordsMatch(
        lookupPluralHeaders.states
      );
    } else if (this.crudPopupData.title === lookupHeaders.origin) {
      this.noResultsMessage = gridMessages.noRecordsMatch(
        lookupPluralHeaders.originFacility
      );
    } else {
      this.noResultsMessage = gridMessages.noRecordsMatch(
        lookupPluralHeaders.destinationFacility
      );
    }
  }

  public onScroll() {
    if (!this.viewport.measureScrollOffset('bottom')) {
      const filteredData = this.filterDataBasedOnSearch();
      if (this.currentIndex < filteredData.length) {
        const nextData = filteredData.slice(
          this.currentIndex,
          this.currentIndex + this.pageSize
        );
        this.lookupTableDataSource.data = [
          ...this.lookupTableDataSource.data,
          ...nextData,
        ];
        this.currentIndex += this.pageSize;
      }
    }
  }

  private filterDataBasedOnSearch() {
    const modifiedSearchValue =
      this.searchTextbox !== ''
        ? this.searchTextbox?.replace(/[\s-]/g, '')?.toLowerCase()
        : '';
    return modifiedSearchValue
      ? this.allData.filter((opt: any) =>
          opt[this.lookupTableDisplayedColumns[0]]
            .replace(/[\s-]/g, '')
            .toLowerCase()
            .includes(modifiedSearchValue)
        )
      : this.allData;
  }
}
