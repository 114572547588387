import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';
import {
  ActionIcons,
  BulkDeleteItemNames,
  dateTimeFormat,
  gridMessages,
  InputRowKeys,
  MessageTemplates,
  pageType,
  popupButtons,
  selectedIdForSummaryTable,
} from 'src/app/shared/utility/constants';
import { CommonService } from 'src/app/shared/services/common.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TableService } from '../tables.service';
import { TableColumnsProp } from 'src/app/shared/models/tables.model';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckboxStateService } from 'src/app/shared/services/checkbox-state.service';
import { IBasicSearchProps } from 'src/app/shared/models/dynamic.model';
import { MatPaginator } from '@angular/material/paginator';
import { CommonTableService } from 'src/app/shared/services/common-table.service';
import { MenuOverlayService } from 'src/app/shared/services/menu-overlay.service';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrl: './summary-table.component.scss',
})
export class SummaryTableComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() summaryGridColumns!: TableColumnsProp[];
  @Input() tableData: any;
  @Input() isFrom!: string;
  @Input() dataSearched!: boolean;
  @Input() totalCount!: number;
  @Input() paginationProps!: IBasicSearchProps;
  @Input() isSelectAllMessageRequired = true;
  @Output() pageChangeEvent = new EventEmitter<unknown>();
  @Output() tableSelectionEvent = new EventEmitter<string>();
  @Output() navigateData = new EventEmitter<any>();
  @Output() proceedMasterShipmentEvent = new EventEmitter<any>();
  @ViewChild(MatSort) sort!: MatSort;
  selectedRowId: any;
  @ViewChild(MatPaginator, { static: false }) set matPaginator(
    paginator: MatPaginator
  ) {
    if (paginator) {
      this.actualPaginator = paginator;
      this.isInLastPage = this.actualPaginator.hasNextPage();
    }
  }

  public actualPaginator!: MatPaginator;
  public dataSource = new MatTableDataSource<any>([]);
  public selection = new SelectionModel<any>(true, []);
  public tableSortDirection!: string;
  public summaryCustomiseTableColumns!: TableColumnsProp[];
  public displayedColumns!: string[];
  public noSearchMessage = `${gridMessages.noSearch}`;
  public noMatchMessage!: string;
  public isAllCheckboxSelected!: boolean;
  public activeColumn!: string;
  public defaultSelectedColumn!: string;
  public selectedId!: string;
  public globalHeaderCheckboxState = false;
  public indeterminate = false;
  public isInLastPage = false;
  public previousPageSize = 10;
  public dateFormat = dateTimeFormat.onlyDate;
  public actionButtons!: string[];
  public buttonNames = popupButtons;
  public isOverlayOpen = false;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly globalSelectedIds: Set<number> = new Set<number>();
  private checkSelectedCount = 0;
  private isAllSelected = false;
  private selectAdditionalRows = false;

  constructor(
    public commonService: CommonService,
    public tableService: TableService,
    private readonly cdref: ChangeDetectorRef,
    private readonly checkboxService: CheckboxStateService,
    public commonTableService: CommonTableService,
    private readonly menuOverlayService: MenuOverlayService
  ) {
    this.setRequiredInitialisation();
  }

  ngOnInit(): void {
    this.summaryCustomiseTableColumns = this.summaryGridColumns;
    this.getDefaultSearchMessage();
    this.prepareTableData();
    this.renderActionsButtonList();
    this.tableService.setCustomizeMenuColumns(
      this.summaryCustomiseTableColumns
    );
    this.tableService.setReorderedColumns(
      this.tableService.getFromLocalStorage(this.isFrom)
    );
  }

  ngOnChanges() {
    this.dataSource.data = this.tableData;
    this.setDisplayColumnsForGrid();
    this.setGridColumnsOptions();
  }

  ngAfterViewInit() {
    this.getDataWithSortPagination();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  // For table header checkbox selection
  public onCheckAllChange(event: MatCheckboxChange): void {
    const isChecked = event.checked;
    this.isAllSelected = false;
    if (isChecked) {
      this.isCheckedCheckAllChange();
    } else {
      this.isUncheckedCheckAllChange();
    }
    const pageLookup = {
      [pageType.account]: selectedIdForSummaryTable.Account,
      [pageType.agent]: selectedIdForSummaryTable.Agent,
      [pageType.smStatusCodes]: selectedIdForSummaryTable.SmStatusCodes,
      [pageType.smRolesAndPermissions]:
        selectedIdForSummaryTable.SmRolesAndPermissions,
      [pageType.userManagement]: selectedIdForSummaryTable.Id,
      [pageType.smAirports]: selectedIdForSummaryTable.SmAirports,
      [pageType.smAirlines]: selectedIdForSummaryTable.SmAirlines,
    };
    if (isChecked) {
      this.getSelectionDataById(pageLookup[this.isFrom]);
    } else {
      this.getDeSelectionDataById(pageLookup[this.isFrom]);
    }
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  // for table body checkbox selection
  public checked(row: any) {
    const key = this.getCheckKey(row);
    if (!this.globalSelectedIds.has(key)) {
      this.globalSelectedIds.add(key);
      this.selection.select(row);
      this.checkSelectedCount++;
    }
    if (this.isAllSelected && this.globalSelectedIds.size === this.totalCount) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public unchecked(row: any) {
    const key = this.getCheckKey(row);
    if (this.globalSelectedIds.has(key)) {
      this.globalSelectedIds.delete(key);
      this.selection.deselect(row);
      this.checkSelectedCount--;
    }
    this.isAllSelected = false;
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public isChecked(row: any): boolean {
    const key = this.getCheckKey(row);
    return this.globalSelectedIds.has(key);
  }

  public dragAndDropGridColumns(event: CdkDragDrop<string[]>) {
    this.summaryCustomiseTableColumns =
      this.tableService.dragAndDropGridColumns(
        event,
        this.displayedColumns,
        this.summaryCustomiseTableColumns,
        this.tableService.getStorageName(this.isFrom),
        false
      );
  }

  public getBorderClass() {
    return (
      this.isFrom === pageType.shipmentGatewayMasterShipment ||
      this.isFrom === pageType.shipmentGatewayPiece ||
      this.isFrom === pageType.shipmentBag
    );
  }

  public onChangingPages(event: any) {
    const isPageSizeChanged = event.pageSize !== this.previousPageSize;
    this.previousPageSize = event.pageSize;
    if (isPageSizeChanged && this.globalHeaderCheckboxState) {
      this.selectAdditionalRows = true;
    } else {
      this.selectAdditionalRows = false;
    }
    this.paginationProps.paging.fromIndex =
      event.pageIndex * event?.pageSize + 1;
    this.paginationProps.paging.toIndex =
      this.paginationProps.paging.fromIndex + (event?.pageSize - 1);
    this.pageChangeEvent.emit({
      payload: this.paginationProps,
      isAllSelected: false,
    });
  }

  public getAllItemsSelectedMessage(): string {
    let allItemsMessage = '';
    if (this.isFrom === pageType.mawb) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Mawbs,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.agent) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Agents,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.hawb) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Shipments,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.manifest) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Manifests,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.bag) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Bags,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.userManagement) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.UserManagement,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.RolesAndPermissions,
        this.dataSource.data.length
      );
    } else if (this.isFrom === pageType.account) {
      allItemsMessage = MessageTemplates.AllItemsSelected(
        BulkDeleteItemNames.Accounts,
        this.dataSource.data.length
      );
    }
    return allItemsMessage;
  }

  public getSelectAllItemsMessage(): string {
    let selectAllMessage = '';
    if (this.isFrom === pageType.mawb) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Mawbs,
        this.totalCount
      );
    } else if (this.isFrom === pageType.agent) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Agents,
        4 //providing static count for reference
      );
    } else if (this.isFrom === pageType.hawb) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Shipments,
        this.totalCount
      );
    } else if (this.isFrom === pageType.manifest) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Manifests,
        this.totalCount
      );
    } else if (this.isFrom === pageType.bag) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Bags,
        this.totalCount
      );
    } else if (this.isFrom === pageType.userManagement) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.UserManagement,
        this.totalCount
      );
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.RolesAndPermissions,
        this.totalCount
      );
    } else if (this.isFrom === pageType.account) {
      selectAllMessage = MessageTemplates.SelectAllItems(
        BulkDeleteItemNames.Accounts,
        this.totalCount
      );
    }
    return selectAllMessage;
  }

  public selectAllCheckboxes() {
    this.isAllSelected = true;
    this.checkSelectedCount = this.totalCount;
    this.paginationProps.paging.fromIndex = 0;
    this.paginationProps.paging.toIndex = this.totalCount;
    this.pageChangeEvent.emit({
      payload: this.paginationProps,
      isAllSelected: true,
    });
  }

  public invokeMethodsAfterApiHit() {
    if (this.selectAdditionalRows) {
      this.selection.clear();
      this.checkAllCheckboxes(this.dataSource.data);
      this.selectAdditionalRows = false;
    }
    this.isAllSelected = false;
    this.updateCheckboxStateForCurrentPage();
    this.updateHeaderCheckboxState();
    this.setIconHeader();
  }

  public invokeMethodsAfterAllSelected(dataFromParent: any) {
    this.checkAllCheckboxes(dataFromParent);
    this.updateHeaderCheckboxState();
  }

  public invokeMethodsAfterDeleteOrNewSearch() {
    this.selection.clear();
    this.checkSelectedCount = 0;
    this.indeterminate = false;
    this.globalSelectedIds.clear();
  }

  public updateHeaderCheckboxState() {
    const { globalHeaderCheckboxState, indeterminate } =
      this.checkboxService.updateHeaderCheckboxState(
        this.dataSource,
        this.isChecked.bind(this),
        this.isAllSelected
      );
    this.globalHeaderCheckboxState = globalHeaderCheckboxState;
    this.indeterminate = indeterminate;
  }

  public navigateToSpecificRecord(rowData: any) {
    this.selection.clear();
    this.navigateData.emit(rowData);
  }

  public consigneeTooltip(tooltipInfo: any): string {
    if (!tooltipInfo) return '';
    const name = tooltipInfo.name || '';
    const companyName = tooltipInfo.companyName || '';
    const address1 = tooltipInfo.address_1 || '';
    const address2 = tooltipInfo.address_2 || '';
    const city = tooltipInfo.city || '';
    const state = tooltipInfo.state || '';
    const zipCode = tooltipInfo.zipCode || '';
    const country = tooltipInfo.country || '';
    const phone = tooltipInfo.phone || '';
    const email = tooltipInfo.email || '';

    let tooltipContent = '';
    tooltipContent += `${name}${companyName ? ', ' + companyName : ''}\n`;
    if (address1 || address2) {
      tooltipContent += `${address1}${address2 ? ', ' + address2 : ''}\n`;
    }
    tooltipContent += `${city}, ${state}, ${zipCode}, ${country}\n`;
    if (phone || email) {
      tooltipContent += `${phone}${phone && email ? ', ' : ''}${email}`;
    }
    return tooltipContent;
  }

  public getActionButtons(row: any): string[] {
    if (row.isPlaceholderRow) {
      // Only show 'Create' for placeholder rows
      return [this.buttonNames.create];
    }
    // Action buttons for specific page types
    if (this.isFrom === pageType.shipmentGatewayPiece) {
      return [this.buttonNames.proceed, this.buttonNames.createLinkedShipments];
    }
    if (this.isFrom === pageType.shipmentGatewayMasterShipment) {
      return [this.buttonNames.proceed];
    }
    // Default: no action buttons
    return [];
  }

  public onActionButtonClicked(button: string, rowData: any) {
    if (
      this.isFrom === pageType.shipmentGatewayMasterShipment &&
      button === this.buttonNames.proceed
    ) {
      this.proceedMasterShipmentEvent.emit(rowData);
    } else if (this.isFrom === pageType.shipmentGatewayPiece) {
      this.proceedMasterShipmentEvent.emit({
        selectedButton: button,
        selectedData: rowData,
      });
    }
  }

  public onRadioChange(row: any) {
    this.selectedRowId = row.id;
    this.proceedMasterShipmentEvent.emit(row);
  }

  public canCreate(row: any): boolean {
    // Example logic: Only allow 'Create' if specific conditions are met
    return row.isPlaceholderRow && row.someCondition === true;
  }

  private renderActionsButtonList(): void {
    if (this.isFrom === pageType.shipmentGatewayPiece) {
      this.actionButtons = [this.buttonNames.create];
    } else {
      this.actionButtons = [];
    }
  }

  private prepareTableData(): void {
    if (this.isFrom === pageType.shipmentGatewayPiece) {
      const placeholderRow = {
        shipmentGatewayShipment:
          this.dataSource.data[0]?.shipmentGatewayShipment || '',
        shipmentGatewayConsigneeName: '',
        shipmentGatewayOrigin: '',
        shipmentGatewayFinalDestination: '',
        shipmentGatewayDateOfCreation: '',
        shipmentGatewayWeights: '',
        shipmentGatewayDimensions: '',
        isGatewayed: '',
        isPlaceholderRow: true, // Identify as placeholder row
      };
      this.dataSource.data = [placeholderRow, ...this.dataSource.data];
    }
  }

  private getDataWithSortPagination() {
    this.sort.active = this.activeColumn;
    this.sort.direction = 'desc';
    this.dataSource.sort = this.sort;
    this.cdref.detectChanges();
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.tableSortDirection = data.direction;
    });
  }

  private setRequiredInitialisation() {
    this.tableSortDirection = 'desc';
    this.isAllCheckboxSelected = false;
  }

  private setGridColumnsOptions() {
    if (this.isFrom === pageType.mawb) {
      this.activeColumn = pageType.mawb;
      this.defaultSelectedColumn = InputRowKeys.Mawb;
      this.selectedId = selectedIdForSummaryTable.Mawb;
    } else if (this.isFrom === pageType.agent) {
      this.activeColumn = pageType.agent;
      this.defaultSelectedColumn = InputRowKeys.Agent;
      this.selectedId = selectedIdForSummaryTable.Agent;
    } else if (this.isFrom === pageType.hawb) {
      this.activeColumn = pageType.hawb;
      this.defaultSelectedColumn = InputRowKeys.hawbExt;
      this.selectedId = selectedIdForSummaryTable.Hawb;
    } else if (this.isFrom === pageType.manifest) {
      this.activeColumn = pageType.manifest;
      this.defaultSelectedColumn = InputRowKeys.ManifestNo;
      this.selectedId = selectedIdForSummaryTable.Manifest;
    } else if (this.isFrom === pageType.bag) {
      this.activeColumn = pageType.bag;
      this.defaultSelectedColumn = InputRowKeys.bagExt;
      this.selectedId = selectedIdForSummaryTable.Bag;
    } else if (this.isFrom === pageType.account) {
      this.activeColumn = pageType.account;
      this.defaultSelectedColumn = InputRowKeys.Account;
      this.selectedId = selectedIdForSummaryTable.Account;
    } else if (this.isFrom === pageType.smStatusCodes) {
      this.activeColumn = pageType.smStatusCodes;
      this.defaultSelectedColumn = InputRowKeys.smStatusCodes;
      this.selectedId = selectedIdForSummaryTable.SmStatusCodes;
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      this.activeColumn = pageType.smRolesAndPermissions;
      this.defaultSelectedColumn = InputRowKeys.smRolesAndPermissions;
      this.selectedId = selectedIdForSummaryTable.SmRolesAndPermissions;
    } else if (this.isFrom === pageType.userManagement) {
      this.activeColumn = pageType.userManagement;
      this.defaultSelectedColumn = InputRowKeys.smUserManagement;
      this.selectedId = selectedIdForSummaryTable.SmUserManagement;
    } else if (this.isFrom === pageType.smAirports) {
      this.activeColumn = pageType.smAirports;
      this.defaultSelectedColumn = InputRowKeys.smAirports;
      this.selectedId = selectedIdForSummaryTable.SmAirports;
    } else if (this.isFrom === pageType.smNumberRanges) {
      this.activeColumn = pageType.smNumberRanges;
      this.defaultSelectedColumn = InputRowKeys.smNumberRanges;
      this.selectedId = selectedIdForSummaryTable.SmNumberRanges;
    } else if (this.isFrom === pageType.smAirlines) {
      this.activeColumn = pageType.smAirlines;
      this.defaultSelectedColumn = InputRowKeys.smAirlines;
      this.selectedId = selectedIdForSummaryTable.SmAirlines;
    }
    this.tableService.setDefaultTableColumn(this.defaultSelectedColumn);
  }

  private setDisplayColumnsForGrid() {
    this.displayedColumns = this.tableService.getFromLocalStorage(this.isFrom);
    this.tableService.setToLocalStorage(
      this.tableService.getStorageName(this.isFrom),
      JSON.stringify(this.displayedColumns)
    );
    this.tableService.setTableDisplayedColumns(this.displayedColumns);
  }

  private getDefaultSearchMessage() {
    let moduleName = '';
    if (this.isFrom === pageType.mawb) {
      moduleName = BulkDeleteItemNames.Mawbs;
    } else if (this.isFrom === pageType.hawb) {
      moduleName = BulkDeleteItemNames.Shipments;
    } else if (this.isFrom === pageType.manifest) {
      moduleName = BulkDeleteItemNames.Manifests;
    } else if (this.isFrom === pageType.bag) {
      moduleName = BulkDeleteItemNames.Bags;
    }

    if (this.isFrom === pageType.account) {
      this.noSearchMessage = gridMessages.accountNoSearch;
      this.noMatchMessage = gridMessages.noRecordsMatch(
        BulkDeleteItemNames.Accounts
      );
    } else if (this.isFrom === pageType.userManagement) {
      this.noSearchMessage = gridMessages.noUserSearch;
      this.noMatchMessage = gridMessages.noRecordsMatch(
        BulkDeleteItemNames.User
      );
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      this.noSearchMessage = gridMessages.noRolesAndPermissionSearch;
      this.noMatchMessage = gridMessages.noRecordsMatch(
        BulkDeleteItemNames.RolesAndPermissions
      );
    } else if (this.isFrom === pageType.userLoginInfo) {
      this.noSearchMessage = gridMessages.noUser;
      this.noMatchMessage = gridMessages.noRecordsMatch(
        BulkDeleteItemNames.Users
      );
    } else {
      this.noSearchMessage = `${this.noSearchMessage} ${moduleName}`;
      this.noMatchMessage = gridMessages.noRecordsMatch(moduleName);
    }
  }

  private getSelectionDataById(key: string) {
    this.dataSource.data.forEach(row => {
      if (!this.globalSelectedIds.has(row[key])) {
        this.globalSelectedIds.add(row[key]);
        this.selection.select(row);
        this.checkSelectedCount++; // Increment for new selections
      }
    });
  }

  private getDeSelectionDataById(key: string) {
    this.dataSource.data.forEach(row => {
      if (this.globalSelectedIds.has(row[key])) {
        this.globalSelectedIds.delete(row[key]);
        this.selection.deselect(row);
        this.checkSelectedCount--;
      }
      this.selection.deselect(row);
    });
  }

  private getCheckKey(row: any) {
    let checkKey;
    if (this.isFrom === pageType.mawb) {
      checkKey = row.mawbId;
    } else if (this.isFrom === pageType.agent) {
      checkKey = row.agentId;
    } else if (this.isFrom === pageType.hawb) {
      checkKey = row.hawbId;
    } else if (this.isFrom === pageType.manifest) {
      checkKey = row.manifestId;
    } else if (this.isFrom === pageType.bag) {
      checkKey = row.bagId;
    } else if (this.isFrom === pageType.account) {
      checkKey = row.accountNumber;
    } else if (this.isFrom === pageType.smStatusCodes) {
      checkKey = row.statusCodeType;
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      checkKey = row.id;
    } else if (this.isFrom === pageType.userManagement) {
      checkKey = row.id;
    } else if (this.isFrom === pageType.smAirports) {
      checkKey = row.airportCode;
    } else if (this.isFrom === pageType.smNumberRanges) {
      checkKey = row.seriesName;
    } else if (this.isFrom === pageType.smAirlines){
      checkKey = row.airlineCode;
    }
    return checkKey;
  }

  private checkAllCheckboxes(dataArray: any) {
    dataArray.forEach((row: any) => {
      const key = this.getCheckKey(row);
      if (!this.globalSelectedIds.has(key)) {
        this.globalSelectedIds.add(key);
        this.selection.select(row);
      }
    });
  }

  private updateCheckboxStateForCurrentPage() {
    this.checkboxService.updateCheckboxStateForCurrentPage(
      this.dataSource,
      this.selection,
      this.globalSelectedIds,
      this.selectedId
    );
  }

  private setIconHeader() {
    if (this.globalSelectedIds.size > 0) {
      this.tableSelectionEvent.emit(ActionIcons.selected);
    } else {
      this.tableSelectionEvent.emit(ActionIcons.fetchResult);
    }
  }

  private isCheckedCheckAllChange() {
    if (this.isFrom === pageType.mawb) {
      this.getSelectionDataById(selectedIdForSummaryTable.Mawb);
    } else if (this.isFrom === pageType.hawb) {
      this.getSelectionDataById(selectedIdForSummaryTable.Hawb);
    } else if (this.isFrom === pageType.manifest) {
      this.getSelectionDataById(selectedIdForSummaryTable.Manifest);
    } else if (this.isFrom === pageType.bag) {
      this.getSelectionDataById(selectedIdForSummaryTable.Bag);
    } else if (this.isFrom === pageType.userManagement) {
      this.getSelectionDataById(selectedIdForSummaryTable.SmUserManagement);
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      this.getSelectionDataById(
        selectedIdForSummaryTable.SmRolesAndPermissions
      );
    } else if (this.isFrom === pageType.smNumberRanges) {
      this.getSelectionDataById(selectedIdForSummaryTable.SmNumberRanges);
    }
  }

  private isUncheckedCheckAllChange() {
    if (this.isFrom === pageType.mawb) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Mawb);
    } else if (this.isFrom === pageType.hawb) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Hawb);
    } else if (this.isFrom === pageType.manifest) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Manifest);
    } else if (this.isFrom === pageType.bag) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.Bag);
    } else if (this.isFrom === pageType.userManagement) {
      this.getSelectionDataById(selectedIdForSummaryTable.SmUserManagement);
    } else if (this.isFrom === pageType.smRolesAndPermissions) {
      this.getSelectionDataById(
        selectedIdForSummaryTable.SmRolesAndPermissions
      );
    } else if (this.isFrom === pageType.smNumberRanges) {
      this.getDeSelectionDataById(selectedIdForSummaryTable.SmNumberRanges);
    }
  }

  public toggleOverlay(menuOverlay?: any) {
    if (this.isOverlayOpen) {
      this.menuOverlayService.closeOverlay();
    } else {
      this.menuOverlayService.openSearchOverlay(menuOverlay, this.isFrom);
    }
    this.isOverlayOpen = !this.isOverlayOpen;
  }
}
