import { Directive, ElementRef, HostListener } from '@angular/core';
import { ValidatorPattern } from '../utility/validators.pattern';

@Directive({
  selector: '[appPostalCode]',
})
export class AppPostalCodeDirective {
  public regexPattern: RegExp = ValidatorPattern.alphaNumerics;

  constructor(private readonly el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput() {
    let current: string = this.el.nativeElement.value;

    if (!this.regexPattern.exec(current) && current.indexOf('-') === -1) {
      this.el.nativeElement.value = current.replace(/[^a-zA-Z0-9-]/g, '');
      return;
    }

    const hyphenIndex = current.lastIndexOf('-');

    if (hyphenIndex !== -1 && hyphenIndex !== 5) {
      current = current.slice(0, hyphenIndex) + current.slice(hyphenIndex + 1);
      this.el.nativeElement.value = current;
    }
  }
}
