import { Action, State, StateContext } from '@ngxs/store';
import {
  IManifestBagStateModel,
  IManifestDetailsStateModel,
  IManifestInformationData,
  ManifestColumnData,
  ManifestDropdownList,
  Origin,
} from 'src/app/features/model/manifest.model';
import { defaultManifestInfoData } from '../manifest.config';
import { Injectable } from '@angular/core';
import {
  FetchDestinations,
  FetchManifestDropdowns,
  FetchManifestsInformationListSuccess,
  FetchManifestsList,
  FetchOrigins,
  FetchSelectedManifestById,
  LinkBagToManifest,
  ResetBagList,
  ResetManifestsInformationList,
  ResetManifestsList,
  SaveManifestDetails,
  UnlinkBagFromManifest,
} from './manifest.action';

// Manifest Details Section
@State<IManifestDetailsStateModel>({
  name: 'manifestInfo',
  defaults: {
    manifestInfo: defaultManifestInfoData(),
  },
})
@Injectable()
export class ManifestDetailsState {
  @Action(SaveManifestDetails)
  saveManifestInfoData(
    ctx: StateContext<IManifestDetailsStateModel>,
    action: SaveManifestDetails
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      manifestInfo: action.payload,
    });
  }
}

// Bag table data
@State<IManifestBagStateModel>({
  name: 'manifestBagList',
  defaults: {
    bag: [],
  },
})
@Injectable()
export class ManifestBagListState {
  @Action(LinkBagToManifest)
  linkBagData(
    ctx: StateContext<IManifestBagStateModel>,
    action: LinkBagToManifest
  ) {
    const state = ctx.getState();
    const updatedBagList = [...state.bag, ...action.payload]; // Merge old state with new data
    ctx.setState({
      ...state,
      bag: updatedBagList,
    });
  }

  @Action(UnlinkBagFromManifest)
  unlinkBagData(ctx: StateContext<IManifestBagStateModel>, { payload }: any) {
    const state = ctx.getState();
    const filteredBags = state.bag.filter(
      linkedBags => linkedBags.bagExt !== payload
    );
    ctx.setState({
      ...state,
      bag: filteredBags,
    });
  }

  @Action(ResetBagList)
  resetManifestBagData(ctx: StateContext<IManifestBagStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      bag: [],
    });
  }
}

export interface ManifestsListStateModel {
  manifests: ManifestColumnData[];
  totalData: number;
  origins: Origin[];
  destinations: Origin[];
}

@Injectable()
@State<ManifestsListStateModel>({
  name: 'manifests',
  defaults: {
    manifests: [],
    totalData: 0,
    origins: [],
    destinations: [],
  },
})
export class ManifestsListState {
  @Action(FetchManifestsList)
  FetchmanifestsList(
    ctx: StateContext<ManifestsListStateModel>,
    action: FetchManifestsList
  ) {
    const state = ctx.getState();
    const payload = action.payload;
    if (payload.data?.manifest && payload.data?.totalRecords) {
      ctx.setState({
        ...state,
        manifests: payload.data.manifest,
        totalData: payload.data.totalRecords,
      });
    } else {
      ctx.setState({
        ...state,
        manifests: [],
        totalData: 0,
      });
    }
  }

  @Action(FetchOrigins)
  FetchOrigins(
    ctx: StateContext<ManifestsListStateModel>,
    action: FetchOrigins
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      origins: action.origins,
    });
  }

  @Action(FetchDestinations)
  FetchDestinations(
    ctx: StateContext<ManifestsListStateModel>,
    action: FetchDestinations
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      destinations: action.destinations,
    });
  }

  @Action(ResetManifestsList)
  ResetmanifestsList(ctx: StateContext<ManifestsListStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      manifests: [],
      totalData: 0,
    });
  }
}

// Fetch Manifest Data
export interface ManifestInformationStateModel {
  manifestInformation: IManifestInformationData;
  manifestsInformationList: IManifestInformationData[];
}
@State<ManifestInformationStateModel>({
  name: 'manifestInformation',
  defaults: {
    manifestInformation: {} as IManifestInformationData,
    manifestsInformationList: [],
  },
})
@Injectable()
export class ManifestInformationState {
  @Action(FetchManifestsInformationListSuccess)
  fetchBagsInformationListSuccess(
    { getState, setState }: StateContext<ManifestInformationStateModel>,
    { bags }: FetchManifestsInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      manifestsInformationList: bags,
    });
  }

  @Action(FetchSelectedManifestById)
  FetchSelectedBagById(
    ctx: StateContext<ManifestInformationStateModel>,
    action: FetchSelectedManifestById
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      manifestInformation: state.manifestsInformationList.filter(
        element => element.manifestId == action.manifestId
      )[0],
    });
  }

  @Action(ResetManifestsInformationList)
  resetBagInfo(
    ctx: StateContext<ManifestInformationStateModel>,
    action: ResetManifestsInformationList
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      manifestInformation: {} as IManifestInformationData,
      manifestsInformationList: [],
    });
  }
}

@Injectable()
@State<ManifestDropdownList>({
  name: 'manifestDropdowns',
  defaults: {
    origins: [],
    destinations: [],
    agentTypeData: [],
  },
})
export class ManifestDropdownsListState {
  @Action(FetchManifestDropdowns)
  saveShipmentLookUpData(
    ctx: StateContext<ManifestDropdownList>,
    action: FetchManifestDropdowns
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      origins: action.payload.origins,
      destinations: action.payload.destinations,
      agentTypeData: action.payload.agentTypeData,
    });
  }
}