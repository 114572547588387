import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import {
  AccountColumnData,
  IAccountsDetailData,
  IAddressInfoDetailsData,
  IBusinessInfoDetailsData,
  IGovernmentInfoDetailsData,
} from 'src/app/features/model/sm-accounts';
import {
  FetchAccountList,
  FetchAccountsInformationListSuccess,
  FetchSelectedAccountById,
  ResetAccountList,
  ResetAccountsInformationList,
  SaveAccountsDetail,
  SaveAddressDetails,
  SaveBusinessDetails,
  SaveGovernmentDetails,
} from './accounts.action';
import {
  defaultAccountsAddressInfoData,
  defaultAccountsBusinessInfoData,
  defaultAccountsDetailData,
  defaultAccountsGovernmentInfoData,
} from '../accounts.config';
import { updateState } from './accounts.utility';

// Accounts Detail Section
@State<IAccountsDetailData>({
  name: 'accountsDetail',
  defaults: {
    ...defaultAccountsDetailData(),
  },
})
@Injectable()
export class AccountsDetailState {
  @Action(SaveAccountsDetail)
  saveAccountsDetailData(
    ctx: StateContext<IAccountsDetailData>,
    action: SaveAccountsDetail
  ) {
    updateState(ctx, action.payload);
  }
}

// Accounts Address Section
@State<IAddressInfoDetailsData>({
  name: 'accountsAddressInfo',
  defaults: {
    ...defaultAccountsAddressInfoData(),
  },
})
@Injectable()
export class AccountsAddressInfoState {
  @Action(SaveAddressDetails)
  saveAddressDetailsData(
    ctx: StateContext<IAddressInfoDetailsData>,
    action: SaveAddressDetails
  ) {
    updateState(ctx, action.payload);
  }
}

// Business Information Section
@State<IBusinessInfoDetailsData>({
  name: 'accountsBusinessInfo',
  defaults: {
    ...defaultAccountsBusinessInfoData(),
  },
})
@Injectable()
export class AccountsBusinessInfoState {
  @Action(SaveBusinessDetails)
  saveBusinessDetailsData(
    ctx: StateContext<IBusinessInfoDetailsData>,
    action: SaveBusinessDetails
  ) {
    updateState(ctx, action.payload);
  }
}

// Government Information
@State<IGovernmentInfoDetailsData>({
  name: 'accountsGovernmentInfo',
  defaults: {
    ...defaultAccountsGovernmentInfoData(),
  },
})
@Injectable()
export class AccountsGovernmentInfoState {
  @Action(SaveGovernmentDetails)
  saveGovernmentDetailsData(
    ctx: StateContext<IGovernmentInfoDetailsData>,
    action: SaveGovernmentDetails
  ) {
    updateState(ctx, action.payload);
  }
}

// Account List
export interface AccountListStateModel {
  account: AccountColumnData[];
  totalData: number;
}

@Injectable()
@State<AccountListStateModel>({
  name: 'account',
  defaults: {
    account: [],
    totalData: 0,
  },
})
export class AccountListState {
  @Action(FetchAccountList)
  FetchHawbsList(
    ctx: StateContext<AccountListStateModel>,
    action: FetchAccountList
  ) {
    const payload = action.payload;
    if (payload.data?.account && payload.data?.totalRecords) {
      updateState(ctx, {
        account: payload.data.account,
        totalData: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        account: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetAccountList)
  ResetAccountList(ctx: StateContext<AccountListStateModel>) {
    updateState(ctx, {
      account: [],
      totalData: 0,
    });
  }
}

// Account CRUD
export interface AccountInformationStateModel {
  accountInformation: any;
  accountsInformationList: any[];
}

@State<AccountInformationStateModel>({
  name: 'accountInformation',
  defaults: {
    accountInformation: {},
    accountsInformationList: [],
  },
})
@Injectable()
export class AccountInformationState {
  @Action(FetchAccountsInformationListSuccess)
  fetchAccountsInformationListSuccess(
    { getState, setState }: StateContext<AccountInformationStateModel>,
    { accounts }: FetchAccountsInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      accountsInformationList: accounts,
    });
  }

  @Action(FetchSelectedAccountById)
  FetchSelectedAccountById(
    ctx: StateContext<AccountInformationStateModel>,
    action: FetchSelectedAccountById
  ) {
    const state = ctx.getState();
    const selectedAccount = state.accountsInformationList.find(
      element => element.accountId === action.accountId
    );

    updateState(ctx, {
      accountInformation: selectedAccount || {},
    });
  }

  @Action(ResetAccountsInformationList)
  resetAccountInfo(ctx: StateContext<AccountInformationStateModel>) {
    updateState(ctx, {
      accountInformation: {},
      accountsInformationList: [],
    });
  }
}
