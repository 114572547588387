import { Injectable } from '@angular/core';
import { TableColumnsProp } from '../models/tables.model';
import {
  fullmanAndCbpGridFields,
  hyperlinkLabels,
  pageType,
} from '../utility/constants';
@Injectable({
  providedIn: 'root',
})
export class CommonTableService {
  /* Display the selected columns in the table */
  public displaySelectedColumnsToTable(
    columnName: string,
    customiseTableColumns: TableColumnsProp[],
    displayedColumns: string[]
  ) {
    const isColumnView = customiseTableColumns.filter(
      data => data.key === columnName && data.isSelected
    );
    if (isColumnView && isColumnView.length > 0) return true;
    else {
      const columnIndex = displayedColumns.indexOf(columnName);
      if (columnIndex > -1) {
        displayedColumns.splice(columnIndex, 1);
      }
      return false;
    }
  }

  public isHyperlink(label: string, isFrom: string, key?: string) {
    const labelMap = {
      [fullmanAndCbpGridFields.fileName]: hyperlinkLabels.fullmanFetchFileName,
      [fullmanAndCbpGridFields.fullmanParsedDataId]:
        hyperlinkLabels.fullmanAction,
      [fullmanAndCbpGridFields.cbpRequestInfoId]: hyperlinkLabels.fullmanAction,
      [fullmanAndCbpGridFields.errorInfo]:
        hyperlinkLabels.fullmanFetchErrorInfo,
      [fullmanAndCbpGridFields.requestedEdi]: hyperlinkLabels.ediFileName,
      [fullmanAndCbpGridFields.responseEdi]: hyperlinkLabels.ediFileName,
      [pageType.smAirports]: hyperlinkLabels.smAirports,
      [pageType.smAirlines]: hyperlinkLabels.smAirlines,
      [pageType.hsCodes]: hyperlinkLabels.hsCode,
      [pageType.agent]: hyperlinkLabels.agent,
      [pageType.account]: hyperlinkLabels.account,
      [pageType.smRolesAndPermissions]: hyperlinkLabels.smRolesAndPermissions,
      [pageType.userManagement]: hyperlinkLabels.fullName,
      [pageType.smNumberRanges]: hyperlinkLabels.seriesName,
      [pageType.smStatusCodes]: hyperlinkLabels.smStatusCodes,
      [pageType.hawb]: hyperlinkLabels.hawb,
      [pageType.bag]: hyperlinkLabels.bag,
      [pageType.manifest]: hyperlinkLabels.manifest,
      [pageType.mawb]: hyperlinkLabels.mawb,
    };
    if (
      isFrom === pageType.fetchFullManData ||
      isFrom === pageType.cbpRequestStatus ||
      isFrom === pageType.cbpResponseStatus
    ) {
      if (key) {
        return label === labelMap[key];
      }
    } else {
      return label === labelMap[isFrom];
    }
    return false;
  }
}
