<button
  mat-mini-fab
  (click)="toggleSidenav()"
  color="primary"
  aria-label=""
  class="toggle-sidebar-btn"
  [ngClass]="{ move: isExpanded }">
  <app-svg-icon
    [iconName]="isExpanded ? 'sidebarArrow' : 'sidebarArrowRight'"
    [isStaticSvg]="true"
    [isFrom]="'Page'"></app-svg-icon>
</button>
<mat-sidenav-container autosize class="side-nav-container">
  <mat-sidenav #sidenav mode="side" opened="isExpanded" class="side-nav-menu">
    <mat-nav-list class="menu-list">
      <mat-list-item
        *ngFor="let menuItem of menuItems; let i = index"
        [ngClass]="{ active: pressedButtonIndex === i }"
        (click)="onButtonClick(i)"
        [routerLink]="menuItem.routerLink"
        matTooltip="{{ isExpanded ? null : menuItem.label }}"
        matTooltipPosition="right"
        class="side-nav-button svg-icon">
        <div class="label-icon" class="{{ isExpanded ? 'ptr-10' : '' }}">
          <app-svg-icon
            [iconName]="menuItem.iconName"
            [isStaticSvg]="true"
            [isFrom]="'Page'"
            [ngClass]="{ activeIcon: pressedButtonIndex === i }"
            class="{{ isExpanded ? 'ptr-10' : '' }}"></app-svg-icon>
          <span *ngIf="isExpanded" class="ibc-heading-h7-subtitle">
            {{ menuItem.label }}
          </span>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>
