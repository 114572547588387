import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { SpinnerService } from '../app/shared/services/spinner.service';
import { Subject, takeUntil, filter } from 'rxjs';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  IdTokenClaims,
  InteractionStatus,
  InteractionType,
  RedirectRequest,
} from '@azure/msal-browser';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import { Claim } from './shared/models/dynamic.model';
import { pageUrl } from './shared/utility/constants';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AppComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: Claim[] = [];
  title = 'pactrakTNG';
  isIframe = false;
  loginDisplay = false;
  public userInfo: any;
  public isSpinnerLoad = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  private readonly _destroying$ = new Subject<void>();
  public fetchedList: any;
  isLogout = false;
  public isForPrintPreview = false;

  constructor(
    private spinnerService: SpinnerService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    this.spinnerStatus();
    this.b2cAuthorizeRequest();
    this.checkLayoutRequired();
  }

  private b2cAuthorizeRequest(){
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
      || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
      || msg.eventType === EventType.SSO_SILENT_SUCCESS),
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      let payload = result.payload as AuthenticationResult;
      console.log(payload);     
    });

    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.checkAndSetActiveAccount();
      this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)
      // this.router.navigate(['/dashboard']);
      //this.setLoginDisplay();
      //this.login()

    })
  }

  private spinnerStatus(){
    this.spinnerService.visibility
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        setTimeout(() => {
          this.isSpinnerLoad = data;
        });
      });
  }
  private b2cReadAuthToken(){
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });
  }

  private setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  private checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  private getClaims(claims: any) {
    let list: Claim[]  =  new Array<Claim>();
    if (claims) {
      Object.keys(claims).forEach(function(k, v){
        let c = new Claim()
        c.id = v;
        c.claim = k;
        c.value = claims ? claims[k] : null;
        list.push(c);
      });
      this.dataSource = list;
    }
  }

  private login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  // login(userFlowRequest?: RedirectRequest | PopupRequest) {
  //   if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
  //     if (this.msalGuardConfig.authRequest) {
  //       this.authService
  //         .loginPopup({
  //           ...this.msalGuardConfig.authRequest,
  //           ...userFlowRequest,
  //         } as PopupRequest)
  //         .subscribe((response: AuthenticationResult) => {
  //           this.authService.instance.setActiveAccount(response.account);
  //         });
  //     } else {
  //       this.authService
  //         .loginPopup(userFlowRequest)
  //         .subscribe((response: AuthenticationResult) => {
  //           this.authService.instance.setActiveAccount(response.account);
  //         });
  //     }
  //   } else {
  //     if (this.msalGuardConfig.authRequest) {
  //       this.authService.loginRedirect({
  //         ...this.msalGuardConfig.authRequest,
  //         ...userFlowRequest,
  //       } as RedirectRequest);
  //     } else {
  //       this.authService.loginRedirect(userFlowRequest);
  //     }
  //   }
  // }

  logout() {
    const activeAccount =
      this.authService.instance.getActiveAccount() ||
      this.authService.instance.getAllAccounts()[0];

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        account: activeAccount,
      });
    } else {
      this.authService.logoutRedirect({
        account: activeAccount,
      });
    }
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  private checkLayoutRequired() {
    if (
      window.location.pathname === pageUrl.printPreviewTable ||
      window.location.pathname === pageUrl.printPreviewInfoTable
    ) {
      this.isForPrintPreview = true;
    } else {
      this.isForPrintPreview = false;
    }
  }
}
