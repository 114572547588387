import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { SnakbarService } from './snakbar.service';
import {
  AccordionTabs,
  AttachmentDownloadRequestType,
  dateTimeFormat,
  downloadInfoPageSectionHeaders,
  downloadInfoPageSectionKeys,
  fileFormats,
  fileFormatsType,
  ModalPopupMessages,
} from '../utility/constants';
import { GridEventColumns } from '../components/events/event.config';
import { AttachmentsHeaders, NotesHeaders } from '../configs/common-configs';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  constructor(
    private readonly datePipe: DatePipe,
    private readonly snakbarService: SnakbarService
  ) {}

  public generateExcelCSVFile(excelData: any): void {
    const workbook = new ExcelJS.Workbook(); // create workbook
    const worksheet = workbook.addWorksheet(excelData.name); // adding sheet to workbook
    this.loadHeadersAndDataBasedOnPage(excelData, worksheet);
    this.autoWidth(worksheet); // set width for cells
    worksheet.eachRow(function (Row) {
      Row.alignment = { horizontal: 'left' }; // left align cells
    });
    this.saveFile(
      excelData.selectedFileType === fileFormats.csv
        ? workbook.csv
        : workbook.xlsx,
      excelData
    ); // save file & download
    this.snakbarService.openCustomisedSnackBar(
      ModalPopupMessages.downloadSuccess(excelData.successMessage),
      ModalPopupMessages.success
    );
  }

  private loadHeadersAndDataBasedOnPage(
    excelData: any,
    worksheet: ExcelJS.Worksheet
  ) {
    if (excelData.isFromSummary) {
      worksheet.addRow(excelData.headers); // adding headers
      this.setDataForHeaders(excelData, worksheet, 'tableData'); // adding row data's
    } else {
      excelData.sectionNames.forEach((headerName: string) => {
        const sectionData =
          excelData.pageData[this.getHeaderSectionKeys(headerName)];
        // Skip sections with no data
        if (!sectionData?.headers || sectionData?.data?.length === 0) {
          return;
        }
        const sectionHeaderRow = worksheet.addRow([headerName]); // adding section headers
        sectionHeaderRow.font = { bold: true };
        const sectionColumnHeader = worksheet.addRow(
          this.getHeaders(excelData, headerName)
        ); // adding headers
        sectionColumnHeader.font = { bold: true };
        this.getBorders(sectionColumnHeader, worksheet);
        this.setDataForHeaders(sectionData, worksheet, 'data'); // adding row data's
        worksheet.addRow([]); // adding empty row
      });
    }
  }

  private getHeaders(excelData: any, headerName: string) {
    const sectionKey = this.getHeaderSectionKeys(headerName);
    const section = excelData.pageData[sectionKey];
    if (!section?.headers) {
      return [];
    }

    if (
      headerName === downloadInfoPageSectionHeaders.mawbInfo ||
      headerName === downloadInfoPageSectionHeaders.mawbmiscellaneous ||
      headerName === downloadInfoPageSectionHeaders.details ||
      headerName === downloadInfoPageSectionHeaders.mawbManifests ||
      headerName === downloadInfoPageSectionHeaders.mawbFlights ||
      headerName === downloadInfoPageSectionHeaders.manifestInfo ||
      headerName === downloadInfoPageSectionHeaders.manifestBag ||
      headerName === downloadInfoPageSectionHeaders.widgets ||
      headerName === downloadInfoPageSectionHeaders.hawbInfo ||
      headerName === downloadInfoPageSectionHeaders.hawbPieces ||
      headerName === downloadInfoPageSectionHeaders.hawbCommodity ||
      headerName === downloadInfoPageSectionHeaders.hawbRoles ||
      headerName === downloadInfoPageSectionHeaders.hawbMiscellaneous ||
      headerName === downloadInfoPageSectionHeaders.hawbStatusCode ||
      headerName === downloadInfoPageSectionHeaders.hawbConsignee ||
      headerName === downloadInfoPageSectionHeaders.hawbShipper ||
      headerName === downloadInfoPageSectionHeaders.bagInfo ||
      headerName === downloadInfoPageSectionHeaders.bagMiscellaneous ||
      headerName === downloadInfoPageSectionHeaders.bagPieces ||
      headerName === downloadInfoPageSectionHeaders.bagCriteria
    ) {
      return section.headers;
    } else if (headerName === downloadInfoPageSectionHeaders.events) {
      return GridEventColumns.map(eventItem => eventItem.label);
    } else if (headerName === downloadInfoPageSectionHeaders.notes) {
      return NotesHeaders.map(eventItem => eventItem.label);
    } else if (headerName === downloadInfoPageSectionHeaders.attachments) {
      return AttachmentsHeaders.map(eventItem => eventItem.label);
    }
  }

  private getHeaderSectionKeys(sectionName: string) {
    const sectionKeyMap = {
      [AttachmentDownloadRequestType.mawbInfo]:
        downloadInfoPageSectionKeys.mawbInfo,
      [downloadInfoPageSectionHeaders.mawbmiscellaneous]:
        downloadInfoPageSectionKeys.mawbmiscellaneous,
      [AccordionTabs.Details]: downloadInfoPageSectionKeys.details,
      [AccordionTabs.Events]: downloadInfoPageSectionKeys.events,
      [AccordionTabs.Attachments]: downloadInfoPageSectionKeys.attachments,
      [AccordionTabs.Notes]: downloadInfoPageSectionKeys.notes,
      [downloadInfoPageSectionHeaders.mawbManifests]:
        downloadInfoPageSectionKeys.mawbManifests,
      [downloadInfoPageSectionHeaders.mawbFlights]:
        downloadInfoPageSectionKeys.mawbFlights,
      [downloadInfoPageSectionHeaders.manifestInfo]:
        downloadInfoPageSectionKeys.manifestInfo,
      [downloadInfoPageSectionHeaders.manifestBag]:
        downloadInfoPageSectionKeys.manifestsBag,
      [downloadInfoPageSectionHeaders.widgets]:
        downloadInfoPageSectionKeys.widgets,
      [downloadInfoPageSectionHeaders.hawbInfo]:
        downloadInfoPageSectionKeys.hawbInfo,
      [downloadInfoPageSectionHeaders.hawbPieces]:
        downloadInfoPageSectionKeys.hawbPieces,
      [downloadInfoPageSectionHeaders.hawbCommodity]:
        downloadInfoPageSectionKeys.hawbCommodity,
      [downloadInfoPageSectionHeaders.hawbRoles]:
        downloadInfoPageSectionKeys.hawbRoles,
      [downloadInfoPageSectionHeaders.hawbMiscellaneous]:
        downloadInfoPageSectionKeys.hawbMiscellaneous,
      [downloadInfoPageSectionHeaders.hawbStatusCode]:
        downloadInfoPageSectionKeys.hawbStatusCode,
      [downloadInfoPageSectionHeaders.hawbConsignee]:
        downloadInfoPageSectionKeys.hawbConsignee,
      [downloadInfoPageSectionHeaders.hawbShipper]:
        downloadInfoPageSectionKeys.hawbShipper,
      [downloadInfoPageSectionHeaders.bagInfo]:
        downloadInfoPageSectionKeys.bagInfo,
      [downloadInfoPageSectionHeaders.bagMiscellaneous]:
        downloadInfoPageSectionKeys.bagMiscellaneous,
      [downloadInfoPageSectionHeaders.bagPieces]:
        downloadInfoPageSectionKeys.bagPieces,
      [downloadInfoPageSectionHeaders.bagCriteria]:
        downloadInfoPageSectionKeys.bagCriteria,
    };
    return sectionKeyMap[sectionName] || '';
  }

  private getBorders(dataToIterate: ExcelJS.Row, worksheet: ExcelJS.Worksheet) {
    dataToIterate.eachCell({ includeEmpty: true }, (cell_v, colNumber) => {
      if (colNumber <= worksheet.columnCount) {
        cell_v.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    });
  }

  private setDataForHeaders(
    excelData: any,
    worksheet: ExcelJS.Worksheet,
    param: any
  ) {
    if (!excelData[param] || !Array.isArray(excelData[param])) {
      return; // Skip if data is undefined or not an array
    }
    excelData[param].forEach((item: any) => {
      const row: any[] = [];
      excelData.headerKeys.forEach((header: string) => {
        row.push(item[header]);
      });
      const rowData = worksheet.addRow(row);
      this.getBorders(rowData, worksheet);
    });
  }

  private readonly autoWidth = (worksheet: any, minimalWidth = 10) => {
    worksheet.columns.forEach((column: any) => {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, (cell: any) => {
        maxColumnLength = Math.max(
          maxColumnLength,
          minimalWidth,
          cell.value ? cell.value.toString().length : 0
        );
      });
      column.width = maxColumnLength + 2;
    });
  };

  private saveFile(workbookType: ExcelJS.Csv | ExcelJS.Xlsx, excelData: any) {
    workbookType.writeBuffer().then(buffer => {
      const blob = new Blob([buffer], {
        type:
          excelData.selectedFileType === fileFormats.csv
            ? fileFormatsType.csv
            : fileFormatsType.xlsx,
      });
      FileSaver(blob, this.getFileName(excelData));
    });
  }

  private getFileName(excelData: any) {
    const extension =
      excelData.selectedFileType === fileFormats.csv
        ? fileFormats.csv
        : fileFormats.xlsx;
    const dateTime = this.datePipe.transform(
      new Date(),
      dateTimeFormat.dateTime24hours
    );
    if (excelData.isFromSummary) {
      return `${excelData.name}_${dateTime}.${extension}`;
    } else {
      return `${excelData.name}_${excelData.extOrNumber}_${dateTime}.${extension}`;
    }
  }
}
