import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseService } from 'src/app/core/services/base.httpService';
import {
  CbpRequestFetchPayloadProps,
  CbpResponseStatusFetchPayloadProps,
  FullmanFetchPayloadProps,
} from 'src/app/shared/models/dynamic.model';
import { URLConstants } from 'src/app/shared/utility/url-constants';

@Injectable({
  providedIn: 'root',
})
export class FullmanFilesService {
  public isFullManFileRejected = new BehaviorSubject<boolean>(false);
  constructor(private readonly baseService: BaseService) {}

  public fetchFullmanFiles(payload: FullmanFetchPayloadProps): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.fetchFullmanFiles,
      payload,
      '',
      'json'
    );
  }

  public fetchCbpRequestStatus(
    payload: CbpRequestFetchPayloadProps
  ): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.fetchCbpRequestStatus,
      payload,
      '',
      'json'
    );
  }

  public fetchCbpResponseStatus(
    payload: CbpResponseStatusFetchPayloadProps
  ): Observable<any> {
    return this.baseService.initiateApiRequest(
      'POST',
      URLConstants.fetchCbpResponseStatus,
      payload,
      '',
      'json'
    );
  }

  public setFullmanFileRejectStatus(data: boolean): void {
    this.isFullManFileRejected.next(data);
  }
}
