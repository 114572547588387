import { UpdateMainConsignee, UpdateMainShipper } from './hawb.state';
import {
  DispList,
  CommodityList,
  PieceList,
  Miscellaneous,
  Pod,
  hawbInformation,
  CommodityMiscellaneous,
  EventList,
  HawbSearchPayload,
  HawbInformationSearchPayload,
  Roles,
} from 'src/app/features/model/hawb.model';

export class AddRoles {
  static readonly type = '[Roles] Add';
  constructor(public payload: Roles) {}
}

export class ResetRoles {
  static readonly type = '[Roles] Reset';
}

export class DeleteRoles {
  static readonly type = '[Roles] Delete';
  constructor(public payload: Roles) {}
}

// Disp Section
export class AddDispCode {
  static readonly type = '[Disp Codes] Add Disp Code';
  constructor(public payload: DispList) {}
}

export class DeleteStatusCode {
  static readonly type = '[Status Codes] Delete Status Code';
  constructor(public id: any) {}
}
export class ResetDispCodes {
  static readonly type = '[Disp Codes] Reset Disp Codes';
}
// POD Section
export class AddPodCode {
  static readonly type = '[POD Codes] Add POD Code';
  constructor(public payload: Pod) {}
}

export class DeletePodCode {
  static readonly type = '[POD Codes] Delete POD Code';
  constructor(public id: any) {}
}
export class ResetPODCodes {
  static readonly type = '[POD Codes] Reset POD Codes';
}
export class RemoveUnsavedAttachments {
  static readonly type = '[Attachment] Remove Unsaved Attachments';
}
export class ResetAtatchmnetCodes {
  static readonly type = '[Attachment Codes] Reset Attachment Codes';
}
// Miscellaneous Section
export class UpdateMiscellaneous {
  static readonly type = '[Miscellaneous] Update';
  constructor(public payload: Miscellaneous) {}
}
// Hawb Information Crud
export class UpdateHawbInformation {
  static readonly type = '[HawbInformation] Update';
  constructor(public payload: hawbInformation) {}
}

// Piece table
export class AddPiece {
  static readonly type = '[Piece] Add Piece';
  constructor(public payload: PieceList) {}
}

export class DeletePiece {
  static readonly type = '[Piece] Delete Piece';
  constructor(public payload: number) {}
}
export class ResetPiece {
  static readonly type = '[Piece] Reset Piece';
}

// Commodity Table
export class AddCommodity {
  static readonly type = '[Commodity] Add';
  constructor(public payload: CommodityList) {}
}

export class ResetCommodity {
  static readonly type = '[Commodity] Reset';
}

export class DeleteCommodity {
  static readonly type = '[Commodity] Delete Commodity';
  constructor(public payload: number) {}
}

// Miscellaneous Commodity
export class AddMiscellaneousCommodity {
  static readonly type = '[Miscellaneous Commodity] Add';
  constructor(public payload: CommodityMiscellaneous) {}
}

export class RemoveUnsavedMiscellaneousCommodities {
  static readonly type = '[Miscellaneous Commodity] Remove Unsaved';
}
export class ResetMiscellaneousCommodity {
  static readonly type = '[Miscellaneous Commodity] Reset';
}

export class AddEvents {
  static readonly type = '[Events] Add';
  constructor(public payload: EventList[]) {}
}
export class ResetEvents {
  static readonly type = '[Events] Reset Events';
}

export class FetchHawbsList {
  static readonly type = '[Hawbs] List';
  constructor(public payload: HawbSearchPayload) {}
}

export class FetchHawbsInformationListSuccess {
  static readonly type = '[Hawb] Fetch Hawbs Information List Success';
  constructor(public hawbs: hawbInformation[]) {}
}

export class FetchHawbsInformationList {
  static readonly type = '[Hawbs] Information List';
  constructor(public payload: HawbInformationSearchPayload) {}
}

export class FetchHawbsInformationByHawbExt {
  static readonly type = '[Hawbs] Information Lit by Hawb EXT';
  constructor(public payload: string) {}
}
export class FetchOrigins {
  static readonly type = '[Hawbs] Fetch Origins';
}

export class FetchDestinations {
  static readonly type = '[Hawbs] Fetch Destinations';
}

export class ResetHawbsList {
  static readonly type = '[Hawbs] Reset Hawbs List';
}

export class FetchSelectedHawbById {
  static readonly type = '[Hawbs] Fetch Selected Hawb By Id';
  constructor(public hawbId: number) {}
}

export class ResetHawbInfo {
  static readonly type = '[Hawbs] Reset Hawbs Info';
}

export class MainConsigneeShipperScreenActions {
  static updateConsignee(payload: any) {
    return new UpdateMainConsignee(payload);
  }

  static updateShipper(payload: any) {
    return new UpdateMainShipper(payload);
  }
}
