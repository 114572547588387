import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { NumberRangesGridContent } from "../number-ranges.model";
import { FetchNumberRangesList, FetchNumbersInformationListSuccess, FetchSelectedNumberById, ResetNumberRangesList, ResetNumbersInformationList } from "./number.action";
import { updateState } from "src/app/shared/utility/store.utility";

export interface NumberRangesListStateModel {
  numberRanges: NumberRangesGridContent[];
  totalData: number;
}

@Injectable()
@State<NumberRangesListStateModel>({
  name: 'numberRangeList',
  defaults: {
    numberRanges: [],
    totalData: 0,
  },
})
export class NumberRangesListState {
  @Action(FetchNumberRangesList)
  FetchHawbsList(
    ctx: StateContext<NumberRangesListStateModel>,
    action: FetchNumberRangesList
  ) {
    const payload = action.payload;
    if (payload.data?.numberRanges && payload.data?.totalRecords) {
      updateState(ctx, {
        numberRanges: payload.data.numberRanges,
        totalData: payload.data.totalRecords,
      });
    } else {
      updateState(ctx, {
        numberRanges: [],
        totalData: 0,
      });
    }
  }

  @Action(ResetNumberRangesList)
  ResetNumberRangeList(ctx: StateContext<NumberRangesListStateModel>) {
    updateState(ctx, {
      numberRanges: [],
      totalData: 0,
    });
  }
}

export interface NumberInformationStateModel {
  numberInformation: any;
  numbersInformationList: any[];
}

@State<NumberInformationStateModel>({
  name: 'numberInformation',
  defaults: {
    numberInformation: {},
    numbersInformationList: [],
  },
})
@Injectable()
export class NumberInformationState {
  @Action(FetchNumbersInformationListSuccess)
  fetchNumbersInformationListSuccess(
    { getState, setState }: StateContext<NumberInformationStateModel>,
    { numbers }: FetchNumbersInformationListSuccess
  ): void {
    const state = getState();
    setState({
      ...state,
      numbersInformationList: numbers,
    });
  }

  @Action(FetchSelectedNumberById)
  FetchSelectedNumberById(
    ctx: StateContext<NumberInformationStateModel>,
    action: FetchSelectedNumberById
  ) {
    const state = ctx.getState();
    const selectedNumber = state.numbersInformationList.find(
      element => element.id === action.numberId
    );

    updateState(ctx, {
      numberInformation: selectedNumber || {},
    });
  }

  @Action(ResetNumbersInformationList)
  resetNumberInfo(ctx: StateContext<NumberInformationStateModel>) {
    updateState(ctx, {
      numberInformation: {},
      numbersInformationList: [],
    });
  }
}