<div class="miscellaneous-section">
  <div [formGroup]="miscellaneousForm">
    <div class="first-miscellaneous-container">
      <mat-form-field appearance="outline" class="mat-md">
        <mat-label>Tare Weight (lb)</mat-label>
        <input
          matInput
          type="text"
          formControlName="tareWeight"
          [appDigitWithTwoDecimalNumber]="7"
          (focusout)="dispatchMiscellaneousFormValueChanges()" />
        <app-svg-icon
          *ngIf="checkControlHasError('tareWeight')"
          matSuffix
          [iconName]="'infoCircle'"
          [isStaticSvg]="true"
          [isFrom]="'Page'"
          class="cursor"
          (click)="fetchWidth($event, 'tareWeight')"
          (keydown)="$event.stopPropagation()"></app-svg-icon>
        <app-custom-error-tooltip
          *ngIf="
            checkControlHasError('tareWeight') &&
            setMiscellaneousInfoErrorTooltip['tareWeight']
          "
          [tooltipWidth]="setMiscellaneousInfoWidth['tareWeight']"
          [tooltipText]="getErrorMessage('tareWeight')"
          (tooltipCloseClicked)="
            commonService.closeErrorTooltip(
              'tareWeight',
              setMiscellaneousInfoErrorTooltip
            )
          ">
        </app-custom-error-tooltip>
      </mat-form-field>
    </div>
  </div>

  <app-information-note
    [message]="misInfo"
    *ngIf="misInfo"></app-information-note>
</div>
