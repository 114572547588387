import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CustomErrorTooltipComponent } from '../../custom-error-tooltip/custom-error-tooltip.component';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { MatTableDataSource } from '@angular/material/table';
import {
  FormConfig,
  IDropdownResponse,
} from 'src/app/shared/models/dynamic.model';
import { ITableProp } from 'src/app/shared/models/tables.model';
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { Subject, takeUntil, startWith, map, Observable } from 'rxjs';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ValidatorService } from 'src/app/shared/services/form-control-validators.service';
import {
  ActionIcons,
  formFieldIds,
  selectionTableNames,
  MasterLookup,
  ModeIcons,
  pageType,
  validationErrorMessages,
  selectedIdForSummaryTable,
  StatusCodes,
  conditionCheckConstants,
} from 'src/app/shared/utility/constants';
import {
  TableControls,
  AutocompleteControls,
  InputWithTwoDecimal,
  ApiControls,
} from '../tables.config';
import { TableService } from '../tables.service';

@Component({
  selector: 'app-add-inline-edit-table',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    SharedModule,
    CustomErrorTooltipComponent,
  ],
  templateUrl: './add-inline-edit-table.component.html',
  styleUrl: './add-inline-edit-table.component.scss',
})
export class AddInlineEditTableComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @Input() inlineCrudTableCustomizeConfig!: ITableProp[];
  @Input() isFrom!: string;
  @Input() formConfig!: FormConfig;
  @Input() pageMode!: string;
  @Output() inlineCrudTableFormValue = new EventEmitter<FormArray>();
  @Output() inlineTableSelectionChange = new EventEmitter<any[]>();
  @ViewChild('firstInput', { static: false }) firstInput!: ElementRef;

  public inlineCrudTableDataSource!: MatTableDataSource<any>;
  public inlineCrudTableForm!: FormGroup;
  public inlineTableDisplayedColumns!: string[];
  public inlineTableSelection = new SelectionModel<any>(true, []);
  public inlineTableSelectAllChecked = false;
  public customiseInlineCrudTableColumns!: ITableProp[];
  public setInlineCrudTableWidth: any = {};
  public setInlineCrudTableErrorTooltip: any = {};
  public setInlineCrudTableTooltipEvent: any = {};
  public actionIcons = ActionIcons;
  public formFields = formFieldIds;
  public selectedRowModuleId = '';
  public serviceTypeData!: IDropdownResponse[];
  public filteredServiceTypeOptions: Observable<IDropdownResponse[]>[] = [];
  public serviceTypeSelected = false;
  public rateScaleData!: IDropdownResponse[];
  public filteredRateScaleOptions: Observable<IDropdownResponse[]>[] = [];
  public fuelSurchargeData!: IDropdownResponse[];
  public filteredFuelSurchargeOptions: Observable<IDropdownResponse[]>[] = [];
  public isForCustomerService = false;

  private errorMessages: any;
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  private hasFocused = false;

  constructor(
    public tableService: TableService,
    public commonService: CommonService,
    private readonly formUtilsService: ValidatorService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.isFrom === selectionTableNames.customerService) {
      this.isForCustomerService = true;
      this.getMasterLookupType(MasterLookup.serviceType);
      this.getMasterLookupType(MasterLookup.rateScale);
      this.getMasterLookupType(MasterLookup.fuelSurcharge);
    }
    this.createFormArray();
    this.setRequiredInitialization();
    this.getTableData();
  }

  ngAfterViewChecked(): void {
    if (
      !this.hasFocused &&
      this.firstInput &&
      this.pageMode == ModeIcons.view
    ) {
      this.firstInput.nativeElement.focus();
      this.hasFocused = true;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /** Selects all rows if they are not all selected; otherwise clear inlineTableSelection. */
  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.inlineTableSelection.clear();
      this.inlineTableSelectAllChecked = false;
    } else {
      const nonInputRows = this.inlineCrudTableDataSource.data.filter(
        (row: any) => !row.isInputRow
      );
      this.inlineTableSelection.select(...nonInputRows);
      this.inlineTableSelectAllChecked = true;
    }
    this.emitCreateSelectionChange();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const nonInputRows = this.inlineCrudTableDataSource.data.filter(
      (row: any) => !row.isInputRow
    );
    return (
      this.inlineTableSelection.selected.length === nonInputRows.length &&
      nonInputRows.length > 0
    );
  }

  // for table body checkbox inlineTableSelection
  public checked(row: any) {
    this.inlineTableSelection.select(row);
    this.checkForCreateModeId(row);
    const found = this.inlineTableSelection.selected.find(
      x => x[this.selectedRowModuleId] == row[this.selectedRowModuleId]
    );
    if (found) {
      found.checked = true;
    }
  }

  public unchecked(row: any) {
    this.checkForCreateModeId(row);
    const found = this.inlineTableSelection.selected.find(
      x => x[this.selectedRowModuleId] == row[this.selectedRowModuleId]
    );
    if (found) {
      found.checked = false;
      this.inlineTableSelection.deselect(found);
    }
  }

  public isChecked(row: any): boolean {
    this.checkForCreateModeId(row);
    const found = this.inlineTableSelection.selected.find(
      el => el[this.selectedRowModuleId] === row[this.selectedRowModuleId]
    );
    if (found) {
      return true;
    }
    return false;
  }

  public getRows() {
    return this.inlineCrudTableForm.get('rows') as FormArray;
  }

  public getTableData() {
    this.inlineCrudTableDataSource = new MatTableDataSource(
      this.getRows().getRawValue()
    );
  }

  public fetchWidth(event: MouseEvent, fieldName: string) {
    this.commonService.setErrorTooltipData(
      event,
      fieldName,
      this.setInlineCrudTableWidth,
      this.setInlineCrudTableTooltipEvent,
      this.setInlineCrudTableErrorTooltip
    );
  }

  public checkControlHasError(formIndex: number, controlName: string) {
    return (
      this.getRows().at(formIndex).get(controlName)?.touched &&
      this.getRows().at(formIndex).get(controlName)?.errors
    );
  }

  public getErrorMessage(formIndex: number, controlName: string) {
    const rows = this.getRows();
    const control = rows.at(formIndex).get(controlName);
    if (control?.errors?.[conditionCheckConstants.autocompleteError]) {
      return validationErrorMessages.autocompleteError;
    } else {
      const fieldErrorMessages = this.errorMessages[controlName];
      if (control?.errors && fieldErrorMessages) {
        const errorKey = Object.keys(control.errors)[0];
        return fieldErrorMessages[errorKey] || '';
      } else {
        return '';
      }
    }
  }

  public getAutocompleteOptionsList(key: string, rowIndex: number) {
    if (key === this.formFields.serviceType) {
      return this.filteredServiceTypeOptions[rowIndex];
    } else if (key === this.formFields.rateScale) {
      return this.filteredRateScaleOptions[rowIndex];
    } else if (key === this.formFields.fuelSurcharge) {
      return this.filteredFuelSurchargeOptions[rowIndex];
    } else {
      return null;
    }
  }

  public checkForEnableOrDisable() {
    return this.inlineCrudTableDataSource.data.length <= 0;
  }

  public emitCreateSelectionChange() {
    const selectedRows = this.inlineTableSelection.selected;
    this.inlineTableSelectionChange.emit(selectedRows);
  }

  public onBlurInlineCrudTableControls(rowIndex: number) {
    this.inlineCrudTableFormValue.emit(this.getRows().at(rowIndex).value);
  }

  public getInlineTableAutocompleteFields(key: string) {
    return AutocompleteControls.indexOf(key) > -1;
  }

  public getInlineTableInputFormControl(key: string) {
    return this.getDiscountWithDecimal(key);
  }

  public getDiscountWithDecimal(key: string) {
    return InputWithTwoDecimal.indexOf(key) > -1;
  }

  private createFormArray() {
    this.inlineCrudTableForm = this.fb.group({
      rows: this.fb.array([]),
    });
    this.addNewRow();
    this.getRows().at(0).get('id')?.setValue(0);
    this.getRows()
      .at(0)
      .get('tempId')
      ?.setValue(this.commonService.generateUniqueTempId());
    this.getRows().at(0).get('isRowDisable')?.setValue(false);
  }

  public addNewRow() {
    this.getRows().push(this.getRowFormGroupConfig());
  }

  private getRowFormGroupConfig() {
    const { form, errorMessages } = this.formUtilsService.generateForm(
      this.formConfig
    );
    this.errorMessages = errorMessages;
    return form;
  }

  private setRequiredInitialization() {
    this.customiseInlineCrudTableColumns = this.inlineCrudTableCustomizeConfig; // set columns config
    this.inlineTableDisplayedColumns =
      this.tableService.getDefaultDisplayColumns(this.isFrom); // set headers for table
    this.setTableRowIds(false);
  }

  private setTableRowIds(idParam: boolean) {
    if (this.isFrom === pageType.customerService) {
      this.selectedRowModuleId = idParam
        ? selectedIdForSummaryTable.tempId
        : selectedIdForSummaryTable.Id;
    }
  }

  private checkForCreateModeId(row: any) {
    if (this.isFrom === selectionTableNames.customerService && !row.id) {
      this.setTableRowIds(true);
    }
  }

  private getMasterLookupType(lookupKey: any) {
    this.commonService
      .getMasterLookUp(lookupKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          if (data.statusCode == StatusCodes.Success) {
            if (lookupKey === MasterLookup.serviceType) {
              this.serviceTypeData = data.data;
              this.autocompleteValueChanges(
                0,
                TableControls.serviceType,
                this.serviceTypeData
              );
            } else if (lookupKey === MasterLookup.rateScale) {
              this.rateScaleData = data.data;
              this.autocompleteValueChanges(
                0,
                TableControls.rateScale,
                this.rateScaleData
              );
            } else if (lookupKey === MasterLookup.fuelSurcharge) {
              this.fuelSurchargeData = data.data;
              this.autocompleteValueChanges(
                0,
                TableControls.fuelSurcharge,
                this.fuelSurchargeData
              );
            }
            this.checkForCrudTablePageSidebarExpanded();
          } else {
            this.errorHandlerService.handleApiError(data);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.errorHandlerService.handleError(error);
        },
      });
  }

  public formAutocompleteValueChanges(controlName: string, rowIndex: number) {
    if (controlName === TableControls.serviceType) {
      this.commonService.getAutocompleteDropdownIdParam(
        this.serviceTypeData,
        this.getRows().at(rowIndex),
        controlName,
        ''
      );
    } else {
      this.commonService.getAutocompleteDropdownId(
        this.getDropdownResponseList(controlName),
        this.getRows().at(rowIndex),
        controlName,
        this.getApiControlNames(controlName)
      );
    }
    this.onBlurInlineCrudTableControls(rowIndex);
  }

  public loadAutocompleteForNewRow(rowIndex: number) {
    this.autocompleteValueChanges(
      rowIndex,
      TableControls.serviceType,
      this.serviceTypeData
    );
    this.autocompleteValueChanges(
      rowIndex,
      TableControls.rateScale,
      this.rateScaleData
    );
    this.autocompleteValueChanges(
      rowIndex,
      TableControls.fuelSurcharge,
      this.fuelSurchargeData
    );
  }
  public onOptionSelectedFormChanges(controlName: string, rowIndex: number) {
    if (controlName === TableControls.serviceType) {
      const selectedServiceType = this.getRows()
        .at(rowIndex)
        .get(TableControls.serviceType)?.value;
      if (this.commonService.isValidField(selectedServiceType)) {
        const serviceTypeId = this.serviceTypeData.filter(
          (type: any) => type.id === selectedServiceType
        )[0]?.name;
        this.getRows()
          .at(rowIndex)
          .get(ApiControls.serviceType)
          ?.setValue(serviceTypeId);
      }
    } else {
      this.commonService.getAutocompleteDropdownBinding(
        this.getDropdownResponseList(controlName),
        this.getRows().at(rowIndex),
        controlName,
        this.getApiControlNames(controlName),
        validationErrorMessages.name
      );
    }
    this.onBlurInlineCrudTableControls(rowIndex);
  }

  private getDropdownResponseList(control: string) {
    const dropdownList = {
      [TableControls.serviceType]: this.serviceTypeData,
      [TableControls.rateScale]: this.rateScaleData,
      [TableControls.fuelSurcharge]: this.fuelSurchargeData,
    };
    return dropdownList[control] || [];
  }

  private getApiControlNames(control: string) {
    const apiControlName = {
      [TableControls.rateScale]: ApiControls.rateScale,
      [TableControls.fuelSurcharge]: ApiControls.fuelSurcharge,
    };
    return apiControlName[control] || '';
  }

  private autocompleteValueChanges(
    formIndex: number,
    controlName: string,
    dropdownData: IDropdownResponse[]
  ) {
    const formControl = this.getRows().at(formIndex).get(controlName);
    if (formControl && controlName === this.formFields.serviceType) {
      this.filteredServiceTypeOptions[formIndex] = this.getFilteredData(
        formControl,
        dropdownData,
        controlName
      );
    } else if (formControl && controlName === this.formFields.rateScale) {
      this.filteredRateScaleOptions[formIndex] = this.getFilteredData(
        formControl,
        dropdownData,
        controlName
      );
    } else if (formControl && controlName === this.formFields.fuelSurcharge) {
      this.filteredFuelSurchargeOptions[formIndex] = this.getFilteredData(
        formControl,
        dropdownData,
        controlName
      );
    }
  }

  private getFilteredData(
    formControl: AbstractControl<any, any> | null,
    dropdownData: any,
    controlName: string
  ): Observable<IDropdownResponse[]> {
    return (
      formControl?.valueChanges.pipe(
        startWith<string | IDropdownResponse>(''),
        map(value => (typeof value === 'string' ? value : value?.name)),
        map(name =>
          name
            ? this.autocompleteFilter(name, controlName, dropdownData)
            : dropdownData?.slice() || []
        )
      ) ?? new Observable<IDropdownResponse[]>(observer => observer.next([]))
    );
  }

  private autocompleteFilter(
    name: string,
    controlName: string,
    dropdownList: IDropdownResponse[]
  ): IDropdownResponse[] {
    const filterValue = name.toLowerCase();
    if (controlName === this.formFields.serviceType) {
      return dropdownList?.filter(opt =>
        opt.id.toLowerCase().includes(filterValue)
      );
    } else {
      return dropdownList?.filter(opt =>
        opt.name.toLowerCase().includes(filterValue)
      );
    }
  }

  private checkForCrudTablePageSidebarExpanded() {
    this.commonService.isMenuExpanded.subscribe(() => {
      this.setInlineCrudTableWidth = this.commonService.setModifiedTooltipWidth(
        this.setInlineCrudTableTooltipEvent,
        this.setInlineCrudTableErrorTooltip,
        this.setInlineCrudTableWidth
      );
    });
  }

  private getServiceTypeName(selectedData: any) {
    const selectedServiceType = this.serviceTypeData.filter(
      (type: any) => type.id === selectedData
    );
    return selectedServiceType[0].name;
  }
}
