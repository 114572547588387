import { NumberRangesGridContent } from "../number-ranges.model";

export class FetchNumberRangesList {
  static readonly type = '[Number Ranges] List';
  constructor(public payload: NumberRangesGridContent) {}
}

export class ResetNumberRangesList {
  static readonly type = '[Number Ranges] Reset Number Ranges List';
}

export class FetchNumbersInformationListSuccess {
  static readonly type = '[Number] Fetch Numbers Information List Success';
  constructor(public numbers: any[]) {}
}

export class FetchSelectedNumberById {
  static readonly type = '[Numbers] Fetch Selected Number By Id';
  constructor(public numberId: number) {}
}

export class ResetNumbersInformationList {
  static readonly type = '[Numbers] Reset Numbers Information List';
}